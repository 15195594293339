import {
    Button,
    FormControl,
    MenuItem,
    TextField,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Stack,
    FormHelperText,
    Box,
} from "@mui/material";
import withRouter from "components/withRouter";
import React, { useEffect, useState } from "react";
import { FormatConstants } from "services/keyConstants";
import { getDocumentLifeCycleForMasterDoc, toCamelCase } from "services/utils";
import * as url from '../utils/urlHelper'
import { Add, Delete } from "@mui/icons-material";
import { Edit, SaveAdd, Trash } from "iconsax-react";

const IncomingInspectionPlan = (props) => {
    const {
        ApiServices,
        // FormatConstants,
        MasterDocumentTypeConstants,
        data, setData,
        columns, setColumns,
        errors, setErrors,
        isDraft
    } = props;

    const [editingHeader, setEditingHeader] = useState(null);
    const [headerInput, setHeaderInput] = useState("");
    const [newColumn, setNewColumn] = useState("");
    const [materialsState, setMaterialsState] = useState([]);

    const handleAddTable = () => {
        const newTable = {
            product: "", // Default empty product
            rows: [
                {
                    process: "",
                    methodOfInspection: "",
                    specification: "",
                    specificationWithTolerance: "",
                    sampleSize: "",
                    acceptanceCriteria: "",
                },
            ],
            columns
        };
        setData([...data, newTable]);
    };

    const handleDeleteTable = (index) => {
        const updatedData = [...data];
        updatedData.splice(index, 1);
        setData(updatedData);
    };

    const handleRemoveRow = (tableIndex, rowIndex) => {
        setData((prevData) => {
            const updatedData = [...prevData];
            const rows = updatedData[tableIndex]?.rows;

            if (rows && rows.length > 1) {
                rows.splice(rowIndex, 1); // Remove the row at the specified index
            }

            return updatedData;
        });
    }

    const handleAddRow = (index, rowIndex) => {
        const updatedData = [...data];
        const defaultRow = {
            process: "",
            methodOfInspection: "",
            specification: "",
            specificationWithTolerance: "",
            sampleSize: "",
            acceptanceCriteria: "",
        };
        updatedData[index]?.rows?.splice(rowIndex + 1, 0, defaultRow);
        setData(updatedData);
    };

    const handleProductChange = (e, index) => {
        const selectedProduct = materialsState?.find((p) => p?.materialId === e?.target?.value)
        const updatedData = [...data];
        updatedData[index] = {
            ...updatedData[index],
            product: e?.target?.value,
            productName: selectedProduct?.materialName
        };
        if (!updatedData[index].columns) {
            updatedData[index].columns = columns
        }
        setData(updatedData);

        // Remove error for the updated product field
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (updatedErrors[index]?.product) {
                delete updatedErrors[index].product; // Remove the product error for this index
                if (Object.keys(updatedErrors[index]).length === 0) {
                    delete updatedErrors[index]; // Remove the table index if no other errors exist
                }
            }
            return updatedErrors;
        });
    };


    const handleChangeIncoming = (e, rowIndex, tableIndex) => {
        const { name, value } = e?.target;

        // Update the specific row in the specific table
        setData((prevData) => {
            const updatedData = [...prevData]; // Create a copy of the data array
            updatedData[tableIndex].rows[rowIndex][name] = value; // Update the specific field
            return updatedData;
        });

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };

            if (updatedErrors[tableIndex]?.[rowIndex]?.[name]) {
                delete updatedErrors[tableIndex][rowIndex][name]; // Remove the specific field error
                if (Object.keys(updatedErrors[tableIndex][rowIndex]).length === 0) {
                    delete updatedErrors[tableIndex][rowIndex]; // Remove the row error if empty
                }
                if (Object.keys(updatedErrors[tableIndex]).length === 0) {
                    delete updatedErrors[tableIndex]; // Remove the table error if empty
                }
            }

            return updatedErrors;
        });
    };

    const addColumn = (index) => {
        if (newColumn.trim()) {
            const newColumnValue = toCamelCase(newColumn?.trim());

            // Update the data with the new column added to rows' parametersToBeInspected
            const updatedData = data?.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        columns: [
                            ...item.columns,
                            { name: newColumn?.trim(), value: newColumnValue, type: "text" },
                        ],
                        rows: item?.rows?.map((row) => {
                            const updatedParameters = row?.parametersToBeInspected?.map(
                                (param) => ({
                                    ...param,
                                    [newColumnValue]: "", // Add the new column with a default empty value
                                })
                            );

                            return {
                                ...row,
                                parametersToBeInspected: updatedParameters,
                            };
                        }),
                    };
                }
                return item;
            });

            setData(updatedData); // Update the data state with the new column in columns and parametersToBeInspected
            setNewColumn(""); // Clear the input field for adding a new column
        }
    };

    useEffect(() => {
        getMaterialListData()
    }, [])

    const getMaterialListData = async () => {
        try {
            const approvedSupplierListmasterDocData = await getDocumentLifeCycleForMasterDoc(
                FormatConstants.PURCHASE.APPROVED_SUPPLIER_LIST,
                MasterDocumentTypeConstants.FORMAT
            );
            const purchaseOrdermasterDocData = await getDocumentLifeCycleForMasterDoc(
                FormatConstants.PURCHASE.PURCHASE_ORDER,
                MasterDocumentTypeConstants.FORMAT
            );
            let reqObj = {
                supplierListdocumentName: FormatConstants.PURCHASE.APPROVED_SUPPLIER_LIST,
                approvedSupplierListMasterDocId: approvedSupplierListmasterDocData?._id,
                purchaseOrderDocumentName: FormatConstants.PURCHASE.PURCHASE_ORDER,
                purchaseOrdermasterDocData: purchaseOrdermasterDocData?._id
            };
            let uri = url.GET_MATERIAL_LIST_FROM_PURCHASE;
            let result = await ApiServices.callServicePutWithBodyData(uri, reqObj); // API call
            if (result?.meta?.success) {
                setMaterialsState(result?.data ?? materialsState)
            }
        } catch (error) {
            console.error("Error in GET_MATERIAL_LIST_DATA function:", error);
        }
    };

    const getAvailableOptions = (index) => {
        const selectedProduct = data?.[index]?.product;
        // Filter out options that are selected in other rows, but include the current selection
        return materialsState?.filter((option) => {
            return (
                option?.materialId === selectedProduct ||
                !data?.some((item, idx) => idx !== index && item?.product === option?.materialId)
            );
        });
    };

    const saveEditingHeader = () => {
        if (editingHeader) {
            const { colIdx, index } = editingHeader;
            const newColumnValue = toCamelCase(headerInput);

            const updatedData = [...data];
            const updatedColumn = {
                name: headerInput,
                // value: newColumnValue,
            };

            // updatedData[index].rows = updatedData[index]?.rows?.map((row) => {
            //     const updatedParameters = row?.parametersToBeInspected?.map((param) => {
            //         const oldColumnValue = updatedData[index]?.columns[colIdx]?.value;

            //         // If the column name is being updated, change all related keys in the row
            //         if (oldColumnValue) {
            //             const updatedParam = { ...param };
            //             if (updatedParam[oldColumnValue]) {
            //                 updatedParam[newColumnValue] = updatedParam[oldColumnValue]; // Create new key-value pair
            //                 delete updatedParam[oldColumnValue]; // Remove the old column key
            //             }
            //             return updatedParam;
            //         }
            //         return param;
            //     });
            //     return {
            //         ...row,
            //         parametersToBeInspected: updatedParameters,
            //     };
            // });
            updatedData[index].columns[colIdx] = {
                ...updatedColumn,
                type: updatedData[index].columns[colIdx]?.type,
                value: updatedData[index].columns[colIdx]?.value
            };
            // Update the state with the new data
            setData(updatedData);

            // Reset editing state
            setEditingHeader(null);
            setHeaderInput("");
        }
    };

    const startEditingHeader = (colIdx, index) => {
        setEditingHeader({ colIdx, index }); // Track which column and data index is being edited
        setHeaderInput(data[index]?.columns[colIdx]?.name); // Set the column name for editing
    };

    return (
        <>
            {data?.map((value, index) => (
                <TableContainer sx={{ margin: 1 }} key={index} component={Paper}>
                    <Table aria-label="dynamic table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography fontSize={15} fontWeight="bold">
                                        Product Name:{" "}
                                        <Typography
                                            fontSize={15}
                                            variant="span"
                                            fontWeight="normal"
                                        >
                                            {value?.productName || "-"}
                                        </Typography>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <FormControl error={!!errors[index]?.product}>
                                        <Select
                                            sx={{ minWidth: "300px" }}
                                            value={value?.product || ""} // Bind the value to product _id
                                            onChange={(e) => handleProductChange(e, index)}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            inputProps={{ readOnly: !isDraft }}
                                        >
                                            {getAvailableOptions(index)?.map((option, i) => (
                                                <MenuItem key={i} value={option?.materialId}>
                                                    {option?.materialName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors[index]?.product && (
                                            <FormHelperText error>{errors[index]?.product}</FormHelperText>
                                        )}
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    {isDraft && <>  <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddTable}
                                        style={{
                                            marginLeft: "16px",
                                        }}
                                    >
                                        Add Product
                                    </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleDeleteTable(index)}
                                            style={{
                                                marginLeft: "16px",
                                            }}
                                        >
                                            Delete
                                        </Button></>}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {value?.columns?.map((col, colIdx) => {
                                    return <TableCell key={colIdx}> {
                                        editingHeader?.colIdx === colIdx && editingHeader?.index === index ?
                                            <Box display="flex" alignItems="center">
                                                <TextField
                                                    value={headerInput}
                                                    onChange={(e) => setHeaderInput(e.target.value)}
                                                    onBlur={() => saveEditingHeader(colIdx, index)}
                                                    autoFocus
                                                    inputProps={{ readOnly: !isDraft }}
                                                />
                                                {isDraft && <IconButton
                                                    onClick={() => saveEditingHeader(colIdx, index)}
                                                    style={{ marginLeft: "10px" }}
                                                >
                                                    <SaveAdd />
                                                </IconButton>}
                                            </Box> :
                                            <Box display="flex" alignItems="center">
                                                {col?.name}

                                                {isDraft && <IconButton
                                                    color="success"
                                                    onClick={() =>
                                                        startEditingHeader(colIdx, index)
                                                    }
                                                    style={{ marginLeft: "10px" }}
                                                >
                                                    <Edit />
                                                </IconButton>}
                                                {isDraft && col?.isDelete !== false ? <IconButton
                                                    color="error"
                                                    onClick={() => deleteColumn(col?.value, index)}
                                                    style={{ marginLeft: "10px" }}
                                                >
                                                    <Trash />
                                                </IconButton> : null}
                                            </Box>
                                    }</TableCell>
                                })}
                                {isDraft && <TableCell>Actions</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {value?.rows?.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {value?.columns?.map((column, colIndex) => (
                                        <TableCell key={colIndex}>
                                            {column?.type === "index" ? (
                                                rowIndex + 1
                                            ) : column?.type === "index" ?
                                                <TextField
                                                    label={column?.name}
                                                    name={column?.value}
                                                    value={row[column?.value] || ""}
                                                    onChange={(e) => handleChangeIncoming(e, rowIndex, index)}
                                                    error={!!errors[index]?.[rowIndex]?.[column.value]} // Check if there's an error for this field
                                                    helperText={errors[index]?.[rowIndex]?.[column.value]} // Display the error message
                                                    inputProps={{ readOnly: !isDraft }}
                                                />
                                                : column?.type === "index" ? (
                                                    <TextField
                                                        label={column?.name}
                                                        name={column?.value}
                                                        type={"number"}
                                                        value={row[column?.value] || ""}
                                                        onChange={(e) => handleChangeIncoming(e, rowIndex, index)}
                                                        error={!!errors[index]?.[rowIndex]?.[column.value]} // Check if there's an error for this field
                                                        helperText={errors[index]?.[rowIndex]?.[column.value]} // Display the error message
                                                        inputProps={{ readOnly: !isDraft }}
                                                    />
                                                ) : <TextField
                                                    label={column?.name}
                                                    name={column?.value}
                                                    value={row[column?.value] || ""}
                                                    onChange={(e) => handleChangeIncoming(e, rowIndex, index)}
                                                    error={!!errors[index]?.[rowIndex]?.[column.value]} // Check if there's an error for this field
                                                    helperText={errors[index]?.[rowIndex]?.[column.value]} // Display the error message
                                                    inputProps={{ readOnly: !isDraft }}
                                                />}
                                        </TableCell>
                                    ))}
                                    <TableCell>
                                        {isDraft && <Stack sx={{ display: "flex", flexDirection: "row" }} gap={1}>
                                            <IconButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleAddRow(index, rowIndex)}
                                            >
                                                <Add />
                                            </IconButton>
                                            <IconButton
                                                color="error"
                                                onClick={() => handleRemoveRow(index, rowIndex)}

                                            ><Delete /></IconButton>
                                        </Stack>}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {isDraft && <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        mt={3}
                        mb={3}
                    >
                        <Box display="flex" alignItems="center" ml={2}>
                            <TextField
                                label="Add New Column"
                                value={newColumn}
                                onChange={(e) => setNewColumn(e.target.value)}
                                style={{ marginRight: "10px" }}
                            />
                            <Button onClick={() => addColumn(index)} variant="contained" color="primary">
                                Add Column
                            </Button>
                        </Box>
                    </Box>}
                </TableContainer>
            ))}
        </>
    );
};

export default withRouter(IncomingInspectionPlan);