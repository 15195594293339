import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import AuthCard from "./AuthCard";
import AuthBackground from "assets/images/auth/AuthBackground";

const AuthWrapper = ({ isForm, children }) => (
  <Box>
    <AuthBackground />
    <Grid
      container
      direction="column"
      justifyContent="center"
      sx={{
        minHeight: "100dvh",
      }}
    >
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: {
              xs: "calc(100vh - 210px)",
              sm: "calc(100vh - 134px)",
              md: "calc(100vh - 112px)",
            },
          }}
        >
          <Grid item>
            <AuthCard isForm={isForm}>{children}</AuthCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

AuthWrapper.propTypes = {
  children: PropTypes.node,
};

export default AuthWrapper;
