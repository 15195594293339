import MainCard from 'components/MainCard'
import withRouter from 'components/withRouter'
import React, { useEffect, useRef, useState } from 'react'
import * as url from './utils/UrlHelper'
import { ApiServices } from 'services/apiServices'
import FolderIcon from "@mui/icons-material/Folder";
import { Box, Card, Grid, Pagination, Typography } from '@mui/material'
import { routeConstants } from 'services/keyConstants'
import { buildUrl } from 'services/utils'
import Error404 from 'components/reusables/404'
const ReportList = (props) => {
    const { navigate, params } = props?.router

    const [data, setData] = useState([])
    const filterData = useRef({
        currentPage: 1,
        totalPage: 1,
    });

    const getData = async () => {
        try {
            const queryParams = {
                page: filterData.current?.currentPage,
            }
            const uri = buildUrl(url.GET_GROUPED_DATA, queryParams)
            const result = await ApiServices.callServiceGet(uri)
            if (result?.meta?.success) {
                setData(result?.data)
                filterData.current.totalPage = result?.meta?.totalPages;
            } else {
                setData([]);
                filterData.current.currentPage = 1;
                filterData.current.totalPage = 1;
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const handleOpenFolder = (item) => {
        return navigate(`/${routeConstants.INSTALLATION}/report-list/${item?.productId}`)
    }

    const handlePageClick = (e, val) => {
        filterData.current.currentPage = val;
        getData();
    };

    return (
        <MainCard title="Installation Report List">
            <Grid container spacing={2}>
                {data?.length > 0 ? data?.map((row, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                        <Card
                            style={{
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                            }}
                        >
                            <Box
                                onClick={() => handleOpenFolder(row)}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: "center",
                                    borderRadius: 2,
                                    padding: 2,
                                    height: "100%",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    "&:hover": {
                                        transform: "scale3d(1.02, 1.02, 1)",
                                        transition: "all .10s ease-in-out",
                                    },
                                }}
                            >
                                <FolderIcon
                                    sx={{
                                        fontSize: 50,
                                        color: "#5c8ee0",
                                        cursor: "pointer",
                                    }}
                                />
                                <Typography>{row?.productName}</Typography>
                            </Box>
                        </Card>
                    </Grid>
                )) : <Error404
                    headingMain={`Oops, looks like there are no installation, please created it!`}
                    subHeading={`Please create`}
                />}
            </Grid>
            {data?.length > 0 ? <Box display="flex" justifyContent="center" mt={4}>
                <Pagination
                    count={filterData.current?.totalPage ?? 1}
                    page={filterData.current?.currentPage ?? 1}
                    onChange={(e, val) => handlePageClick(e, val)}
                    color="primary"
                    hidePrevButton
                    hideNextButton
                />
            </Box> : null}
        </MainCard>
    )
}

export default withRouter(ReportList)
