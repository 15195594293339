import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// Define lazy-loaded components
const Demo = Loadable(lazy(() => import("../Demo")));
const DemoLabel = Loadable(lazy(() => import("../DemoLabel")));
const Dynamic = Loadable(lazy(() => import("../Dynamic")));
const DraggableForm = Loadable(lazy(() => import("../DraggableForm")));
const DynamicForm = Loadable(lazy(() => import("../wizard/DynamicForm")));
const Wizard = Loadable(lazy(() => import("../wizard-theme/index")));
const AddDynamicForm = Loadable(lazy(() => import("../AddDynamicForm")));
const DynamicWizard = Loadable(lazy(() => import("../wizard/DynamicWizard")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });
// Define routes using lazy-loaded components
const DynamicFormRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute('dynamic-form', null, [
      createRoute("demo", <Demo />),
      createRoute("demo-label", <DemoLabel />),
      createRoute("dynamic", <Dynamic />),
      createRoute("dynamic-table", <DraggableForm />),
      createRoute("dynamic-feedback", <AddDynamicForm />),
      createRoute("dynamic-form", <DynamicForm />),
      createRoute("wizard-dynamic-form", <DynamicWizard />),
      createRoute("wizard", <Wizard />),
    ]),
  ]
);

export default DynamicFormRoute;
