import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, FormatConstants, routeConstants } from "services/keyConstants";
import CommonYearList from "components/reusables/CommonYearList";
import * as url from "./urlHelpers"
// render - pages
const Create = Loadable(lazy(() => import("../molecules/Create")));
const Report = Loadable(lazy(() => import("../Report")));
const View = Loadable(lazy(() => import("../View")));
const ViewById = Loadable(lazy(() => import("../ViewById")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

const CPRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.CUSTOMER_PROPERTY}`, null, [
      createRoute("create", <Create />),
      createRoute("list/:year", <View />),
      createRoute("view/:id", <ViewById />),
      createRoute("report/:id", <Report />),
      createRoute(
        commonRoutesConstants.YEAR_LIST,
        <CommonYearList
          uri={url.GET_BY_YEAR}
          isCreate={true}
          createRoute={{
            path: `/${routeConstants.CUSTOMER_PROPERTY}/create`,
          }}
          title={routeConstants.CUSTOMER_PROPERTY}
          navigateToFolder={`/${routeConstants.CUSTOMER_PROPERTY}/list/`}
          permissionFormatConstants={FormatConstants.CUSTOMER_PROPERTY.LIST_OF_CUSTOMER_PROPERTY}
        />
      ),
    ]),
  ]
);

export default CPRoutes;
