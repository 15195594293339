import { routeConstants } from "services/keyConstants";

const baseUrl = `/${routeConstants.CUSTOMER_PROPERTY}`;

export const CREATE = `${baseUrl}/`;
export const GET_ALL = `${baseUrl}/get-all`;
export const UPDATE_DATA = `${baseUrl}/`;
export const GET_BY_ID = `${baseUrl}/`;
export const GET_BY_YEAR = `${baseUrl}/year-list`;
export const DOWNLOAD = `${baseUrl}/download`;
export const DOWNLOAD_ALL = `${baseUrl}/download-all`;
