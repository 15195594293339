import { routeConstants } from "services/keyConstants";

const baseUrl = `/${routeConstants.CUSTOMER_SERVICE}`;

export const POST_CUSTOMER_SERVICING = `${baseUrl}/`;
export const UPDATE_REPORT = `${baseUrl}/update-record/`;
export const CREATE_SERVICE_REPORT = `${baseUrl}/create-report`;
export const GET_BY_ID_CUSTOMER = `${baseUrl}/`;
export const PUBLIC_FORM = `${baseUrl}/public`;
export const GET_ALL_SERVICE_FEEDBACK = `${baseUrl}/get-dynamic-document`;
export const UPDATE_SPECIFIC_DATA = `${baseUrl}/update-specific`;
export const DOWNLOAD = `${baseUrl}/download`;
export const GET_YEAR_LIST = `${baseUrl}/year-list`;
export const GET_PARENT = `/product/get-parent`;
export const CREATE_FEEDBACK_REPORT = `${baseUrl}/create-feedback`
