import { routeConstants } from "services/keyConstants";

let baseUrl = "/" + routeConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION;

export const CREATE_ORDER = `${baseUrl}/create-order`;
export const ADD_DATA = `${baseUrl}/`;

export const CREATE_CUSTOMER_ORDER = `${baseUrl}/create-order-customer`;

export const GET_BY_ID = `${baseUrl}/`;

export const GET_USER_SPECIFIC = `${baseUrl}/get-user-specific`;

export const GET_YEAR_LIST = `${baseUrl}/year-list`;

export const GET_ALL_PENDING = `${baseUrl}/get-all-pending`;

export const GET_ALL_DATA_BY_TYPE_SPECIFIC = `${baseUrl}/get-specific`;

export const UPDATE_SPECIFIC_DATA = `${baseUrl}/update-specific-data`;

export const UPDATE_DATA_ORDER = `${baseUrl}/update-specific-order`;

export const UPDATE_DATA_MEDIA = `${baseUrl}/media`;

export const DOWNLOAD = `${baseUrl}/download`;
export const DOWNLOAD_REGISTER = `${baseUrl}/download-register`;
export const DOWNLOAD_AGREEMENT = `${baseUrl}/download-agreement`;
export const DOWNLOAD_List_OF_DISTRIBUTORS = `${baseUrl}/download-list-of-distributors`;

export const GET_BY_LINK = `${baseUrl}/get-by-link`;
export const GET_ALL_FORMATS = `${baseUrl}/get-master-docs`;

export const GET_FILTERED_DOCS = `${baseUrl}/get-filtered-docs`;

export const UPDATE_DISTRIBUTOR = `${baseUrl}/update-distributor`;
export const GET_PARENT = `/product/get-parent`;

export const UPLOAD_DOCUMENT = `${baseUrl}/upload-document/`;
export const UPLOAD_USER_TRAINING_DOCUMENT = `${baseUrl}/upload-user-training-document/`;

export const GET_BY_FILTER = `${baseUrl}/get-by-filter`;
