import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Button,
  Table,
  Box,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Pagination,
  MenuItem,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  DialogContent,
  DialogTitle,
  Stack,
  Paper,
} from "@mui/material";
import { Filter as FilterIcon } from "iconsax-react";
import { Colors } from "services/keyConstants";
import CustomDialog from "components/reusables/CustomDialog";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import withRouter from "components/withRouter";
import { GET_ALL } from "./Utils/UrlHelper";
import SearchAndViewToggle from "components/reusables/SearchAndViewToggle";
import dayjs from "dayjs";
import MainCard from "components/MainCard";
import SkeletonTable from "components/SkeletonTable";

const BMRListTableCopy = (props) => {
  const { ApiServices } = props,
    { navigate } = props?.router;
  const [filterObj, setFilterObj] = useState({
    dateRange: [null, null],
    productType: "",
    product: "",
    productId: "",
    status: "", // Add productId to filterObj
  });
  const [isFilterModelOpen, setIsFilterModelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [fiterData, setFiterData] = useState({
    filters: {}, // Ensure filters is an object
    searchQuery: "", // Default search query
    sortFd: "", // Default sorting field
    sortOrder: "asc", // Default sorting order
  });

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    limit: 10,
    currentPage: 1,
  });
  const [filter, setFilter] = useState({
    viewMode: "grid",
    search: "",
  });
  const bmrFilterData = useRef({
    currentPage: 1,
    totalPage: 1,
  });
  const [filteredData, setFilteredData] = useState([]);

  const column = useMemo(() => [
    {
      Header: "BMR No",
      accessor: "bmrNo",
      type: "string",
      Cell: (cellProps) => {
        const { bmrNumber, isFinalCreated, _id } =
          cellProps?.row?.original || {};

        // Determine navigation path based on isFinalCreated
        const navigatePath = isFinalCreated
          ? `/production/final-split-bmr?id=${_id}`
          : `/production/batch-manufacturing-record?id=${_id}`;

        return (
          <div
            className="d-flex align-items-center"
            style={{
              cursor: "pointer",
              color: "blue",
            }}
            onClick={() => navigate(navigatePath)}
          >
            <div className="flex-shrink-0"></div>
            {bmrNumber || "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Product Name-Product Code",
      accessor: "productName-productCode",
      type: "string",
      Cell: (cellProps) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0"></div>
            {cellProps?.row?.original?.productId?.productName || "N/A"}-
            {cellProps?.row?.original?.productId?.productData?.[0]
              ?.productCode || "N/A"}
          </div>
        </>
      ),
    },
    {
      Header: "Specification",
      accessor: "specification",
      type: "string",
      Cell: (cellProps) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0"></div>
            {cellProps?.row?.original?.productSize || "N/A"}
          </div>
        </>
      ),
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      type: "string",
      Cell: (cellProps) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0"></div>
            {cellProps?.row?.original?.quantity || "N/A"}
          </div>
        </>
      ),
    },
    {
      Header: "Product Type",
      accessor: "productType",
      type: "string",
      Cell: (cellProps) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0"></div>
            {cellProps?.row?.original?.productType || "N/A"}
          </div>
        </>
      ),
    },
    {
      Header: "Status",
      accessor: "bmrStatus",
      type: "string",
      Cell: ({ row }) => {
        const status = row?.original?.bmrStatus;
        const displayStatus =
          status === "Active"
            ? "In Progress"
            : status === "Closed"
            ? "Closed"
            : "N/A";

        return (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0"></div>
            {displayStatus}
          </div>
        );
      },
    },
  ]);

  const getAllBMR = async (filters = {}) => {
    setIsLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page: bmrFilterData?.current?.currentPage,
        limit: paginationData?.limit,
        ...(filter?.search?.length > 0 && { search: filter?.search }),
        ...(filters.startDate && { startDate: filters.startDate }),
        ...(filters.endDate && { endDate: filters.endDate }),
        ...(filters.productType && { productType: filters.productType }),
        ...(filters.status && {
          status: filters.status === "In Progress" ? "Active" : filters.status, // ✅ Map status correctly
        }),
        ...(filters.productName && { productName: filters.productName }),
        ...(filters.productCode && { productCode: filters.productCode }),
      }).toString();

      const uri = `${GET_ALL}?${queryParams}`;
      let result = await ApiServices?.callServiceGet(uri);

      if (result?.meta?.success) {
        setData(result.data);
        setFilteredData(result.data);
        bmrFilterData.current.totalPage = result?.meta?.totalPages;
      } else {
        setData([]);
        setFilteredData([]);
        bmrFilterData.current.currentPage = 1;
        bmrFilterData.current.totalPage = 1;
      }
    } catch (error) {
      console.error("GET_ALL_BMR", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bmrFilterData.current.currentPage = 1;
    getAllBMR();
  }, [filter?.search]);

  const handlePageClick = (e, val) => {
    bmrFilterData.current.currentPage = val;
    getAllBMR();
  };

  const handleModel = () => {
    setIsFilterModelOpen(!isFilterModelOpen);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleSearch = (searchKey, value) => {
    setPaginationData((prevData) => ({
      ...prevData,
      currentPage: 1, // Reset to first page on search/filter update
    }));

    setFiterData((prevData) => {
      const updatedFilters = { ...prevData.filters, [searchKey]: value };

      return {
        ...prevData,
        filters: updatedFilters,
        searchQuery: value,
        sortFd: searchKey, // Set sorting field
        sortOrder:
          prevData.sortFd === searchKey && prevData.sortOrder === "asc"
            ? "desc"
            : "asc", // Toggle sorting
      };
    });

    // Reset filter criteria when a new search is performed
    setFilterObj({
      dateRange: [null, null],
      productType: "",
      product: "",
      status: "",
    });
  };

  const submitFilter = () => {
    const isDateRangeValid =
      Array.isArray(filterObj.dateRange) && filterObj.dateRange.length === 2;

    const startDate = isDateRangeValid ? filterObj.dateRange[0] : null;
    const endDate = isDateRangeValid ? filterObj.dateRange[1] : null;

    let status = filterObj.status;
    if (status === "In Progress") status = "Active"; // ✅ Fix status mapping

    const productType = filterObj.productType || "";
    const productId = filterObj.productId || "";

    const filteredData = data.filter((item) => {
      const isProductTypeMatched = productType
        ? item.productType === productType
        : true;
      const isProductMatched = productId
        ? item.productId?._id === productId
        : true;
      const isStatusMatched = status ? item.bmrStatus === status : true;

      const createdAt = new Date(item.createdAt);
      const isDateMatched =
        startDate && endDate
          ? createdAt >= new Date(startDate) && createdAt <= new Date(endDate)
          : true;

      return (
        isProductTypeMatched &&
        isProductMatched &&
        isDateMatched &&
        isStatusMatched
      );
    });

    setFilteredData(filteredData);
    handleModel();
  };

  const columns = useMemo(() => [
    {
      Header: "Sr.No",
      accessor: "srno",
      Cell: (cellProps) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {cellProps.row.index +
              1 +
              (paginationData?.currentPage - 1) * paginationData?.limit}
          </div>
        </div>
      ),
    },
    ...column,
  ]);

  const resetFilter = () => {
    setFilteredData(data); // Reset to the original data
    setFilterObj({
      dateRange: [null, null],
      productType: "",
      product: "",
      productId: "",
      status: "", // Reset productId
    });
  };

  const productOptions = data?.map((doc) => {
    const productName = doc.productId?.productName || "";
    const productCode = doc.productId?.productData?.[0]?.productCode || "";
    const productId = doc.productId?._id || ""; // Include productId

    return {
      label: `${productName}-${productCode}`, // Display value in the dropdown
      value: `${productName}-${productCode}`, // Value to store in filterObj
      productId, // Include productId for filtering
    };
  });

  const isFilterApplied = () => {
    return (
      filterObj.dateRange.some((date) => date !== null) || // Check if dateRange has non-null values
      filterObj.productType !== "" || // Check if productType is selected
      filterObj.product !== "" || // Check if product is selected
      filterObj.status !== "" // Check if status is selected
    );
  };

  return (
    <>
      <MainCard
        title="Batch Manufacturing Record"
        secondary={
          <Stack sx={{ display: "flex", flexDirection: "row" }} gap={2}>
            {isFilterApplied() && (
              <Button
                variant="contained"
                color="secondary"
                onClick={resetFilter}
                sx={{
                  whiteSpace: "nowrap",
                  minHeight: "35px",
                  marginLeft: "10px",
                }}
              >
                Clear Filter
              </Button>
            )}
            <Button
              variant="contained"
              color="success"
              id="filter-btn"
              sx={{ whiteSpace: "nowrap", minHeight: "35px" }}
              onClick={handleModel}
            >
              <FilterIcon />
            </Button>
          </Stack>
        }
      >
        <CustomDialog
          open={isFilterModelOpen}
          onClose={handleModel}
          ariaLabelledby="date-range-dialog"
        >
          <DialogTitle>Apply Filter</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              paddingTop: "10px !important",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                value={filterObj?.dateRange}
                onChange={(newValue) =>
                  setFilterObj({ ...filterObj, dateRange: newValue })
                }
                localeText={{ start: "Start Date", end: "End Date" }}
              />
            </LocalizationProvider>

            <FormControl fullWidth>
              <InputLabel>Select Product Type</InputLabel>
              <Select
                value={filterObj?.productType || ""}
                onChange={(event) =>
                  setFilterObj({
                    ...filterObj,
                    productType: event.target.value,
                  })
                }
              >
                {["Assembly", "Production"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Select Status</InputLabel>
              <Select
                value={filterObj?.status || ""}
                onChange={(event) =>
                  setFilterObj({
                    ...filterObj,
                    status: event.target.value,
                  })
                }
              >
                {["In Progress", "Closed"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Select Product</InputLabel>
              <Select
                value={filterObj?.product || ""}
                onChange={(event) => {
                  const selectedProduct = productOptions.find(
                    (option) => option.value === event.target.value
                  );
                  setFilterObj({
                    ...filterObj,
                    product: event.target.value,
                    productId: selectedProduct?.productId || "", // Store productId
                  });
                }}
              >
                {productOptions?.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModel} color="error" variant="contained">
              Cancel
            </Button>
            <Button onClick={submitFilter} color="success" variant="contained">
              Apply
            </Button>
          </DialogActions>
        </CustomDialog>

        <Box>
          {isLoading ? (
            <>No Data Found</>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end", // Aligns content to the right
                  padding: "8px 0",
                  gap: 2, // Maintains spacing
                  flexWrap: "nowrap", // Prevents wrapping
                }}
              >
                <SearchAndViewToggle
                  filter={filter}
                  setFilter={setFilter}
                  width={12}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "5px 0px 10px 0px",
                  gap: "10px",
                }}
              >
                {isLoading ? (
                  <SkeletonTable />
                ) : (
                  <TableContainer component={Paper}>
                    <Table style={{ borderRadius: "10px" }}>
                      {/* Table Head */}
                      <TableHead>
                        <TableRow>
                          {columns.map((column, colIndex) => (
                            <TableCell
                              key={column.accessor}
                              className="text-center"
                              onClick={() => handleSearch(column.accessor)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    minWidth:
                                      column?.type === undefined ? "" : "150px",
                                    maxWidth:
                                      column?.type === undefined ? "" : "200px",
                                  }}
                                >
                                  {column?.Header}
                                </span>
                              </div>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      {/* Table Body */}
                      <TableBody
                        style={{ boxShadow: "0px 2px 5px rgba(0,0,0,0.3)" }}
                      >
                        {filteredData.length > 0 ? (
                          filteredData.map((item, rowIndex) => (
                            <TableRow
                              key={item?.id || rowIndex}
                              style={{ backgroundColor: Colors.DEFAULT_COLOR }}
                            >
                              {columns.map((column, colIndex) => (
                                <TableCell key={column.accessor}>
                                  {column.Cell
                                    ? column.Cell({
                                        row: {
                                          original: item,
                                          index: rowIndex,
                                        },
                                      })
                                    : item[column.accessor]}

                                  {colIndex !== columns.length - 1 && (
                                    <Typography
                                      variant="span"
                                      style={{
                                        position: "absolute",
                                        width: "1px",
                                        height: "50%",
                                        backgroundColor:
                                          "rgba(219, 224, 229, 0.65)",
                                        right: 0,
                                        top: "20%",
                                      }}
                                    />
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={columns.length} align="center">
                              No Data Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </>
          )}
        </Box>
        <Box display="flex" justifyContent="center">
          <Pagination
            count={bmrFilterData?.current?.totalPage ?? 1}
            page={bmrFilterData?.current?.currentPage ?? 1}
            onChange={(e, val) => handlePageClick(e, val)}
            color="primary"
            hidePrevButton
            hideNextButton
          />
        </Box>
      </MainCard>
    </>
  );
};

export default withRouter(BMRListTableCopy);
