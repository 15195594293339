import React, { useState, useImperativeHandle } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Paper,
  Box,
  IconButton,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  useTheme,
  InputLabel,
  Autocomplete,
  FormHelperText,
  DialogTitle,
  Divider,
  DialogContent,
  Typography,
  DialogActions,
  Grid,
  Checkbox,
  Tooltip,
} from "@mui/material";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Edit, Edit2, Eye, SaveAdd, Trash } from "iconsax-react";
import CustomTooltip from "./CustomTooltip";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  extractUserIdAndName,
  getItem,
  getUserData,
  isNotNullOrUndefined,
  toCamelCase,
} from "services/utils";
import DynamicNotification from "components/DynamicNotification";
import "./DownloadLoader.css";
import dayjs from "dayjs";
import CustomDialog from "./CustomDialog";
import ESign from "components/ESign";

const DynamicTable = ({
  columns,
  setColumns,
  rows,
  setRows,
  handleSubmitRef,
  isStatic = false,
  isViewMode = false,
  isCustomNavigation = false,
  editOnChange = "",
  deleteRowFunction = "",
  isNumberDynamic = false,
  viewMedia = "",
  isNoDataMsg = true,
  isStrictData = false,
  errors,
  setErrors,
  isConditionalDate = false,
  isDeleteConfirmModal = false,
  handleESign,
  openEsign,
  setOpenEsign,
  isExtraButtons = false,
  hideExtraButtons = false,
  disableButtonsOnPermission,
}) => {
  const userData = getItem("user_data");
  const defaultValuesObject = columns?.reduce(
    (acc, item) =>
      item?.isReadOnly && item?.defaultValue
        ? { ...acc, [item?.value]: item?.defaultValue }
        : acc,
    {}
  );
  const theme = useTheme();
  const [newColumn, setNewColumn] = useState("");
  const [deleteModalConfirm, setDeleteModalConfirm] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState("");
  const [newRow, setNewRow] = useState(
    defaultValuesObject ? defaultValuesObject : {}
  );
  const [selectedRow, setSelectedRow] = useState({});
  const [editingCell, setEditingCell] = useState(null);
  const [editingHeader, setEditingHeader] = useState(null);
  const [headerInput, setHeaderInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedField, setSelectedField] = useState("");
  const [longModalOpen, setLongModalOpen] = useState(false);
  const [modalText, setModalText] = useState({});

  const addColumn = () => {
    if (newColumn.trim()) {
      setColumns([
        ...columns,
        { name: newColumn.trim(), value: toCamelCase(newColumn) },
      ]);
      setRows(rows.map((row) => ({ ...row, [toCamelCase(newColumn)]: "" })));
      setNewColumn("");
    }
  };

  const getNestedValue = (obj, path) => {
    return path
      ?.split(".")
      ?.reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
        obj
      );
  };

  const getValue = (row, col) => {
    if (!col) return "";

    const resolveValue = (source, key) => {
      if (typeof key !== "string") {
        return undefined;
      }
      return key.includes(".") ? getNestedValue(source, key) : source?.[key];
    };

    if (col?.isReadOnly) {
      if (col?.defaultValue) {
        return (
          resolveValue(col?.defaultValue, col.displayValue) ??
          resolveValue(row, col?.defaultValue) ??
          resolveValue(row, col?.displayValue) ??
          resolveValue(row, col?.value) ??
          null
        );
      } else {
        return (
          resolveValue(row, col?.value)?.[col.displayValue] ??
          resolveValue(row, col?.value) ??
          null
        );
      }
    } else {
      return col.displayValue
        ? resolveValue(row, col?.value)?.[col?.displayValue] ??
        resolveValue(row, col?.value) ??
        null
        : resolveValue(row, col?.value) ?? null;
    }
  };

  const deleteColumn = (colValue) => {
    setColumns(columns?.filter((column) => column.value !== colValue));
    setRows(
      rows?.map((row) => {
        const updatedRow = { ...row };
        delete updatedRow[colValue];
        return updatedRow;
      })
    );
  };

  const addRow = () => {
    setRows([...rows, newRow]);
    setNewRow({});
  };

  const deleteRowModel = (rowIndex) => {
    setDeleteModalConfirm(true);
    setDeleteRowIndex(rowIndex);
  };

  const deleteRow = (rowIndex) => {
    isDeleteConfirmModal
      ? setRows(rows?.filter((_, index) => index !== deleteRowIndex))
      : setRows(rows?.filter((_, index) => index !== rowIndex));
    setDeleteModalConfirm(false);
  };

  const handleInputChange = (e, columnValue, rows) => {
    const newValue = e.target.value;
    setNewRow((prevRow) => ({
      ...prevRow,
      [columnValue]: newValue,
    }));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      rows?.forEach((row, index) => {
        if (newErrors[index]) {
          if (newErrors[index][columnValue]) {
            delete newErrors[index][columnValue];

            if (Object.keys(newErrors[index]).length === 0) {
              delete newErrors[index];
            }
          }
        }
      });
      return newErrors;
    });
  };

  const handleCellChange = (e, rowIndex, columnValue) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][columnValue] = e.target.value;
    setRows(updatedRows);
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[rowIndex] && newErrors[rowIndex][columnValue]) {
        delete newErrors[rowIndex][columnValue];
        if (Object.keys(newErrors[rowIndex]).length === 0) {
          delete newErrors[rowIndex];
        }
      }
      return newErrors;
    });
  };

  const handleSubmitData = () => {
    const filteredRows = [...rows, newRow].filter((row) =>
      Object.values(row).some((value) => value.trim() !== "")
    );
    setRows(filteredRows);
    setNewRow({});
    return filteredRows;
  };

  useImperativeHandle(handleSubmitRef, () => ({
    handleSubmitData,
  }));

  const startEditingCell = (rowIndex, columnValue) => {
    setEditingCell({ rowIndex, columnValue });
  };

  const saveEditingCell = () => {
    setEditingCell(null);
  };

  const startEditingHeader = (colIndex, currentName) => {
    setEditingHeader(colIndex);
    setHeaderInput(currentName);
  };

  const saveEditingHeader = (colIndex) => {
    const oldColumn = columns[colIndex];
    const newColumnValue = toCamelCase(headerInput);

    const updatedColumns = [...columns];
    updatedColumns[colIndex] = { name: headerInput, value: newColumnValue };

    const updatedRows = rows?.map((row) => {
      const updatedRow = { ...row, [newColumnValue]: row[oldColumn?.value] };
      delete updatedRow[oldColumn?.value];
      return updatedRow;
    });

    setColumns(updatedColumns);
    setRows(updatedRows);
    setEditingHeader(null);
    setHeaderInput("");
  };

  const handleDropdownChange = (e, rowIndex, columnValue) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][columnValue] = e?.target?.value;
    setRows(updatedRows);
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[rowIndex] && newErrors[rowIndex][columnValue]) {
        delete newErrors[rowIndex][columnValue];
        if (Object.keys(newErrors[rowIndex]).length === 0) {
          delete newErrors[rowIndex];
        }
      }
      return newErrors;
    });
  };

  const handleDocumentUpload = (e, rowIndex, fieldName) => {
    const file = e.target.files;
    const updatedRows = [...rows];
    updatedRows[rowIndex][`${fieldName}_document`] = file;
    setRows(updatedRows);
  };

  const handleDateChange = (date, rowIndex, columnValue) => {
    if (rowIndex !== null) {
      setRows((prevRows) =>
        prevRows?.map((row, index) =>
          index === rowIndex
            ? { ...row, [columnValue]: date?.toISOString() }
            : row
        )
      );
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (newErrors[rowIndex] && newErrors[rowIndex][columnValue]) {
          delete newErrors[rowIndex][columnValue];
          if (Object.keys(newErrors[rowIndex]).length === 0) {
            delete newErrors[rowIndex];
          }
        }
        return newErrors;
      });
    } else {
      setNewRow((prevNewRow) => ({
        ...prevNewRow,
        [columnValue]: date?.toISOString(),
      }));
    }
  };

  const handleRadioChange = (e, rowIndex, columnValue) => {
    const value = e?.target?.value;
    if (rowIndex !== null) {
      const updatedRows = [...rows];
      updatedRows[rowIndex][columnValue] = value;
      setRows(updatedRows);
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (newErrors[rowIndex] && newErrors[rowIndex][columnValue]) {
          delete newErrors[rowIndex][columnValue];
          if (Object.keys(newErrors[rowIndex]).length === 0) {
            delete newErrors[rowIndex];
          }
        }
        return newErrors;
      });
    } else {
      setNewRow((prevNewRow) => ({
        ...prevNewRow,
        [columnValue]: value,
      }));
    }
  };

  const handleCheckboxChange = (e, rowIndex, columnValue) => {
    const isChecked = e?.target?.checked;

    if (rowIndex !== null) {
      const updatedRows = [...rows];
      updatedRows[rowIndex][columnValue] = isChecked; // Store true/false
      setRows(updatedRows);
    } else {
      setNewRow((prevNewRow) => ({
        ...prevNewRow,
        [columnValue]: isChecked, // Store true/false for new row
      }));
    }
  };

  const handleModal = (e, rowIndex, columnValue) => {
    setSelectedField({
      rowIndex: rowIndex,
      columnValue: columnValue,
    });
    setIsOpen(true);
  };

  const handleSubmitModalData = (selectedUser) => {
    if (!selectedUser) return;
    if (selectedField?.rowIndex === "") {
      setNewRow({
        ...newRow,
        [selectedField?.columnValue]: extractUserIdAndName(selectedUser),
      });
    } else {
      const updatedRows = [...rows];
      updatedRows[selectedField?.rowIndex][selectedField?.columnValue] =
        extractUserIdAndName(selectedUser);
      setRows(updatedRows);
    }
  };

  const handleChildEsign = (type, row, rowIdex, fieldName) => {
    setOpenEsign(true);
    setSelectedRow({ type: type, row, rowIdex, fieldName });
  };

  const handleSubmitEsign = () => {
    const value = {
      time: new Date().toISOString(),
      userData: getUserData(userData),
    };
    if (selectedRow?.type === "newRow") {
      setNewRow((pre) => ({
        ...pre,
        [selectedRow?.fieldName]: value,
      }));
    } else {
      if (
        selectedRow?.rowIndex >= 0 &&
        selectedRow?.rowIndex < rows.length &&
        rows[selectedRow?.rowIndex]
      ) {
        updatedRows[selectedRow?.rowIndex][selectedRow?.fieldName] = value;
        setRows(updatedRows);
      } else {
        console.error(
          "Invalid rowIndex or undefined row object:",
          selectedRow?.rowIndex
        );
      }
      const updatedRows = [...rows];
      updatedRows[selectedRow?.rowIdex] = {
        ...updatedRows[selectedRow?.rowIdex],
        [selectedRow?.fieldName]: value,
      };
      setRows(updatedRows);
    }
  };

  const handleModalOpen = (item) => {
    setModalText(item);
    setLongModalOpen(true);
  };

  return (
    <>
      {isOpen ? (
        <DynamicNotification
          open={isOpen}
          setOpen={setIsOpen}
          isMultipleDepartment={false}
          isMultipleUser={false}
          handleSubmit={handleSubmitModalData}
        />
      ) : null}
      {openEsign ? (
        <ESign
          open={openEsign}
          setOpen={setOpenEsign}
          handleSubmit={handleSubmitEsign}
        />
      ) : null}
      <TableContainer component={Paper} className="temp">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns?.map((col, colIndex) => (
                <TableCell key={colIndex}>
                  {editingHeader === colIndex ? (
                    !isStatic && (
                      <Box display="flex" alignItems="center">
                        <TextField
                          value={headerInput}
                          onChange={(e) => setHeaderInput(e.target.value)}
                          onBlur={() => saveEditingHeader(colIndex)}
                          autoFocus
                        />
                        <IconButton
                          onClick={() => saveEditingHeader(colIndex)}
                          style={{ marginLeft: "10px" }}
                        >
                          <SaveAdd />
                        </IconButton>
                      </Box>
                    )
                  ) : (
                    <Box display="flex" alignItems="center">
                      {col?.name}
                      {!isViewMode && !isStatic && (
                        <>
                          <IconButton
                            color="success"
                            onClick={() =>
                              startEditingHeader(colIndex, col?.name)
                            }
                            style={{ marginLeft: "10px" }}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => deleteColumn(col?.value)}
                            style={{ marginLeft: "10px" }}
                          >
                            <Trash />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  )}
                </TableCell>
              ))}
              {!isViewMode && !isStrictData && <TableCell>Actions</TableCell>}
              {isCustomNavigation && <TableCell>Action</TableCell>}
              {isExtraButtons?.length > 0 && <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns?.map((col, colIndex) => (
                  <TableCell key={colIndex}>
                    {col?.type === "radio" ? (
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={row[col?.value] || ""}
                          onChange={(e) =>
                            handleRadioChange(e, rowIndex, col?.value)
                          }
                        >
                          {col?.options?.map((option, index) => (
                            <FormControlLabel
                              key={index}
                              value={option}
                              control={<Radio />}
                              label={option}
                            />
                          ))}
                        </RadioGroup>
                        {errors?.[rowIndex]?.[col?.value] && (
                          <FormHelperText style={{ color: "red" }}>
                            {errors?.[rowIndex]?.[col?.value]}
                          </FormHelperText>
                        )}
                      </FormControl>
                    ) : col?.type === "checkbox" ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={row[col?.value] ?? false}
                            onChange={(e) =>
                              handleCheckboxChange(e, rowIndex, col?.value)
                            }
                          />
                        }
                        label={col?.label || ""}
                      />
                    ) : col?.type === "date" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          name="dateOfRequest"
                          sx={{
                            minWidth: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: errors?.[rowIndex]?.[col?.value]
                                ? "red"
                                : "default",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: errors?.[rowIndex]?.[col?.value]
                                ? "red"
                                : "default",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: errors?.[rowIndex]?.[col?.value]
                                ? "red"
                                : "default",
                            },
                          }}
                          value={
                            dayjs(
                              row?.[col?.value]
                                ? dayjs(row[col?.value]).isValid()
                                  ? dayjs(row[col?.value])
                                  : getValue(row, col) || null
                                : getValue(row, col) || null
                            ).isValid()
                              ? dayjs(
                                row?.[col?.value]
                                  ? dayjs(row[col?.value]).isValid()
                                    ? dayjs(row[col?.value])
                                    : getValue(row, col) || null
                                  : getValue(row, col) || null
                              )
                              : null
                          }
                          format="DD/MM/YYYY"
                          onChange={(date) =>
                            handleDateChange(date, rowIndex, col?.value)
                          }
                          minDate={isConditionalDate ? dayjs() : null}
                          readOnly={isViewMode || col?.isReadOnly}
                          textField={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                        {errors?.[rowIndex]?.[col?.value] && (
                          <span style={{ color: "red" }}>
                            {errors?.[rowIndex]?.[col?.value]}
                          </span>
                        )}
                      </LocalizationProvider>
                    ) : col?.type === "esignButton" ? (
                      row[col?.value] && row[col?.value]?.userData?.name ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={`${row[col?.value]?.userData?.name} - ${dayjs(
                            row[col?.value]?.time
                          ).format("DD/MM/YYYY HH:mm:ss")}`}
                          InputProps={{ readOnly: true }}
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={isViewMode || col?.isReadOnly}
                          onClick={() => {
                            handleESign
                              ? handleESign(col?.value, rowIndex)
                              : handleChildEsign(
                                "rows",
                                row,
                                rowIndex,
                                col?.value
                              );
                          }}
                        >
                          ESign
                        </Button>
                      )
                    ) : col?.type === "dropdown" ? (
                      <>
                        <FormControl fullWidth>
                          <InputLabel>Select Option</InputLabel>
                          <Select
                            multiple={col?.isMultiple}
                            sx={{
                              minWidth: "100px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: errors?.[rowIndex]?.[col?.value]
                                  ? "red"
                                  : "default",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: errors?.[rowIndex]?.[col?.value]
                                  ? "red"
                                  : "default",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: errors?.[rowIndex]?.[col?.value]
                                  ? "red"
                                  : "default",
                              },
                            }}
                            value={
                              row?.[col?.value]
                                ? row[col?.value]
                                : col?.isMultiple
                                  ? []
                                  : ""
                            }
                            onChange={(e) => {
                              let value =
                                col?.options?.filter((item) =>
                                  Array.isArray(e?.target?.value)
                                    ? e?.target?.value.includes(
                                      item[col.renderOptionValue] ?? item
                                    )
                                    : (item[col.renderOptionValue] ?? item) ===
                                    e?.target?.value
                                ) || null;
                              handleDropdownChange(e, rowIndex, col?.value);

                              if (col?.extraOnChange) {
                                col?.extraOnChange({
                                  e,
                                  rowIndex,
                                  colValue: col?.value,
                                  rows,
                                  value,
                                });
                              }
                            }}
                            fullWidth
                            disabled={isViewMode}
                          >
                            {col?.options?.map((option, index) => (
                              <MenuItem
                                key={index}
                                value={option[col.renderOptionValue] ?? option}
                              >
                                {option[col.renderOptionValue] ?? option}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.[rowIndex]?.[col?.value] && (
                            <span style={{ color: "red" }}>
                              {errors?.[rowIndex]?.[col?.value]}
                            </span>
                          )}
                        </FormControl>
                        {col?.isDocumentAdd &&
                          row?.[col?.value] === col?.condition &&
                          (isViewMode ? (
                            <CustomTooltip title="View Document">
                              <IconButton
                                color="primary"
                                onClick={() => isViewMode && viewMedia(row)}
                              >
                                <Eye />
                              </IconButton>
                            </CustomTooltip>
                          ) : (
                            <div style={{ marginTop: "10px" }}>
                              <label
                                htmlFor={`upload_${rowIndex}_${col?.value}`}
                              >
                                Upload Document (Photo/Video)
                              </label>
                              <input
                                type="file"
                                multiple
                                id={`upload_${rowIndex}_${col?.value}`}
                                accept="image/*,video/*"
                                onChange={(e) =>
                                  handleDocumentUpload(e, rowIndex, col?.value)
                                }
                              />
                            </div>
                          ))}
                      </>
                    ) : col?.type === "dynamicButton" ? (
                      <Button
                        onClick={(e) =>
                          col?.isNotificationValue
                            ? handleModal(e, rowIndex, col?.value)
                            : null
                        }
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "10px", marginTop: "10px" }}
                      >
                        {col?.dynamicValue
                          ? isNotNullOrUndefined(row[col?.value])
                            ? row[col?.value]?.name || ""
                            : newRow[col?.dynamicValue]?.name || ""
                          : col?.value}
                      </Button>
                    ) : col?.type === "dynamicDropdown" ? (
                      <Autocomplete
                        multiple
                        freeSolo
                        options={[]}
                        value={row[col.value] ? row[col.value] || [] : []}
                        onChange={(e, value) => {
                          const updatedRows = [...rows];
                          updatedRows[rowIndex][[col?.value]] = value;
                          setRows(updatedRows);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label={`Enter ${col.name}`} />
                        )}
                      />
                    ) : col?.type === "array-string" ? (
                      <Box>{row[col?.value]?.join(",")}</Box>
                    ) : col?.type === "array-dynamicDropdown" ? (
                      Object.entries(row[col?.value])?.map(
                        ([key, value], index) => (
                          <Box mb={2} key={index}>
                            <Autocomplete
                              value={value} // Dynamically handle the value
                              multiple
                              freeSolo
                              options={col?.options || []} // Use dynamic options from col if available
                              onChange={(event, newValue) => {
                                const updatedRows = [...rows];
                                // Dynamically update the specific column's value based on `col.value` and `key`
                                updatedRows[rowIndex][col?.value] = {
                                  ...updatedRows[rowIndex][col?.value],
                                  [key]: newValue, // Dynamically update the key's value
                                };

                                setRows(updatedRows); // Update the rows state dynamically
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={`${col?.name} for ${key}`} // Use the dynamic key for labeling
                                />
                              )}
                            />
                          </Box>
                        )
                      )
                    ) : editingCell &&
                      editingCell?.rowIndex === rowIndex &&
                      editingCell?.columnValue === col?.value ? (
                      <TextField
                        multiline={col?.type === "textarea"}
                        rows={
                          col?.type === "textarea" ? col?.rows ?? 4 : undefined
                        }
                        sx={{
                          width: "100%",
                        }}
                        label={`Enter ${col?.name}`}
                        value={col?.value ? row[col?.value] || "" : ""}
                        error={!!(errors?.row && errors.row[col?.value])}
                        helperText={!!(errors?.row && errors.row[col?.value])}
                        onChange={(e) =>
                          handleCellChange(e, rowIndex, col?.value)
                        }
                        type={col?.type ?? "text"}
                        onBlur={saveEditingCell}
                        autoFocus
                      />
                    ) : (
                      <Box display="flex" alignItems="center" minWidth={col?.isLongText ? "200px" : undefined}>
                        {colIndex === 0 && isNumberDynamic ? (
                          rowIndex + 1 || ""
                        ) : col?.isLongText ? (
                          <>
                            <span
                              onClick={() => handleModalOpen({ col, row: getValue(row, col) })}
                              style={{ cursor: "pointer", color: "blue", display: "inline-flex", alignItems: "center" }}
                            >
                              {getValue(row, col)?.length > 20
                                ? getValue(row, col).slice(0, 20) + "..."
                                : getValue(row, col) ?? ""}

                              {/* Add this part for "Click here for full view" */}
                              {getValue(row, col)?.length > 20 && (
                                <Tooltip title="Click for full view">
                                  <IconButton
                                    size="small"
                                    sx={{ marginLeft: '5px', color: 'gray' }}
                                  >
                                    <FullscreenIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </span>
                          </>
                        ) : (
                          getValue(row, col) ?? ""
                        )}

                        {errors?.[rowIndex]?.[col?.value] && (
                          <span style={{ color: "red" }}>
                            {errors?.[rowIndex]?.[col?.value]}
                          </span>
                        )}

                        {!isViewMode &&
                          ((colIndex === 0 && isNumberDynamic) ||
                            col?.isReadOnly ? null : (
                            <IconButton
                              onClick={() =>
                                startEditingCell(rowIndex, col?.value)
                              }
                              style={{ marginLeft: "10px" }}
                            >
                              <Edit />
                            </IconButton>
                          ))}
                      </Box>
                    )}
                  </TableCell>
                ))}
                {!isViewMode &&
                  !isStrictData &&
                  row?.isDeleteRowButton !== false && (
                    <TableCell>
                      <Button
                        onClick={() =>
                          isDeleteConfirmModal
                            ? deleteRowModel(rowIndex)
                            : deleteRow(rowIndex)
                        }
                        disabled={
                          disableButtonsOnPermission?.disableOnPermission
                            ? !disableButtonsOnPermission?.deleteButtonPermission
                            : false
                        }
                        variant="contained"
                        color="error"
                        style={{
                          marginRight: "10px",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          width: "max-content",
                        }}
                      >
                        Delete Row
                        <Trash size="32" />
                      </Button>
                    </TableCell>
                  )}
                {isExtraButtons && isExtraButtons
                  ?.filter((button) => {
                    if (typeof button?.isCondition === "function") {
                      return button?.isCondition(row); // Use function from parent
                    }
                    return button?.isCondition !== false; // Keep other buttons as-is
                  })
                  ?.length > 0 && (
                    <TableCell>
                      <Box display="flex" gap={2}>
                        {isExtraButtons
                          ?.filter((button) => {
                            if (typeof button?.isCondition === "function") {
                              return button?.isCondition(row); // Apply dynamic condition
                            }
                            return button?.isCondition !== false;
                          })
                          ?.map((button, index) => {
                            const shouldHide =
                              (hideExtraButtons && row?.[hideExtraButtons] === true) ||
                              (button?.hideButtons &&
                                row?.[button?.hideButtons?.key] === button?.hideButtons?.value) ||
                              (button?.hideOnKey && row?.[button?.hideOnKey] !== undefined);

                            if (!shouldHide) {
                              return (
                                <Button
                                  onClick={() => button?.callFunction(row, rowIndex)}
                                  disabled={
                                    button?.disableOnPermission ? !button?.permission : false
                                  }
                                  color={button?.color ?? "primary"}
                                  variant="contained"
                                  key={index}
                                >
                                  {button?.name}
                                </Button>
                              );
                            }
                            return null;
                          })}
                      </Box>
                    </TableCell>
                  )}
                {isCustomNavigation && (
                  <>
                    <TableCell>
                      <CustomTooltip title="Edit">
                        <IconButton
                          color="primary"
                          onClick={() =>
                            editOnChange && editOnChange(row, rowIndex)
                          }
                        >
                          <Edit2 />
                        </IconButton>
                      </CustomTooltip>
                      {deleteRowFunction !== "" && (
                        <CustomTooltip title="Delete">
                          <IconButton
                            color="error"
                            onClick={() =>
                              deleteRowFunction &&
                              deleteRowFunction(row, rowIndex)
                            }
                          >
                            <Trash />
                          </IconButton>
                        </CustomTooltip>
                      )}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
            {!isViewMode && !isStrictData && (
              <TableRow>
                {columns?.map((col, colIndex) => (
                  <TableCell key={colIndex}>
                    {col?.type === "radio" ? (
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={newRow[col?.value] || ""}
                          onChange={(e) =>
                            handleRadioChange(e, null, col?.value)
                          }
                          row
                        >
                          {col?.options?.map((option, index) => (
                            <FormControlLabel
                              key={index}
                              value={option}
                              control={<Radio />}
                              label={option}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    ) : col?.type === "checkbox" ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={newRow[col?.value] ?? false}
                            onChange={(e) =>
                              handleCheckboxChange(e, null, col?.value)
                            }
                          />
                        }
                        label={col?.label || ""}
                      />
                    ) : col?.type === "date" ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          minDate={dayjs()}
                          sx={{ minWidth: "150px" }}
                          name="dateOfRequest"
                          value={dayjs(newRow[col?.value]) || null}
                          onChange={(date) =>
                            handleDateChange(date, null, col?.value)
                          }
                          format="DD/MM/YYYY"
                          readOnly={isViewMode || col?.isReadOnly}
                          textField={(params) => (
                            <TextField
                              placeholder={`Enter ${col?.name}`}
                              {...params}
                              fullWidth
                            />
                          )}
                        />
                      </LocalizationProvider>
                    ) : col?.type === "dropdown" ? (
                      <FormControl fullWidth>
                        <InputLabel>Select Option</InputLabel>
                        <Select
                          sx={{ minWidth: "100px" }}
                          multiple={col?.isMultiple}
                          value={
                            newRow?.[col?.value]
                              ? newRow[col?.value]
                              : col?.isMultiple
                                ? []
                                : ""
                          }
                          onChange={(e) => {
                            handleInputChange(e, col?.value, rows);
                            if (col?.extraOnChange) {
                              col?.extraOnChange(e, col?.value, rows);
                            }
                          }}
                          fullWidth
                        >
                          {col?.options?.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option[col.renderOptionValue] ?? option}
                            >
                              {option[col.renderOptionValue] ?? option}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors?.rows?.[col?.value] && (
                          <span style={{ color: "red" }}>
                            {errors?.rows?.[col?.value]}
                          </span>
                        )}
                      </FormControl>
                    ) : col?.type === "dynamicButton" ? (
                      <Button
                        onClick={(e) =>
                          col?.isNotificationValue
                            ? handleModal(e, "", col?.value)
                            : null
                        }
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "10px", marginTop: "10px" }}
                      >
                        {col?.dynamicValue
                          ? isNotNullOrUndefined(newRow[col?.value]?.name)
                            ? newRow[col?.value]?.name || ""
                            : col?.value
                          : col?.value}
                      </Button>
                    ) : col?.type === "esignButton" ? (
                      newRow[col?.value] ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={`${newRow[col?.value]?.userData?.name
                            } - ${dayjs(newRow[col?.value]?.time).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}`}
                          InputProps={{ readOnly: true }}
                        />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={isViewMode || col?.isReadOnly}
                          onClick={() => {
                            handleESign
                              ? handleESign(newRow[col?.value])
                              : handleChildEsign(
                                "newRow",
                                newRow,
                                undefined,
                                col?.value
                              );
                          }}
                        >
                          ESign
                        </Button>
                      )
                    ) : col?.type === "dynamicDropdown" ? (
                      <Autocomplete
                        multiple
                        freeSolo
                        options={[]}
                        value={newRow[col.value] || []}
                        onChange={(e, value) =>
                          setNewRow({ ...newRow, [col.value]: value })
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={`Enter ${col.name}`} />
                        )}
                      />
                    ) : col?.type === "textarea" ? (
                      <TextField
                        multiline={col?.type === "textarea"}
                        rows={
                          col?.type === "textarea" ? col?.rows ?? 4 : undefined
                        }
                        placeholder={`Enter ${col?.name}`}
                        label={`Enter ${col?.name}`}
                        type={col?.type ? col?.type : "text"}
                        value={
                          colIndex === 0 && isNumberDynamic
                            ? (rows?.length || 0) > 0
                              ? (rows?.length || 0) + 1
                              : (colIndex || 0) + 1
                            : col?.isReadOnly
                              ? col?.defaultValue?.[col?.displayValue] || ""
                              : newRow?.[col?.value] || ""
                        }
                        onChange={(e) => handleInputChange(e, col?.value, rows)}
                        error={!!errors?.rows?.[col?.value]}
                        helperText={errors?.rows?.[col?.value] || ""}
                        autoFocus
                        sx={{
                          width: "100%",
                          readOnly:
                            (colIndex === 0 && isNumberDynamic) ||
                              col?.isReadOnly
                              ? true
                              : false,
                        }}
                      />
                    ) : (
                      <>
                        <TextField
                          inputProps={{
                            readOnly:
                              (colIndex === 0 && isNumberDynamic) ||
                                col?.isReadOnly
                                ? true
                                : false,
                          }}
                          sx={{
                            width: `calc(${`Enter ${col?.name}`?.length
                              }ch + 16px)`,
                            maxWidth: "150px",
                            "& .MuiInputLabel-root": {
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? "#272a2e"
                                  : "#fff",
                            },
                          }}
                          placeholder={`Enter ${col?.name}`}
                          label={`Enter ${col?.name}`}
                          value={
                            colIndex === 0 && isNumberDynamic
                              ? (rows?.length || 0) > 0
                                ? (rows?.length || 0) + 1
                                : (colIndex || 0) + 1
                              : col?.isReadOnly
                                ? col?.defaultValue?.[col?.displayValue] || ""
                                : newRow?.[col?.value] || ""
                          }
                          onChange={(e) =>
                            handleInputChange(e, col?.value, rows)
                          }
                          error={!!errors?.rows?.[col?.value]}
                          helperText={errors?.rows?.[col?.value] || ""}
                        />
                      </>
                    )}
                  </TableCell>
                ))}
                {!(isViewMode === true ? false : isStrictData) ? (
                  <TableCell>
                    <Button
                      onClick={addRow}
                      variant="contained"
                      color="primary"
                    >
                      Add Row
                    </Button>
                  </TableCell>
                ) : null}
              </TableRow>
            )}
            {isNoDataMsg && isViewMode && (!rows || rows.length === 0) ? (
              <TableRow>
                <TableCell
                  colSpan={columns?.length}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  No data found
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
        {!isViewMode && !isStatic && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mt={3}
            mb={3}
          >
            <Box display="flex" alignItems="center" ml={2}>
              <TextField
                label="Add New Column"
                value={newColumn}
                onChange={(e) => setNewColumn(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              <Button onClick={addColumn} variant="contained" color="primary">
                Add Column
              </Button>
            </Box>
          </Box>
        )}
      </TableContainer>
      <CustomDialog
        open={deleteModalConfirm}
        onClose={() => {
          setDeleteModalConfirm(false);
        }}
        maxWidth="xs"
      >
        <DialogTitle fontWeight="bold">Confirm</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>Are You Sure Want To Delete Row?</Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="error"
              sx={{ marginRight: 2 }}
              onClick={() => setDeleteModalConfirm(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => deleteRow()}
            >
              Delete
            </Button>
          </Grid>
        </DialogActions>
      </CustomDialog>

      <CustomDialog open={longModalOpen} onClose={() => setLongModalOpen(false)}>
        <DialogTitle>{modalText?.col?.name}</DialogTitle>
        <DialogContent>
          <Typography>{modalText?.row}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLongModalOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </CustomDialog>

    </>
  );
};

export default DynamicTable;
