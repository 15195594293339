import MainCard from "components/MainCard";
import withRouter from "components/withRouter";
import React, { useEffect, useRef, useState } from "react";
import * as url from "../utils/urlHelper";
import CustomAccordionSkeleton from "components/CustomAccordionSkeleton";
import CustomAccordion from "components/CustomAccordian";
import Error404 from "components/reusables/404";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import CustomNotification from "components/CustomNotification";
import {
  FormatConstants,
  MasterDocumentTypeConstants,
} from "services/keyConstants";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  buildUrl,
  downloadFormat,
  filterUserById,
  getDocumentLifeCycleForMasterDoc,
  getUserData,
  sendNotification,
  showToast,
  TabPanel,
} from "services/utils";
const FinalInspectionList = (props) => {
  const {
    ApiServices,
    routeConstants,
    userData,
    onChangeDownloadState,
    DepartmentConstants,
    selectedRole,
  } = props,
    { navigate, params } = props.router;
  const { year } = params;
  const filterData = useRef({
    currentPage: 1,
    totalPage: 1,
  });
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openESign, setOpenESign] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [tabLabels, setTabLabels] = useState(["Open Final Inspection Report", "Close Final Inspection Report"]);
  const [tabValue, setTabValue] = useState(0)

  const getData = async (type) => {
    setIsLoading(true);
    try {
      const masterDocData = await getDocumentLifeCycleForMasterDoc(
        FormatConstants.MAMOFPRODUCT.FINAL_INSPECTION_REPORT,
        MasterDocumentTypeConstants.FORMAT
      );
      const reqObj = {
        masterDocId: masterDocData?._id,
        status: type,
      };
      const uri = url.GET_DATA_BY_STATUS
      const result = await ApiServices.callServicePostWithBodyData(uri, reqObj);

      if (result?.meta?.success) {
        setData(result?.data);
        filterData.current.totalPage = result?.meta?.totalPages;
      } else {
        setData([]);
        filterData.current.currentPage = 1;
        filterData.current.totalPage = 1;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData(tabValue === 0 ? "Open" : "Closed");
  }, [tabValue]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSubmit = async (selectedUser) => {
    let reqObj = {
      id: selectedData?._id,
      updateData: {
        [`productData.responsibleUser`]: Array.isArray(selectedUser) ? selectedUser?.[0] : selectedUser,
      },
      isMakeDecision: true,
    };
    const result = await ApiServices.callServicePutWithBodyData(
      url.UPDATE_SPECIFIC_BY_ID,
      reqObj
    );

    showToast(result);
    if (result?.meta?.success) {
      let obj = {
        dataArray: [
          {
            recipients: filterUserById(selectedUser),
            msg: `In-Process Inspection report is assigned`,
            status: "In-Process Inspection assigned",
            location: `${routeConstants.MAM_OF_PRODUCT}/final-process-form/${selectedData?._id}`,
          },
        ],
      };
      await sendNotification(obj);
      getData();
    }
  };

  const isValidUser = (data) => {
    const respoUsers = data?.productData?.notifiedUsers; // Ensure respoUsers is an array

    if (Array.isArray(respoUsers) && respoUsers.length > 0) {
      const matchingUser = respoUsers?.find(
        (user) =>
          user.userId === userData?._id && user?.designationId === selectedRole?.roleId
      );

      if (matchingUser) {
        return true; // User matches both userId and designationId
      } else {
        return "user"; // User doesn't match the notified users
      }
    } else if (!respoUsers || respoUsers.length === 0) {
      return "make decision"; // No notified users
    }
  };


  // pagiantion handler
  const handlePageClick = (e, val) => {
    filterData.current.currentPage = val;
    getData();
  };

  // Notification Modal Handler
  const handleNotification = (item) => {
    setSelectedData(item ?? {});
    setOpenESign(true);
  };

  const handleDownload = async (mainRecordId) => {
    try {
      let reqObj = {
        formatId: mainRecordId,
      };
      let uri = url.DOWNLOAD_INPROCESS_INSPECTION;
      await downloadFormat(uri, reqObj, onChangeDownloadState);
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  const renderContent = (data) => {
    return (
      isLoading ? (
        <CustomAccordionSkeleton />
      ) : data?.length === 0 ? (
        <Error404
          headingMain={`Oops, looks like there are no record, please created it!`}
        />
      ) : (
        data?.map((record, index) => {
          return (
            <CustomAccordion
              key={index}
              expanded={expanded === index}
              onChange={handleAccordionChange(index, record)}
              summary={
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Stack
                    sx={{ flexDirection: "row", alignItems: "center" }}
                    gap={2}
                  >
                    <Typography
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "flex-start",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {record?.productData?.productName ?? "-"} - {record?.productData?.quantity}
                    </Typography>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      startIcon={<ArrowForwardIcon />}
                      onClick={() => {
                        if (isValidUser(record) === true) {
                          navigate(
                            `/${routeConstants.MAM_OF_PRODUCT}/final-process-form/${record?._id}`
                          );
                        } else if (isValidUser(record) === "make decision") {
                          return showToast("Please make decision first!!!");
                        } else if (isValidUser(record) === "user") {
                          return showToast("You are not authorized!!!");
                        }
                      }}
                      sx={{ borderRadius: 10 }}
                    >
                      Show Plan
                    </Button>
                  </Stack>
                  <Stack sx={{ flexDirection: "row" }} gap={1}>
                    {/* {!record?.productData?.responsibleUser && ( // If all processData entries meet the criteria, don't show the buttons
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => handleNotification(record)}
                  >
                    Make Decision
                  </Button>
                )} */}
                    <Button
                      variant="contained"
                      onClick={() => handleDownload(record?._id)}
                    >
                      Download
                    </Button>
                  </Stack>
                </Stack>
              }
              details={
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>BMR Number</TableCell>
                          <TableCell>Product Name</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Product Size</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {record?.productData?.bmrNumber}
                          </TableCell>
                          <TableCell>
                            {record?.productData?.productName}
                          </TableCell>
                          <TableCell>
                            {record?.productData?.productSize}
                          </TableCell>
                          <TableCell>{record?.productData?.quantity}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              }
            />
          );
        })
      )
    )
  }

  return (
    <MainCard title="Final Inspection List">

      <Box sx={{ width: "100%" }}>
        <Tabs value={tabValue} onChange={(e, newVal) => setTabValue(newVal)} variant="fullWidth">
          {tabLabels.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>
        {tabLabels.map((_, index) => (
          <TabPanel key={index} value={tabValue} index={index}>
            {renderContent(data)}
          </TabPanel>
        ))}
        <Box display="flex" justifyContent="center" mt={4}>
          <Pagination
            count={filterData.current?.totalPage ?? 1}
            page={filterData.current?.currentPage ?? 1}
            onChange={(e, val) => handlePageClick(e, val)}
            color="primary"
            hidePrevButton
            hideNextButton
          />
        </Box>
      </Box>

      {openESign ? (
        <CustomNotification
          dialogTitle={"Select QC head to Notify"}
          open={openESign}
          setOpen={setOpenESign}
          handleSubmit={handleSubmit}
          isMultipleUser={false}
          isStaticDepartment={[DepartmentConstants.QUALITY_CONTROL_DEPARTMENT]}
        />
      ) : null}
    </MainCard>
  );
};

export default withRouter(FinalInspectionList);
