import { Badge, Divider, Grid, LinearProgress, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "store";
import user from "assets/images/welcomeImages/user.png";
import approved from "assets/images/welcomeImages/approved.png";
import info from "assets/images/welcomeImages/information.png";
import question from "assets/images/welcomeImages/questions.png";
import { useNavigate } from "react-router";
import { fetchCurrentStep } from "store/reducers/onBording";
import { useDispatch } from "react-redux";
import { getMasterDocDataById } from "services/utils";
import withRouter from "./withRouter";
import rocket1 from "assets/images/welcomeImages/rocket1.png";
import rocket2 from "assets/images/welcomeImages/rocket2.png";
import flash from "assets/images/welcomeImages/flash.png";
import write from "assets/images/welcomeImages/write.png";
import subicon1 from "assets/images/welcomeImages/subicon1.png";
import subicon2 from "assets/images/welcomeImages/subicon2.png";

const style = {
  position: "absolute",
  right: { lg: "4rem", sm: "2.5rem", xs: "2.5rem" },
  bottom: 0,
  borderRadius: "25px",
  position: "absolute",
  backgroundColor: "white",
  padding: "15px",
};

const Question = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="50.5"
      height="50.5"
      rx="25.25"
      fill="white"
    />
    <rect
      x="0.75"
      y="0.75"
      width="50.5"
      height="50.5"
      rx="25.25"
      stroke="#CFD1D4"
      stroke-width="0.5"
    />
    <path
      d="M23.9141 36.4141H28.0807V40.5807H23.9141V36.4141ZM25.9974 11.4141C21.4141 11.4141 17.6641 15.1641 17.6641 19.7474H21.8307C21.8307 17.4557 23.7057 15.5807 25.9974 15.5807C28.2891 15.5807 30.1641 17.4557 30.1641 19.7474C30.1641 23.9141 23.9141 23.2891 23.9141 30.1641V32.2474H28.0807V30.1641C28.0807 25.5807 34.3307 24.9557 34.3307 19.7474C34.3307 15.1641 30.5807 11.4141 25.9974 11.4141Z"
      fill="black"
    />
  </svg>
);

const HeroIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 7L6.52733 6.98667C6.61282 6.94396 6.70875 6.92664 6.80378 6.93677C6.8988 6.9469 6.98893 6.98404 7.0635 7.04381C7.13806 7.10357 7.19394 7.18345 7.22451 7.27399C7.25508 7.36453 7.25907 7.46193 7.236 7.55467L6.764 9.44533C6.74076 9.53811 6.74463 9.63561 6.77513 9.72626C6.80563 9.81691 6.86149 9.89691 6.93609 9.95678C7.01069 10.0166 7.10089 10.0538 7.196 10.064C7.2911 10.0741 7.38712 10.0568 7.47267 10.014L7.5 10M13 7.5C13 8.28793 12.8448 9.06815 12.5433 9.7961C12.2417 10.5241 11.7998 11.1855 11.2426 11.7426C10.6855 12.2998 10.0241 12.7417 9.2961 13.0433C8.56815 13.3448 7.78793 13.5 7 13.5C6.21207 13.5 5.43185 13.3448 4.7039 13.0433C3.97595 12.7417 3.31451 12.2998 2.75736 11.7426C2.20021 11.1855 1.75825 10.5241 1.45672 9.7961C1.15519 9.06815 1 8.28793 1 7.5C1 5.9087 1.63214 4.38258 2.75736 3.25736C3.88258 2.13214 5.4087 1.5 7 1.5C8.5913 1.5 10.1174 2.13214 11.2426 3.25736C12.3679 4.38258 13 5.9087 13 7.5ZM7 5H7.00533V5.00533H7V5Z"
      stroke="#4680FF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Completed = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 18.5C10.1819 18.5 11.3522 18.2672 12.4442 17.8149C13.5361 17.3626 14.5282 16.6997 15.364 15.864C16.1997 15.0282 16.8626 14.0361 17.3149 12.9442C17.7672 11.8522 18 10.6819 18 9.5C18 8.3181 17.7672 7.14778 17.3149 6.05585C16.8626 4.96392 16.1997 3.97177 15.364 3.13604C14.5282 2.30031 13.5361 1.63738 12.4442 1.18508C11.3522 0.732792 10.1819 0.5 9 0.5C6.61305 0.5 4.32387 1.44821 2.63604 3.13604C0.948212 4.82387 0 7.11305 0 9.5C0 11.8869 0.948212 14.1761 2.63604 15.864C4.32387 17.5518 6.61305 18.5 9 18.5ZM8.768 13.14L13.768 7.14L12.232 5.86L7.932 11.019L5.707 8.793L4.293 10.207L7.293 13.207L8.067 13.981L8.768 13.14Z"
      fill="#2CA87F"
    />
  </svg>
);
const Close = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM13.36 12.3C13.65 12.59 13.65 13.07 13.36 13.36C13.21 13.51 13.02 13.58 12.83 13.58C12.64 13.58 12.45 13.51 12.3 13.36L10 11.06L7.7 13.36C7.55 13.51 7.36 13.58 7.17 13.58C6.98 13.58 6.79 13.51 6.64 13.36C6.35 13.07 6.35 12.59 6.64 12.3L8.94 10L6.64 7.7C6.35 7.41 6.35 6.93 6.64 6.64C6.93 6.35 7.41 6.35 7.7 6.64L10 8.94L12.3 6.64C12.59 6.35 13.07 6.35 13.36 6.64C13.65 6.93 13.65 7.41 13.36 7.7L11.06 10L13.36 12.3Z"
      fill="#292D32"
    />
  </svg>
);

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1, height: "10px" }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const WelcomeTour = (props) => {
  const { FormatConstants } = props
  const { currentStep, subSteps } = useSelector((state) => state?.onBoarding);
  const [progress, setProgress] = React.useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    setProgress((prevProgress) =>
      prevProgress >= 100 ? 10 : currentStep * 25 - 25
    );
  }, [currentStep]);

  const steps = [
    {
      id: 1,
      title: "Create User",
      description: "Create User and give them Destination",
      caption: "Just Getting Started! ",
      img: "",
      current: currentStep === 1,
      isDone: 1 < currentStep,
    },
    {
      id: 2,
      title: "Fill The Questions",
      description: "To Generate Quality Manual and Procedures",
      caption: "Smooth Progress!",
      img: subicon2,
      current: currentStep === 2,
      isDone: 2 < currentStep,
    },
    {
      id: 3,
      title: "Necessary Information",
      description: "Provides key facts, data for foundation",
      caption: "Look at You Go!",
      img: subicon1,
      current: currentStep === 3,
      isDone: 3 < currentStep,
      subText: [
        { id: 1, name: "Add Product", current: currentStep === 3 && subSteps === 1, isDone: 1 < subSteps, },
        { id: 2, name: "Manufacturing Process", current: currentStep === 3 && subSteps === 2, isDone: 2 < subSteps, },
        { id: 3, name: "Quality Plan", current: currentStep === 3 && subSteps === 3, isDone: 3 < subSteps, },
        { id: 4, name: "List of Equipment", current: currentStep === 3 && subSteps === 4, isDone: 4 < subSteps, },
        { id: 5, name: "List of Machine", current: currentStep === 3 && subSteps === 5, isDone: 5 < subSteps, },
      ],
    },
    {
      id: 4,
      title: "Approved By",
      description: "Select User Who Work On Document",
      caption: "Just Getting Started!",
      img: "",
      current: currentStep === 4,
      isDone: 4 < currentStep,
    },
  ];

  const handleNavigate = (step) => {
    switch (step?.title) {
      case "Create User":
        return step?.current && navigate('/users')
      case "Fill The Questions":
        return step?.current && navigate("/dashboard");
      case "Necessary Information":
        return step?.current && navigate("/product/list");
      case "Approved By":
        return step?.current && navigate('/master-table')
    }
  }

  useEffect(() => {
    dispatch(fetchCurrentStep()); // Calls the API and updates Redux state
  }, [dispatch]);

  const handleSubNavigate = async (item) => {
    let masterDocData, isActiveData;

    switch (item?.name) {
      case "Add Product":
      case "Manufacturing Process":
        if (item?.current) {
          navigate('/product/list');
        }
        break;

      case "Quality Plan":
        masterDocData = await getMasterDocDataById(FormatConstants.MAMOFPRODUCT.INPROCESS_INSPECTION_PLAN);
        isActiveData = masterDocData?.versionData?.find(version => version?.isActive === true);
        if (item?.current && masterDocData?._id && isActiveData?._id) {
          navigate(`/format/format-type-detail/${masterDocData._id}/format/${isActiveData._id}`);
        }
        break;

      case "List of Equipment":
        masterDocData = await getMasterDocDataById(FormatConstants?.MAMOFEQUIPMENT?.MASTERLIST_OF_EQUIPMENT);
        isActiveData = masterDocData?.versionData?.find(version => version?.isActive === true);

        if (item?.current && masterDocData?._id && isActiveData?._id) {
          navigate(`/format/format-type-detail/${masterDocData._id}/format/${isActiveData._id}`);
        }
        break;

      case "List of Machine":
        masterDocData = await getMasterDocDataById(FormatConstants.PREVENTIVE_MAINTAINANCE.MASTER_LIST_OF_MACHINE);
        isActiveData = masterDocData?.versionData?.find(version => version?.isActive === true);

        if (item?.current && masterDocData?._id && isActiveData?._id) {
          navigate(`/format/format-type-detail/${masterDocData._id}/format/${isActiveData._id}`);
        }
        break;

      default:
        console.warn("Unhandled navigation case:", item?.title);
        break;
    }
  };

  return (
    <>
      <Modal
        disableAutoFocus={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ marginBottom: "5rem" }}
      >
        <Box sx={style}>
          <Box
            sx={{
              bgcolor: "rgb(255,255,255)",
              borderRadius: "25px",
            }}
          >
            <Box
              sx={{
                padding: "10px 10px 0px 10px",
              }}
            >
              {steps?.map((step) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {step?.id == currentStep && (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          color="#4680FF"
                          sx={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          {step.caption}
                        </Typography>
                        {(step?.id == 3 || step?.id == 2) && (
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                              marginLeft: "5px",
                            }}
                            src={step.img}
                          />
                        )}
                        <img
                          style={{
                            width: "24px",
                            height: "24px",
                          }}
                          src={rocket1}
                        />
                      </Box>
                      <Box
                        onClick={() => {
                          setOpen(false);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <Close />
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                padding: "10px 0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img style={{ width: "50px", height: "50px" }} src={rocket2} />
              <Box sx={{ width: "100%" }}>
                <LinearProgressWithLabel
                  sx={{
                    height: "10px",
                    backgroundColor: "#E9F0FF",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#4680FF",
                    },
                  }}
                  value={progress}
                />
              </Box>
            </Box>
          </Box>
          <Grid
            container
            xs={12}
            columnGap={2}
            sx={{
              justifyContent: "center",
              flexDirection: "column",
              display: "flex",
            }}
          >
            {steps?.map((step) => (
              <Box
                key={step?.id}
                item
                sx={[
                  step?.id == currentStep
                    ? { backgroundColor: "#B8D7F0" }
                    : { backgroundColor: "#ffff" },

                  step?.id <= currentStep
                    ? { border: "1px solid #B8D7F0" }
                    : { border: "1px solid white" },

                  {
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    flexDirection: "column",
                    alignItems: "baseline",
                    margin: "5px 0px",
                    borderRadius: "10px",
                    width: { lg: "400px", sm: "500px", xs: "350px" },
                  },
                ]}
                onClick={() => handleNavigate(step)}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {step?.id == currentStep ? (
                    <img
                      src={
                        step?.title == "Create User"
                          ? user
                          : step?.title == "Fill The Questions"
                            ? question
                            : step?.title == "Necessary Information"
                              ? info
                              : step?.title === "Approved By"
                                ? approved
                                : "null"
                      }
                    />
                  ) : (
                    <Box
                      sx={[
                        step?.id < currentStep
                          ? {
                            backgroundColor: "#B8D7F0",
                            color: "#004390",
                            border: "1px solid #B8D7F0",
                          }
                          : {
                            backgroundColor: "#ffff",
                            color: "black",
                            border: "1px solid #353535",
                          },
                        {
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          textAlign: "center",
                        },
                      ]}
                    >
                      <Typography>{step?.id}</Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          paddingLeft: "10px",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={[
                            step.id <= currentStep
                              ? { color: "#004390" }
                              : { color: "black" },
                            { fontSize: "12px", fontWeight: "600" },
                          ]}
                        >
                          {step?.title}
                        </Typography>
                        <Typography
                          sx={[
                            step.id <= currentStep
                              ? { color: "#004390" }
                              : { color: "black" },
                            { fontSize: "12px", fontWeight: "400" },
                          ]}
                        >
                          {step?.description}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>{step.id < currentStep ? <Completed /> : null}</Box>
                  </Box>
                </Box>
                <Box sx={{ color: "#004390", width: "100%" }}>
                  <Box
                    sx={
                      currentStep == 3
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <Box sx={{ width: "100%" }}>
                      {step?.id == 3 && (
                        <Divider
                          sx={{
                            margin: "10px 0px",
                            bgcolor: "#A3C0FF",
                            height: "1.5px",
                            width: "100%",
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      {step.id == 3 &&
                        step?.subText?.map((item, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "3px 15px",
                            }}
                            onClick={() => handleSubNavigate(item)}
                          >
                            <Typography variant="caption" key={index}>
                              {item.name}
                            </Typography>
                            {item.id < currentStep ? <Completed /> : null}
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Grid>
        </Box>
      </Modal>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          zIndex: 10,
          right: { lg: "4rem", sm: "2.5rem", xs: "2.5rem" },
        }}
        onClick={handleOpen}
      >
        <Box
          badgeContent={currentStep}
          color="primary"
          sx={[
            open ? { display: "flex" } : { display: "none" },
            {
              alignItems: "center",
              color: "#ffff",
              bgcolor: "#6293FF",
              cursor: "pointer",
              margin: "20px 0px",
              padding: "10px 24px",
              borderRadius: "20px",
              alignItems: "center",
            },
          ]}
        >
          <Typography>Let’s Set Things Up! </Typography>
          <img style={{ width: "20px", height: "20px" }} src={flash} />
        </Box>
        <Box
          color="primary"
          sx={[
            open ? { display: "none" } : { display: "flex" },
            {
              alignItems: "center",
              color: "#4680FF",
              bgcolor: "#E9F0FF",
              cursor: "pointer",
              margin: "20px 0px",
              padding: "8px 20px",
              borderRadius: "20px",
              alignItems: "center",
            },
          ]}
        >
          <Typography>You're Working On</Typography>
          <img
            style={{ width: "24px", height: "24px", marginLeft: "5px" }}
            src={write}
          />
          <Typography
            sx={{
              padding: "5px 10px",
              marginLeft: "10px",
              bgcolor: "white",
              borderRadius: "50px",
            }}
          >
            Step {currentStep}
          </Typography>
        </Box>
      </Box>

    </>
  );
};

export default withRouter(WelcomeTour);
