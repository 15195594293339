import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import { ListItemText } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import withRouter from "components/withRouter";

const Footer = () => {
  return (
    <BottomNavigation
      sx={{
        marginTop: "10px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "16px",
        border: "1px solid #EFEFEF",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        alignItems: "center",
        height: "62px",
        width: "calc(100%)",
      }}
    >
      <p> &copy; RegTech ♥ crafted by Team Maven!</p>
      <List sx={{ backgroundColor: "rgb(185, 188, 226" }} className="none">
        <ListItem
        >
          <ListItemButton component="a" href="#simple-list">
            <FiberManualRecordIcon
              className="fixed"
              sx={{ fontSize: "10px" }}
            />
            <ListItemText primary="Support" />
          </ListItemButton>
          <ListItemButton component="a" href="#simple-list">
            <FiberManualRecordIcon
              className="fixed"
              sx={{ fontSize: "10px" }}
            />
            <ListItemText primary="License" />
          </ListItemButton>
          <ListItemButton component="a" href="#simple-list">
            <FiberManualRecordIcon
              className="fixed"
              sx={{ fontSize: "10px" }}
            />
            <ListItemText primary="Terms of Use" />
          </ListItemButton>
          <ListItemButton component="a" href="#simple-list">
            <FiberManualRecordIcon
              className="fixed"
              sx={{ fontSize: "10px" }}
            />
            <ListItemText primary="Blog" />
          </ListItemButton>
        </ListItem>
      </List>
    </BottomNavigation>
  );
}

export default withRouter(Footer);
