import { useMemo } from "react";
import { Toolbar, Typography, useMediaQuery } from "@mui/material";
import HeaderContent from "./HeaderContent";
import useConfig from "hooks/useConfig";
import withRouter from "components/withRouter";
import { Box } from "@mui/system";
import { useSelector } from "store";
import { HambergerMenu } from "iconsax-react";
import { dispatch } from "store";
import { openDrawer } from "store/reducers/menu";

const Header = (props) => {
  const { theme } = props
  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  const { drawerOpen } = useSelector((state) => state.menu);

  // common header
  const MainHeader = () => (
    <Toolbar
      sx={{
        paddingBottom: "0px !important",
        paddingTop: "16px",
        minHeight: "40px !important",
        position: "relative",
        zIndex: 1200,
      }}
    >
      <Toolbar
        sx={{
          width: "calc(100%)",
          border: "1px solid #FFFFFF",
          minHeight: "60px !important",
          height: "10px",
          borderRadius: "8px",
          bgcolor: "rgba(255,255,255,0.5)",
        }}
      >
        <Box
          sx={{
            width: "80%",
            display : 'flex',
            gap : "20px"
          }}
        >
          
          <Box
            sx={{
              display : { sx : "flex", lg :  'none'},
              alignItems :  'center'
            }}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
          >
            <HambergerMenu />
          </Box>

          <Typography
            sx={{
              textAlign: "start",
              color: "#000",
              fontSize: { xs: 12, sm: 16, lg: "21px" },
              fontWeight: 600,
              color: "#434242",
            }}
          >
            Dashboard
          </Typography>
        </Box>

        {headerContent}
      </Toolbar>
    </Toolbar>
  );

  return (
    <MainHeader />
  );
};

export default withRouter(Header);
