import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import YearList from "pages/standards/molecules/YearList";
// render - pages

const Folder = Loadable(lazy(() => import("../../molecules/Folder")));
const List = Loadable(lazy(() => import("../../molecules/List")));
const Detail = Loadable(lazy(() => import("../../molecules/Detail")));
const AddEditFormat = Loadable(lazy(() => import("../AddEditFormat")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

const FormatStructureRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("format", null, [
      createRoute(
        "format-list",
        <List
          moduleType="procedure"
          limit={30}
          name="Format"
          navigateUrl="format-list"
        />
      ),
      createRoute(
        "format-list/:id",
        <List
          moduleType="format-by-parentId"
          limit={30}
          name="Format"
          navigateUrl="format-type"
        />
      ),
      createRoute(
        "format-type/:documentId",
        <Folder
          moduleType="format"
          name="Format Folders"
          detailUrl="format-type"
          yearUrl="format-detail-year"
        />
      ),
      createRoute(
        "format-detail-year/:documentId/:type",
        <YearList
          moduleType="format"
          name="Format Folders"
          detailUrl="format-type"
          yearUrl="format-detail-year"
          navigateUrl="format-type"
        />
      ),
      createRoute(
        "format-type/:documentId/:type?/:year?",
        <Detail moduleType="format" name="Format" />
      ),
      createRoute(
        "format-type-detail/:mId/:type/:fId",
        <AddEditFormat moduleType="format" name="Format" />
      ),
    ]),
  ]
);

export default FormatStructureRoute;
