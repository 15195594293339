import { routeConstants } from "services/keyConstants";

let baseUrl = `/${routeConstants.CUSTOMER_COMPLAINTS}`;

export const CREATE_CUSTOMER_COMPLAINT = `${baseUrl}`;

export const GET_ALL_COMPLAINT = `${baseUrl}`;

export const UPDATE_SPECIFIC_COMPLAINT = `${baseUrl}/specific/`;

export const GET_ALL_COMPLAINTS_TO_ASSIGNED_USER = `${baseUrl}/list`;

export const UPDATE_SPECIFIC_COMPLAINT_WITH_IMAGE = `${baseUrl}/`;

export const DOWNLOAD_CC = `${baseUrl}/download`;

export const DOWNLOAD_CC_REGISTER = `${baseUrl}/download-register`;

export const GET_BY_YEAR = `${baseUrl}/year-list`;

export const UPDATE_CA_IN_CC = `${baseUrl}/find-capa`;

export const GET_ALL_COMPLAINT_LIST = `${baseUrl}/cc`;

export const GET_PARENT = `/product/get-parent`;

export const GET_ALL_COMPLAINT_BY_ID = `${baseUrl}/`;

export const CWRB_PENDING = `${baseUrl}/cwrb-pending`;

export const GET_COUNT_FOR_DASHBOARD = `${baseUrl}/get-count`;

export const GET_COUNT_FOR_CRITICAL = `${baseUrl}/get-critical-count`;

export const GET_COUNT_FOR_JUSTIFY = `${baseUrl}/get-justify-count`;

export const GET_CUSTOMER_COMPLAINTS = `${baseUrl}/get-complaints`;

export const GET_COUNT_STATUS = `${baseUrl}/get-count-status-vise`;

export const GET_CUSTOMER_RESPONSE = `${baseUrl}/get-customer-responded`;
