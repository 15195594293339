import { routeConstants } from "services/keyConstants";

const BASE_URL = `${routeConstants.INSTALLATION}`
const baseUrlFormat = `${BASE_URL}/`;

export const INSTALLATION_CREATE_FORMATS = `/${BASE_URL}/pro-format`;
export const INSTALLATION_FORMATS_LIST = `/${BASE_URL}/pro-format/list`;
export const INSTALLATION_FORMATS = `/${baseUrlFormat}`;
export const INSTALLATION_LIST = `/${baseUrlFormat}/by-proformatId`;
export const SENT_EMAIL = `/${baseUrlFormat}/email`;
export const ADD_UPDATE_INSTALLATION = `/${baseUrlFormat}/`;
export const BASE_ROUTE = `/${baseUrlFormat}/`;
export const BASE_ROUTE_EXTERNAL = `/${baseUrlFormat}/external/`;
export const GET_REPORT = `/${baseUrlFormat}/get-report`;
export const UPDATE_PROFORMAT = `/${BASE_URL}/pro-format`;
export const INSTALLATION_DOWNLOAD = `/${baseUrlFormat}/download`;
export const EXTERNAL_FORMAT_CREATE = `/${baseUrlFormat}create`;
export const GET_BY_YEAR = `/${BASE_URL}/pro-format/year-list`;
export const GET_DEVICE_LIST = `/${BASE_URL}/get-list`
export const GET_GROUPED_DATA = `/${BASE_URL}/get-group-data`
export const GET_GROUPED_DATA_BY_PRODUCT = `/${BASE_URL}/get-group-data-product`
export const GET_ALL_REPORT = `/${BASE_URL}/get`
export const GET_ALL_REPORT_EXTERNAL = `/${BASE_URL}/get-external`
export const UPDATE_SPECIFIC = `/${BASE_URL}/update-specific`