import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import DrawerHeader from "./DrawerHeader";
import DrawerContent from "./DrawerContent";
import MiniDrawerStyled from "./MiniDrawerStyled";
import { DRAWER_WIDTH } from "config";
import { dispatch, useSelector } from "store";
import { openDrawer } from "store/reducers/menu";
import withRouter from "components/withRouter";

const MainDrawer = (props) => {
  const { theme, window } = props;
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  const { drawerOpen } = useSelector((state) => state.menu);
  const { pinUnpin } = useSelector((state) => state.menu);

  // responsive drawer container
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(
    () => <DrawerHeader open={drawerOpen} />,
    [drawerOpen]
  );
  if (!pinUnpin) {
    return (
      <Box
        component="nav"
        sx={{
          flexShrink: { md: 0 },
          zIndex: 1200,
        }}
        aria-label="mailbox folders"
        onMouseOver={() => dispatch(openDrawer(true))}
        onMouseLeave={() => dispatch(openDrawer(false))}
      >
        {!downLG ? (
          <MiniDrawerStyled variant="permanent" open={drawerOpen}>
            {drawerHeader}
            {drawerContent}
          </MiniDrawerStyled>
        ) : (
          <Drawer
            container={container}
            variant="temporary"
            open={drawerOpen}
            onClose={() => dispatch(openDrawer(!drawerOpen))}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: DRAWER_WIDTH,
                borderRight: `1px solid ${theme.palette.divider}`,
                backgroundImage: "none",
                boxShadow: "inherit",
                overflow: "unset",
              },
            }}
          >
            {drawerHeader}
            {drawerContent}
          </Drawer>
        )}
      </Box>
    );
  }
  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, zIndex: 1200 }}
      aria-label="mailbox folders"
    >
      {!downLG ? (
        <MiniDrawerStyled variant="permanent" open={drawerOpen}>
          {drawerHeader}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={drawerOpen}
          onClose={() => dispatch(openDrawer(!drawerOpen))}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
              borderRight: `1px solid ${theme.palette.divider}`,
              backgroundImage: "none",
              boxShadow: "inherit",
              overflow: "unset",
            },
          }}
        >
          {drawerHeader}
          {drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool,
  window: PropTypes.object,
  handleDrawerToggle: PropTypes.func,
};

export default withRouter(MainDrawer);
