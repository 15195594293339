import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

const Area = Loadable(lazy(()=>import("../Area")));
const CommonList = Loadable(lazy(() => import("../CommonList")));
const DetailList = Loadable(lazy(() => import("../DetailList")));
const PestControl = Loadable(lazy(() => import("../PestControl")));
const EsdControl = Loadable(lazy(() => import("../EsdControl")));
const ApproneAndSlipperCleaningRecord = Loadable(lazy(() => import("../ApproneAndSlipperCleaningRecord")));
const TemperatureAndHumidityMonitoringRecord = Loadable(lazy(() => import("../TemperatureAndHumidityMonitoringRecord")));
const FumigationRecord = Loadable(lazy(() => import("../FumigationRecord")));
const PersonnelHygiene = Loadable(lazy(() => import("../PersonnelHygiene")));
const CleaningRegister = Loadable(lazy(() => import("../CleaningRegister")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });
const WERoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.WORK_ENVIRONMENT, null, [
      createRoute("common-list", <CommonList />),
      createRoute("area", <Area />),
      createRoute("detail/:masterDocId", <DetailList />),
      createRoute("pest-control/:id?", <PestControl />),
      createRoute("esd-control/:id?", <EsdControl />),
      createRoute("fumigation-record/:id?", <FumigationRecord />),
      createRoute("appron-and-slipper-cleaning-record/:id?", <ApproneAndSlipperCleaningRecord />),
      createRoute("temperature-and-humidity-monitoring-record/:id?", <TemperatureAndHumidityMonitoringRecord />),
      createRoute("cleaning-register/:id?", <CleaningRegister />),
      createRoute("personnel-hygiene-record/:id?", <PersonnelHygiene />),
    ]),
  ]
);

export default WERoutes;
