import React from "react";
import { TextField, Chip, Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { validateEmail } from "services/utils";

const filter = createFilterOptions();

const EmailSelector = ({ emails, setEmails, isHiddenLabel = false }) => {
  const handleAddEmail = (event, newValue) => {
    let updatedEmails = [...emails];
    if (typeof newValue === "string") {
      if (validateEmail(newValue)) {
        updatedEmails = [...emails, newValue];
      }
    } else if (newValue && typeof newValue.inputValue === "string") {
      if (validateEmail(newValue.inputValue)) {
        updatedEmails = [...emails, newValue.inputValue];
      }
    } else if (Array.isArray(newValue)) {
      const validEmails = newValue
        .filter((item) => validateEmail(item?.inputValue ?? item))
        .map((item) => item?.inputValue ?? item);

      if (validEmails?.length > 0) {
        updatedEmails = validEmails;
      }
    }

    setEmails(updatedEmails);
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      value={emails}
      onChange={handleAddEmail}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={[]}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={isHiddenLabel ? "" : "Add Emails"}
          placeholder="Enter email"
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
            onDelete={() => {
              setEmails(emails.filter((email) => email !== option));
            }}
          />
        ))
      }
    />
  );
};

export default EmailSelector;
