import MainCard from "components/MainCard";
import { Box, Card, Grid, Pagination, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import Error404 from "components/reusables/404";
import withRouter from "components/withRouter";
import CustomFolderSkeleton from "components/CustomFolderSkeleton";
import { useEffect, useRef, useState } from "react";
import * as url from "pages/DynamicDocument/utils/urlHelper";
import { buildUrl } from "services/utils";

const YearList = (props) => {
  const { ApiServices, name, moduleType, navigateUrl } = props,
    { params, navigate } = props?.router;
  const { documentId, type } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const filterData = useRef({ currentPage: 1, totalPage: 1 });

  const getData = async () => {
    try {
      const baseUrl = url.GET_BY_YEAR + documentId;
      const queryParams = {
        moduleType: moduleType,
        documentState: type,
        page: filterData.current?.currentPage,
      };
      const uri = buildUrl(baseUrl, queryParams);
      const result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        setData(result?.data);
        filterData.current.totalPage = result?.meta?.totalPages;
      } else {
        setData([]);
        filterData.current.currentPage = 1;
        filterData.current.totalPage = 1;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageClick = (e, val) => {
    filterData.current.currentPage = val;
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const handleOpenFolder = (year) => {
    navigate(`../${navigateUrl}/${documentId}/${type}/${year}`);
  };

  return (
    <>
      {isLoading ? (
        <CustomFolderSkeleton length={12} />
      ) : data?.length === 0 ? (
        <Error404 headingMain="Oops, looks like there are no records!" />
      ) : (
        <MainCard title={`${name} Year List`}>
          <Grid container spacing={2}>
            {data?.map((row, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                <Card
                  style={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    onClick={() => handleOpenFolder(row?.year)}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      borderRadius: 2,
                      padding: 2,
                      height: "100%",
                      justifyContent: "center",
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale3d(1.02, 1.02, 1)",
                        transition: "all .10s ease-in-out",
                      },
                    }}
                  >
                    <FolderIcon
                      sx={{
                        fontSize: 50,
                        color: "#5c8ee0",
                        cursor: "pointer",
                      }}
                    />
                    <Typography>{row?.year}</Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" mt={4}>
              <Pagination
                count={filterData.current.totalPage}
                page={filterData.current.currentPage}
                onChange={handlePageClick}
                color="primary"
                hidePrevButton
                hideNextButton
              />
            </Box>
          </Grid>
        </MainCard>
      )}
    </>
  );
};

export default withRouter(YearList);
