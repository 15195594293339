import React, { useEffect, useState } from "react";
import { Button, Grid, Box } from "@mui/material";
import AuthWrapper from "pages/auth/template/AuthWrapper";
import * as url from "./utils/urlHelper";
import withRouter from "components/withRouter";
import {
  showToast,
  validateColumns,
} from "services/utils";
import SkeletonTable from "components/SkeletonTable";
import DynamicTableForm from "components/reusables/DynamicTableForm";
import successImg from "assets/images/verify.png";

const ServicingForm = (props) => {
  const { params, navigate } = props?.router,
    { ApiServices } = props;
  const { id, } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({})
  const [feedbackForm, setFeedbackForm] = useState([])
  const [data, setData] = useState({})
  const handleSubmit = async () => {
    try {
      const validationCustomerDetailsError = validateColumns(
        feedbackForm
      );
      if (Object.keys(validationCustomerDetailsError)?.length > 0) {
        setErrors(validationCustomerDetailsError);
        return
      }

      let reqObj = {
        id: id,
        updateData: {
          [`document.columns`]: feedbackForm,
          [`document.submmited`]: true
        }
      };

      let uri = url.UPDATE_SPECIFIC_DATA;

      const result = await ApiServices.callServicePutWithBodyData(uri, reqObj);
      if (result) {
        showToast(result);
        getData()
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true)
      const uri = url.POST_CUSTOMER_SERVICING + id
      const result = await ApiServices.callServiceGet(uri)
      if (result?.meta?.success) {
        setFeedbackForm(result?.data?.document?.columns ?? [])
        setData(result?.data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, []);

  return (
    <AuthWrapper isForm={true}>
      <>
        <Box mb={2}>
          {isLoading ? (
            <SkeletonTable columnCount={2} />
          ) : (
            feedbackForm && !data?.document?.submmited ? (
              <DynamicTableForm
                columns={feedbackForm}
                setColumns={setFeedbackForm}
                setErrors={setErrors}
                errors={errors}
                isStatic={true}
              />
            ) : <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <img height={"100px"} width={"100px"} src={successImg} alt="done" />
              <span style={{ textAlign: "center" }}>
                Data Submitted Successfully
              </span>
            </div>
          )}
        </Box>

      </>
      {!data?.document?.submmited ? <Grid item xs={12} justifyContent="flex-end" display="flex" gap={2}>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </Grid> : null}
    </AuthWrapper>
  );
};
export default withRouter(ServicingForm);
