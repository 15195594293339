import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import { lazy } from "react";
import { routeConstants } from "services/keyConstants";

const AddEdit = Loadable(lazy(() => import("../molecules/AddEdit")));
const History = Loadable(lazy(() => import("../molecules/History")));
const View = Loadable(lazy(() => import("../molecules/View")));

const createRoute = (path, element, children) => ({ path, element, children });

const ChangeNoteRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.CHANGE_NOTE, null, [
      createRoute("manage/:id?", <AddEdit />),
      createRoute("change-note-history/:type?", <History />),
      createRoute("view/:id?", <View />),
    ]),
  ]
);

export default ChangeNoteRoute;
