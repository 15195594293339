import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import TabsContainer from "components/TabsContainer";
import { Box } from "@mui/material";
import List from "./List";
import {
  AddEditFormats,
  AddEditManuals,
  AddEditProcedure,
} from "./Schema/Validation";
import AddEditDocument from "./molecules/AddEditDocument";
import AddEditFormat from "./molecules/AddEditFormat";
import { getPermssionDataByName } from "services/utils";
import withRouter from "components/withRouter";

const Main = (props) => {
  const { moduleTypeConstants, AdminAccessConstants } = props
  const tabLabels = ["Quality Manuals", "Procedures", "Formats"];
  const [permissionData, setPermissionData] = useState([]);

  const tabContents = [
    () => (
      <List
        type="Manual"
        moduleType="qualityManual"
        Component={AddEditDocument}
        validationSchema={AddEditManuals}
        initialValues={{
          name: "",
          moduleType: "qualityManual",
          type: "Parent-child",
        }}
        formFields={[
          {
            name: "name",
            label: "Name",
            type: "textField",
            initialValue: "",
          },
          {
            name: "type",
            label: "Type",
            type: "select",
            initialValue: "Parent-child",
            options: [
              { label: "Parent-child", value: "Parent-child" },
              { label: "Custom", value: "Custom" },
            ],
            hidden: (values) => {
              return values === "Default";
            },
          },
        ]}
        tableColumns={[
          {
            header: "Manual Name",
            field: "name",
            accessor: (item) =>
              `${item?.name ?? "-"} (${item?.defaultName ?? "-"})`,
          },
        ]}
        permissionData={permissionData}
      />
    ),
    () => (
      <List
        type="Procedure"
        moduleType="procedure"
        Component={AddEditDocument}
        validationSchema={AddEditProcedure}
        initialValues={{
          name: "",
          moduleType: "procedure",
          isMultiPart: false,
          parts: [],
        }}
        formFields={[
          {
            name: "name",
            label: "Name",
            type: "textField",
            initialValue: "",
          },
          {
            name: "isMultiPart",
            label: "Do you want to divide formats into multiple parts?",
            type: "select",
            initialValue: "false",
            options: [
              { label: "Yes", value: "true" },
              { label: "No", value: "false" },
            ],
            extraOnChange: (event, values, setFieldValue) => {
              const value = event.target.value;
              setFieldValue("isMultiPart", value);

              // If isMultiPart is false, clear parts
              if (value === "false") {
                setFieldValue("parts", []);
              } else {
                // If isMultiPart is true, initialize parts with one empty object
                setFieldValue("parts", [{ name: "" }]);
              }
            },
          },
          {
            name: "parts",
            label: "Part Names",
            type: "array",
            hiddenField: "isMultiPart",
            hidden: (values) => {
              return values === false;
            },
            fields: [
              {
                name: "name",
                label: "Part Name",
                type: "textField",
              },
            ],
          },
        ]}
        tableColumns={[
          {
            header: "Procedure Name",
            field: "name",
            accessor: (item) =>
              `${item?.name ?? "-"} (${item?.defaultName ?? "-"})`,
          },
          {
            header: "Is Multiple Part",
            field: "isMultiPart",
            accessor: (item) =>
              `${item?.isMultiPart === "true" ? "Yes" : "No" ?? "-"}`,
          },
          {
            header: "Parts",
            field: "parts",
            accessor: (item) =>
              item?.parts && item?.parts?.length > 0
                ? item?.parts
                  ?.map((part, index) => `${index + 1}. ${part?.name}`)
                  ?.join("<br/>")
                : "-",
          },
        ]}
        permissionData={permissionData}
      />
    ),
    () => (
      <List
        type="Formats"
        moduleType="format"
        Component={AddEditFormat}
        validationSchema={AddEditFormats}
        initialValues={{
          procedure: null,
          part: null,
          documents: [{ name: "", viewType: "List" }],
        }}
        tableColumns={[
          {
            header: "Procedure Name",
            accessor: (item) =>
              `${item?.procedureName ?? "-"} (${item?.procedureDefaultName ?? "-"
              })`,
          },
          {
            header: "Formats Data",
            accessor: (item) => {
              // Check if parts array has items, partName exists, and result array has items
              if (item?.documents?.length > 0) {
                // Map document names with index, joining them by "<br/>"
                return (
                  item?.documents
                    ?.map((doc, index) => `${index + 1}. ${doc?.name}`)
                    .join("<br/>") || "-"
                );
              } else if (item?.parts?.length > 0) {
                return (
                  item?.parts
                    ?.map((doc, partIndex) => {
                      let partOutput = `${partIndex + 1}. ${doc?.partName}`;

                      if (doc?.result?.length > 0) {
                        const resultNames = doc?.result
                          ?.map(
                            (result, resultIndex) =>
                              `${partIndex + 1}.${resultIndex + 1} ${result?.name
                              }`
                          )
                          ?.join("<br/>");

                        partOutput += `<br/>${resultNames}`;
                      }

                      return partOutput;
                    })
                    .join("<br/>") || "-"
                );
              }
              return "-";
            },
          },
        ]}
        permissionData={permissionData}
      />
    ),
  ];

  const getPermissionData = async () => {
    let permissionResult = await getPermssionDataByName(
      undefined,
      moduleTypeConstants.ADMIN,
      {
        slug: AdminAccessConstants?.DYNAMIC_DOCUMENT
      }
    );
    setPermissionData(permissionResult ?? []);
  };

  useEffect(() => {
    getPermissionData()
  }, [])

  return (
    <MainCard title={"Document list"}>
      <Box sx={{ width: "100%" }}>
        <TabsContainer
          tabLabels={tabLabels}
          tabContents={tabContents}
          variant="fullWidth"
        />
      </Box>
    </MainCard>
  );
};

export default withRouter(Main);
