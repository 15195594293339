import { useEffect, useRef, useState } from "react";
import { Box, Button, Skeleton, Grid } from "@mui/material";
import MainCard from "components/MainCard";
import * as url from "./utils/urlHelper";
import {
  downloadFormat,
  getFormatDataById,
  getTitle,
  showToast,
  validationDynamicTable,
} from "services/utils";
import { CustomHooks } from "hooks/CustomHooks";
import withRouter from "components/withRouter";
import SkeletonTable from "components/SkeletonTable";
import DynamicTable from "components/reusables/DynamicTable";
import { getDefaultDataForDynamicTable } from "./utils/service";
import DynamicFormatStructure from "components/reusables/DynamicFormatStructure";
import { FormatConstants } from "services/keyConstants";

const MasterListOfDoc = (props) => {
  const { userData, ApiServices, onChangeDownloadState } = props;
  const { documentId } = CustomHooks.useUrlParams();
  // const TempData = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState({});

  const handleFrequencyChange = ({ e, rowIndex, colValue, rows, value }) => {
    // Extract frequency value from the event
    const frequencyValue = e.target.value;

    // Get the effective date from rowsData
    const effectiveDateStr = rows?.[rowIndex]?.effectiveDate;

    // If effective date or frequency value is missing, return
    if (!effectiveDateStr || !frequencyValue) {
      console.error("Effective Date or Frequency Value is missing!");
      return;
    }

    try {
      // Parse the effective date (DD/MM/YYYY)
      const [day, month, year] = effectiveDateStr
        .split("/")
        .map((val) => parseInt(val, 10));
      let nextDate = new Date(year, month - 1, day); // Date is zero-indexed for months in JS

      // Add frequency to the date
      if (frequencyValue === "6 months") {
        nextDate.setMonth(nextDate.getMonth() + 6);
      } else if (frequencyValue.includes("year")) {
        const yearsToAdd = parseInt(frequencyValue.split(" ")[0], 10);
        nextDate.setFullYear(nextDate.getFullYear() + yearsToAdd);
      }

      // Format the new date as DD-MM-YYYY
      const formattedNextDate = `${String(nextDate.getDate()).padStart(
        2,
        "0"
      )}-${String(nextDate.getMonth() + 1).padStart(
        2,
        "0"
      )}-${nextDate.getFullYear()}`;

      // Update the rows data with the new frequency and next review date
      const updatedRows = [...rows]; // Ensure you're copying the rowsData to maintain immutability
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex], // Spread the existing row data
        frequencyReview: frequencyValue, // Update frequency
        nextReviewDate: formattedNextDate, // Update next review date
      };

      // Call the function to update the state with the modified rows
      setRows(updatedRows); // Trigger re-render
    } catch (error) {
      console.error("Error processing frequency change:", error);
    }
  };

  const [columns, setColumns] = useState([]);

  const [errors, setErrors] = useState({});

  const handleFormatDataSave = async () => {
    try {
      const validationErrors = validationDynamicTable(rows, columns);
      if (Object.keys(validationErrors)?.length > 0) {
        setErrors(validationErrors);
        return;
      }
      const reqObj = {
        document: {
          rows: rows,
          columns: columns,
        },
      };

      let uri = url.UPDATE_MASTER_LIST + documentId;
      let result = await ApiServices.callServicePutWithBodyData(uri, reqObj);
      showToast(result);
      if (result?.meta?.success) {
        getDocument();
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    }
  };

  const handleDownload = async () => {
    let reqObj = {
      formatId: documentId,
    };
    let uri = url.DOWNLOAD_COD;
    await downloadFormat(uri, reqObj, onChangeDownloadState);
  };

  const getDocument = async () => {
    try {
      let reqObj = {
        childformatId: documentId,
        documentName: "Master List Of Document",
      };
      let uri = url.GET_MASTER_LIST;
      let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
      if (result?.meta?.success) {
        // const fetchedDocument = result?.data.document?.rows || [];

        // const tableRows = fetchedDocument.map((item, index) => ({
        //   srNo: index + 1,
        //   documentNumber: item?.documentNumber || "",
        //   effectiveDate: item?.effectiveDate || "",
        //   revisionNo: item?.revisionNumber || "",
        //   documentName: item?.documentName || "",
        //   frequencyReview: item?.frequencyReview || "",
        //   nextReviewDate: item?.nextReviewDate || "",
        //   reviewDate: item?.reviewDate || "",
        // }));
        setRows(result?.data.document?.rows);
        if (
          result?.data.document?.columns &&
          result?.data.document?.columns.length > 0
        ) {
          setColumns(result?.data.document?.columns);
        }
        // TempData.current = tableRows;
      }
    } catch (error) {
      console.error("GET_CHILD_FORMATS_DATA_BY_FORMATNUMBER ERROR", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDocument();
  }, []);

  const getFormatData = async () => {
    let result = await getFormatDataById(
      FormatConstants.COD.MASTERLIST_OF_DOCUMENTS
    );

    setData(result);

    let tempData = getDefaultDataForDynamicTable(
      result?.structuredData[0].columns,
      {},
      {
        handleFrequencyChange,
      }
    );

    setColumns(tempData);
    setIsLoading(false);
  };
  useEffect(() => {
    getFormatData();
  }, []);

  return (
    <MainCard
      title={getTitle("Master List of Docs")}
      secondary={
        isLoading ? (
          <Skeleton
            variant="rectangular"
            height="30px"
            width="100px"
            sx={{
              borderRadius: "12px",
            }}
          />
        ) : (
          <Button variant="contained" color="success" onClick={handleDownload}>
            Download
          </Button>
        )
      }
    >
      <Grid container spacing={2}>
        {isLoading ? (
          <Grid item xs={12}>
            <SkeletonTable />
          </Grid>
        ) : (
          <>
            {data ? (
              <DynamicFormatStructure
                data={data?.structuredData}
                columns={columns}
                rows={rows}
                setRows={setRows}
                setColumns={setColumns}
                setErrors={setErrors}
                errors={errors}
                options={{
                  isNumberDynamic: true,
                  isStrictData: true,
                  isStatic: true,
                }}
              />
            ) : null}

            {/* <DynamicTable
              columns={columns}
              setColumns={setColumns}
              rows={rows}
              setRows={setRows}
              setErrors={setErrors}
              errors={errors}
              isStrictData={true}
              isNumberDynamic={true}
            /> */}
            {!userData?.externalUser && (
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={handleFormatDataSave}
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </MainCard>
  );
};

export default withRouter(MasterListOfDoc);
