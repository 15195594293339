import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, FormatConstants, permissionConstants, routeConstants } from "services/keyConstants";
import CommonYearList from "components/reusables/CommonYearList";
import * as url from "./urlHelpers"

// Define lazy-loaded components

// Order Related
const CreateOrder = Loadable(lazy(() => import("../CreateOrder")));
const DynamicList = Loadable(lazy(() => import("../DynamicList")));
const View = Loadable(lazy(() => import("../View")));
const OrderForm = Loadable(lazy(() => import("../OrderForm")));
const ProductDecision = Loadable(lazy(() => import("../ProductDecision")));
const DistributionRecord = Loadable(
  lazy(() => import("../DistributionRecord"))
);
const OrderReviewFormById = Loadable(
  lazy(() => import("../OrderReviewFormById"))
);

// Distributor Related
const AddDistributor = Loadable(lazy(() => import("../AddDistributor")));
const DistributorById = Loadable(lazy(() => import("../DistributorById")));
const DistributorAgreement = Loadable(lazy(() => import("../DistributorAgreement")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const CRPDRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(
      routeConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION,
      null,
      [
        createRoute("create-order", <CreateOrder />),
        createRoute("create-distributor", <AddDistributor />),
        createRoute("order-form/:id", <OrderForm />),
        createRoute("order-review/:id", <OrderReviewFormById />),
        createRoute("distributor/:id", <DistributorById />),
        createRoute("distributor-agreement/:id", <DistributorAgreement />), ,
        createRoute("product-decision/:id", <ProductDecision />),
        createRoute("distribution-record/:id", <DistributionRecord />),
        createRoute("list/:year", <DynamicList />),
        createRoute("view/:year/:id", <View />),
        createRoute(commonRoutesConstants.YEAR_LIST, <CommonYearList
          uri={url.GET_YEAR_LIST}
          title={routeConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION}
          isExtraButtons={true}
          permissionFormatConstants={[FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.ORDER_FORM, FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.DISTRIBUTOR_FORM]}
          extraButtonChildren={[
            {
              buttonTitle: "Create Order",
              path: `/${routeConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION}/create-order`,
              formatConstants: FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.ORDER_FORM,
              permissionConstants: permissionConstants.CREATE,
              disableOnPermission: true,
            },
            {
              buttonTitle: "Create New Distributor",
              path: `/${routeConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION}/create-distributor`,
              formatConstants: FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.DISTRIBUTOR_FORM,
              permissionConstants: permissionConstants.CREATE,
              disableOnPermission: true,
            },
          ]}
          navigateToFolder={`/${routeConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION}/list/`}
        />),
      ]
    ),
  ]
);

export default CRPDRoutes;
