const { FormatConstants } = require("services/keyConstants");
const { getDocumentLifeCycleForMasterDoc } = require("services/utils");
import { ApiServices } from "services/apiServices";
import { Constants, MasterDocumentTypeConstants } from "services/keyConstants";
import { getItem, showToast } from "services/utils";
import * as url from "./urlHelper";
const userData = getItem(Constants.KEY_USER_DATA);

export const handleCreateCORFormat = async () => {
  try {
    const masterDocData = await getDocumentLifeCycleForMasterDoc(FormatConstants.COR.MASTERLIST_OF_QUALITY_RECORDS, MasterDocumentTypeConstants.FORMAT);
    let reqObj = {
      documentName: "Control Of Record",
      masterDocId: masterDocData?._id,
      orgId: userData?.orgId,
      year: new Date().getFullYear(),
    };
    let uri = url.POST_FORMAT;
    let result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    showToast(result);
    return result
  } catch (error) {
    console.error("POST_FORMAT ERROR", error);
  }
};