let isFriendUrl = false,
  // localUrl = isFriendUrl ? "192.168.1.94" : "localhost";
  localUrl = isFriendUrl ? "192.168.1.16" : "localhost";

const localURL = `http://${localUrl}:8001`,
  mavenProfServURL = "https://msk.mavenprofserv.us",
  mavenRegTechURL = "https://msk.mavenregtech.com";

const createURLs = (baseURL, proxyURL) => ({
  API_URL: `${baseURL}/api`,
  IMG_URL: `${baseURL}/`,
  PROXY_URL: proxyURL,
});

const localURLs = createURLs(localURL, `http://${localUrl}:8002/`);
const mavenProfServURLs = createURLs(
  mavenProfServURL,
  "https://proxy.mavenprofserv.us/api2/proxy/"
);
const mavenRegTechURLs = createURLs(
  mavenRegTechURL,
  "https://proxy.mavenregtech.com/api2/proxy/"
);

const service =
  window.location.href.startsWith("http://localhost") ||
  window.location.hostname.endsWith(".me.mavenregulatory.com")
    ? localURLs
    : window.location.hostname.endsWith(".mavenprofserv.us")
    ? mavenProfServURLs
    : mavenRegTechURLs;

export default service;
