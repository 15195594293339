import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";
import Loader from "components/Loader";
import * as url from "services/url_helper";
import { ApiServices } from "services/apiServices";
import { dispatchFunction, getItem, getMasterDocDataById, setItem } from "services/utils";
import { Constants, FormatConstants } from "services/keyConstants";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  useEffect(() => {
    const init = async () => {
      try {
        let token = getItem(Constants.KEY_USER_TOKEN);
        let userData = getItem(Constants.KEY_USER_DATA);
        if (isEmpty(token) && isEmpty(userData)) {
          dispatch({
            type: LOGOUT,
          });
        } else {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: userData,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (password, clientUserId) => {
    const isEmail = (str) =>
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/.test(str);
    let companyData = getItem(Constants.KEY_COMPANY_DATA);

    let reqObj = {
      orgId: companyData?._id,
      password,
      ...(isEmail(clientUserId) ? { email: clientUserId } : { clientUserId }),
    };
    const uri = url.SIGN_IN;

    return ApiServices.callServicePostWithBodyData(uri, reqObj).then(
      async (response) => {
        if (response?.result?.type === "success") {
          let userData = {
            ...response?.result?.data,
            departmentData: response?.result?.departmentData,
          };
          const departmentData = response?.result?.departmentData?.[0];
          const memberData = departmentData?.members?.[0];

          const updatedUserRole = {
            ...(departmentData?.departmentId && {
              departmentId: departmentData?.departmentId,
            }),
            ...(departmentData?.departmentName && {
              departmentName: departmentData?.departmentName,
            }),
            ...(departmentData?.departmentDefaultName && {
              departmentDefaultName: departmentData?.departmentDefaultName,
            }),
            ...(memberData?.id && { roleId: memberData?.id }),
            ...(memberData?.defaultDesignation && {
              defaultRoleName: memberData?.defaultDesignation,
            }),
            ...(memberData?.designation && {
              roleName: memberData?.designation,
            }),
          };
          setItem(Constants.KEY_SELECTED_ROLE, updatedUserRole);
          setItem(Constants.KEY_USER_TOKEN, response?.result?.token);
          setItem(Constants.KEY_USER_DATA, userData);
          setItem(Constants.PERMISSIONDATA, response?.result?.permissionData);

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: response?.result?.data,
            },
          });
          if (response?.result?.data?.isFirstTime) {
            if (response?.result?.data?.tourSteps?.onBoardingStage === 1) {
              window.location.replace("/welcome");
            } else if (response?.result?.data?.tourSteps?.onBoardingStage === 3 && response?.result?.data?.tourSteps?.subSteps === 1) {
              window.location.replace('/product/list')
            } else if (response?.result?.data?.tourSteps?.onBoardingStage === 3 && response?.result?.data?.tourSteps?.subSteps === 2) {
              // navigate('/product/list')
            } else if (response?.result?.data?.tourSteps?.onBoardingStage === 3 && response?.result?.data?.tourSteps?.subSteps === 3) {
              let masterDocData = await getMasterDocDataById(FormatConstants.MAMOFPRODUCT.INPROCESS_INSPECTION_PLAN);
              let isActiveData = masterDocData?.versionData?.find(version => version?.isActive === true);
              window.location.replace(`/format/format-type-detail/${masterDocData._id}/format/${isActiveData._id}`);
            } else if (response?.result?.data?.tourSteps?.onBoardingStage === 3 && response?.result?.data?.tourSteps?.subSteps === 4) {
              let masterDocData = await getMasterDocDataById(FormatConstants?.MAMOFEQUIPMENT?.MASTERLIST_OF_EQUIPMENT);
              let isActiveData = masterDocData?.versionData?.find(version => version?.isActive === true);
              window.location.replace(`/format/format-type-detail/${masterDocData._id}/format/${isActiveData._id}`);
            } else if (response?.result?.data?.tourSteps?.onBoardingStage === 3 && response?.result?.data?.tourSteps?.subSteps === 5) {
              let masterDocData = await getMasterDocDataById(FormatConstants.PREVENTIVE_MAINTAINANCE.MASTER_LIST_OF_MACHINE);
              let isActiveData = masterDocData?.versionData?.find(version => version?.isActive === true);
              window.location.replace(`/format/format-type-detail/${masterDocData._id}/format/${isActiveData._id}`);
            } else if (response?.result?.data?.tourSteps?.onBoardingStage === 4) {
              window.location.replace(`/master-table`);
            }

          } else {
            window.location.replace("/dashboard");
          }
        } else {
          return response?.result;
        }
      }
    );
  };

  const resetPassword = async (reqObj) => {
    const uri = url.SAVE_PASSWORD;

    try {
      const response = await ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );
      dispatchFunction(response);
      if (response?.result?.type === "success") {
        let userData = {
          ...response?.result?.data,
          departmentData: response?.result?.departmentData,
        };
        const departmentData = response?.result?.departmentData?.[0];
        const memberData = departmentData?.members?.[0];

        const updatedUserRole = {
          ...(departmentData?.departmentId && {
            departmentId: departmentData?.departmentId,
          }),
          ...(departmentData?.departmentName && {
            departmentName: departmentData?.departmentName,
          }),
          ...(departmentData?.departmentDefaultName && {
            departmentDefaultName: departmentData?.departmentDefaultName,
          }),
          ...(memberData?.id && { roleId: memberData?.id }),
          ...(memberData?.defaultDesignation && {
            defaultRoleName: memberData?.defaultDesignation,
          }),
          ...(memberData?.designation && {
            roleName: memberData?.designation,
          }),
        };
        setItem(Constants.KEY_SELECTED_ROLE, updatedUserRole);
        setItem(Constants.KEY_USER_TOKEN, response?.result?.token);
        setItem(Constants.KEY_USER_DATA, userData);
        setItem(Constants.PERMISSIONDATA, response?.result?.permissionData);
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: response?.result?.data,
          },
        });
        if (response?.result?.data?.isFirstTime) {
          window.location.replace("/welcome");
        }
        else {
          window.location.replace("/dashboard");
        }
      } else {
        return response?.result;
      }
    } catch (error) {
      console.error("Error in resetPassword:", error);
    }
  };

  const updateRole = async (userData, selectedRole) => {
    setItem(Constants.KEY_SELECTED_ROLE, selectedRole);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: userData,
      },
    });
  };

  const logout = () => {
    const companyData = getItem(Constants.KEY_COMPANY_DATA);
    const hostname = getItem(Constants.KEY_HOST_NAME);
    localStorage.clear();
    if (companyData) {
      setItem(Constants.KEY_COMPANY_DATA, companyData);
    }
    if (hostname) {
      setItem(Constants.KEY_HOST_NAME, hostname);
    }
    dispatch({ type: LOGOUT });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        resetPassword,
        logout,
        resetPassword,
        updateRole,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
