import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// render - pages
const List = Loadable(lazy(() => import("pages/standards/molecules/List")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

const RecordsRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute("records", null, [
      createRoute(
        "view",
        <List
          moduleType="procedure"
          limit={30}
          name="Format"
          navigateUrl="format-detail"
          isFormat={true}
        />
      ),
    ]),
  ]
);

export default RecordsRoute;
