import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useMediaQuery, Box } from "@mui/material";
import Drawer from "./Drawer";
import Header from "./Header";
import Footer from "./Footer";
import HorizontalBar from "./Drawer/HorizontalBar";
import useConfig from "hooks/useConfig";
import { dispatch } from "store";
import { openDrawer } from "store/reducers/menu";
import { MenuOrientation } from "config";
import withRouter from "components/withRouter";
import back1 from "../../assets/images/back1.jpeg";
import { useSelector } from "store";
import ArrowAnimate from "assets/gif/ArrowAnimate.gif";
import { ApiServices } from "services/apiServices";
import { changeCurrentStep } from "store/reducers/onBording";
import WelcomeTour from "components/WelcomeTour";

const MainLayout = (props) => {
  const { theme, userData } = props,
    { DRAWER_WIDTH } = props?.config;
  const downXL = useMediaQuery(theme.breakpoints.down("xl"));
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  const { miniDrawer, menuOrientation } = useConfig();
  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  // set media wise responsive drawer
  useEffect(() => {
    if (miniDrawer) {
      dispatch(openDrawer(!downXL));
    }
  }, [downXL]);

  // useEffect(() => {
  //   ApiServices.callServiceGet("/client/users/get-update-tour")
  //   .then((res) => {
  //     try {
  //       const { onBoardingStage } = res.data
  //       dispatch(changeCurrentStep({currentStep : onBoardingStage}))
  //     } catch (error) {
  //       console.log("Tour Step not finding...");
  //     }
  //   })
  // }, [])

  const { currentStep } = useSelector((state) => state.onBoarding);

  return (
    <Box
      sx={{
        position: "relative", // To ensure the layout is inside this wrapper
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* Fixed Background */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backgroundImage: `url(${back1})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          zIndex: -1,
        }}
      />

      {/* Main Layout Container (scrollable) */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        {!isHorizontal ? <Drawer /> : <HorizontalBar />}
        <Box
          component="main"
          sx={{
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
            flexGrow: 1,
            p: { xs: 0, md: 3 },
          }}
        >
          <Box
            sx={{
              bgcolor: "rgba(255, 255, 255, 0.3)",
              borderRadius: "10px",
              minHeight: "86.8vh",
            }}
          >
            {userData?.isFirstTime && currentStep < 5 && <WelcomeTour />}
            <Header />
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(MainLayout);
