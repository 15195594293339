import PropTypes from "prop-types";
import { Box } from "@mui/material";
import MainCard from "components/MainCard";

const AuthCard = ({ isForm, children, ...other }) => (
  <MainCard
    sx={{
      ...(isForm
        ? {}
        : {
            maxWidth: { xs: 400, md: 480 },
          }),
      margin: { xs: 2.5, md: 3 },
      "& > *": {
        flexGrow: 1,
        flexBasis: "50%",
      },
    }}
    content={false}
    {...other}
  >
    <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>{children}</Box>
  </MainCard>
);

AuthCard.propTypes = {
  children: PropTypes.node,
};

export default AuthCard;
