import { commonRoutesConstants, routeConstants } from "services/keyConstants";

const baseUrl = `/${routeConstants.MAM_OF_PRODUCT}`;
const baseUrlEquipment = `/${routeConstants.MAM_OF_EQUIPMENT}`;

export const POST_MONITORING_MEASUREMENT_PRODUCT = `${baseUrl}/`;

export const GET_BY_ID = `${baseUrl}/`;
export const GET_MONITORING_MEASUREMENT_PRODUCT = `${baseUrl}/get-all`;

export const DOWNLOAD_INPROCESS_INSPECTION = `${baseUrl}/inprocess-download`;

export const DOWNLOAD_FINAL = `${baseUrl}/inprocess-download`;

export const DOWNLOAD_PLANS = `${baseUrl}/download`;

export const DOWNLOAD_INDEPENDENT = `${baseUrl}/independent-download`;

export const GET_ALL_DATA_BY_TYPE = `${baseUrl}/get-dynamic`;

export const UPDATE_MONITORING_MEASUREMENT_PRODUCT = `${baseUrl}/update-specific`;

export const GET_DYNAMIC_MONITORING_MEASUREMENT_PRODUCT = `${baseUrl}/get-dynamic`;

export const GET_PARENT = `/product/get-parent`;

export const GET_EQUIPMENT_OR_INSTRUMENT_DATA = `${baseUrlEquipment}/get-by-filter`;

export const GET_PARAMETERS = `${baseUrl}/get-parameter`;

export const GET_BY_YEAR = `${baseUrl}/${commonRoutesConstants.YEAR_LIST}`;

export const CREATE_INDEPENDENT_IN_PROCESS = `${baseUrl}/create-independent-inprocess`;

export const UPDATE_INDEPENDENT_INPROCESS = `${baseUrl}/update-independent`;

export const UPDATE_SPECIFIC_FINAL = `${baseUrl}/update-final/`;

export const GET_BY_MASTERDOCID = `${baseUrl}/get-by-masterdocid`;

export const CREATE_INSPECION = `${baseUrl}/create-inspection/`;

export const GET_INSPECTION_DATA_BY_ID = `${baseUrl}/get-by-id-inspection/`;

export const UPDATE_SPECIFIC_BY_ID = `${baseUrl}/update-specific-inspection/`;

export const UPDATE_BMR = `${baseUrl}/update-bmr/`;

export const CREATE_FINAL_INSPECTION = `${baseUrl}/create-final-inspection/`;

export const GET_BY_MASTERDOCID_BY_EQUIPMENT = `${baseUrlEquipment}/get-by-masterdocid`;

export const GET_MATERIAL_LIST_FROM_PURCHASE = `${baseUrl}/get-materialList`;

export const GET_DATA_BY_STATUS = `${baseUrl}/get-by-status`;

export const GET_DATA_BY_FILTER = `${baseUrl}/get-by-version`;

export const GET_ALL_COUNTS = `${baseUrl}/get-all-counts`;

export const GET_COUNTS = `${baseUrl}/get-count-inspection-product`;

export const GET_TABLE_DATA = `${baseUrl}/get-table-data`;
