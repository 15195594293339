import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import * as url from "./urlHelper";
import CommonYearList from "components/reusables/CommonYearList";

const AddCustomerComplaint = Loadable(
  lazy(() => import("../AddCustomerComplaint"))
);
const ComplaintTables = Loadable(lazy(() => import("../ComplaintTables")));
const ComplaintRegister = Loadable(lazy(() => import("../ComplaintRegister")));
const ComplaintCorrectiveAction = Loadable(
  lazy(() => import("../ComplaintCorrectiveAction"))
);
const AssignCustomerComplaint = Loadable(
  lazy(() => import("../AssignCustomerComplaint"))
);
const CustomerView = Loadable(lazy(() => import("../CustomerView")));

const ComplaintListTable = Loadable(
  lazy(() => import("../ComplaintListTable"))
);

const List = Loadable(lazy(() => import("../List")));

const createRoute = (path, element, children) => ({ path, element, children });

const CCRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.CUSTOMER_COMPLAINTS}`, null, [
      createRoute("list-cc", <ComplaintListTable />),
      createRoute("add-complaint", <AddCustomerComplaint />),
      createRoute("assign-customer-complaint", <AssignCustomerComplaint />),
      createRoute("customerComplaint/:year", <CustomerView />),
      createRoute("list/:year", <List />),
      createRoute("complaint-table/:year", <ComplaintTables />),
      createRoute("complaint-register/:year", <ComplaintRegister />),
      createRoute("complaint-capa/:year", <ComplaintCorrectiveAction />),
      createRoute(
        commonRoutesConstants.YEAR_LIST,
        <CommonYearList
          uri={url.GET_BY_YEAR}
          isCreate={true}
          createRoute={{
            path: `/${routeConstants.CUSTOMER_COMPLAINTS}/add-complaint`,
          }}
          title={routeConstants.CUSTOMER_COMPLAINTS}
          navigateToFolder={`/${routeConstants.CUSTOMER_COMPLAINTS}/list/`}
        />
      ),
    ]),
  ]
);

export default CCRoutes;
