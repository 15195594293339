import { routeConstants, commonRoutesConstants } from "services/keyConstants";

const baseUrl = `/${routeConstants.FEEDBACK}`;

export const ADD_FEEDBACK_DATA = `${baseUrl}/`;

export const GET_BY_ID = `${baseUrl}/`;

export const CREATE_FEEDBACK_PUBLIC = `${baseUrl}/public`;

export const GET_ALL_DATA = `${baseUrl}/get-all`;

export const DOWNLOAD = `${baseUrl}/download`;

export const GET_ALL_DATA_BY_TYPE = `${baseUrl}/get-dynamic`;

export const UPDATE_DATA = `${baseUrl}/update-specific`;

export const GET_BY_YEAR = `${baseUrl}/${commonRoutesConstants.YEAR_LIST}`;

export const GET_ALL_FORMATS = `${baseUrl}/get-master-docs`;

export const GET_BY_FILTER = `${baseUrl}/get-by-filter`;