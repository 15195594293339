import MainCard from 'components/MainCard';
import withRouter from 'components/withRouter';
import React, { useEffect, useRef, useState } from 'react';
import * as url from './utils/UrlHelper';
import { ApiServices } from 'services/apiServices';
import DynamicTable from 'components/reusables/DynamicTable';
import CustomDialog from 'components/reusables/CustomDialog';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getDocumentLifeCycleForMasterDoc } from 'services/utils';
import { FormatConstants, MasterDocumentTypeConstants, routeConstants } from 'services/keyConstants';
import ReportList from './ReportList';

const DeviceList = (props) => {
    const { userData } = props
    const [rows, setRows] = useState([]);
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [modalData, setModalData] = useState({

    });
    const [modalErrors, setModalErrors] = useState({});
    const filterData = useRef({
        currentPage: 1,
        totalPage: 1,
    });

    const getData = async () => {
        try {
            const uri = url.GET_DEVICE_LIST;
            const result = await ApiServices.callServiceGet(uri);
            if (result?.meta?.success) {
                setData(result?.data);
                setRows(result?.data?.rows);
                filterData.current.totalPage = result?.meta?.totalPages;
            } else {
                setData([]);
                filterData.current.currentPage = 1;
                filterData.current.totalPage = 1;
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const createReport = (row) => {
        setOpen(true);
        setSelectedData(row);
        setModalData({
            productName: row?.productName, productId: row?._id, documentIds: {
                parentDocId: row?.parentDocId, rowId: row?._id
            }
        }); // Pre-fill modal data with selected row
    };

    const validate = () => {
        let errors = {};
        if (!modalData?.productName) errors.productName = 'Product Name is required';
        if (!modalData?.modelNo) errors.modelNo = 'Model No is required';
        if (!modalData?.date) errors.date = 'Date is required';
        if (!modalData?.type) errors.type = 'Type is required';
        if (modalData?.type === "External" && !modalData?.email) errors.email = 'Email is required';

        setModalErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        try {
            if (!validate()) {
                return;
            }
            const reqObj = {
                ...modalData,
                ...(modalData?.type === "External" ? { link: `${window.location.origin}/report-details/${userData?.orgId}/` } : {})
            }
            const uri = url.EXTERNAL_FORMAT_CREATE
            
            const result = await ApiServices.callServicePostWithBodyData(uri, reqObj)
            if (result?.meta?.success) {
                setOpen(false)
                setSelectedData({})
            }
        } catch (error) {
            console.error(error)
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setModalData((prev) => ({ ...prev, [name]: value, }));
    };

    return (
        <MainCard title="Pending To Install">
            <Stack gap={2}>
                <Box>
                    <DynamicTable
                        isNumberDynamic={true}
                        rows={rows}
                        setRows={setRows}
                        columns={data?.columns}
                        isViewMode={true}
                        isStatic={true}
                        isExtraButtons={[
                            {
                                name: 'Install',
                                callFunction: createReport,
                                color: 'success',
                            },
                        ]}
                    />
                    {rows?.length > 0 ? <Box display="flex" justifyContent="center" mt={4}>
                        <Pagination
                            count={filterData.current?.totalPage ?? 1}
                            page={filterData.current?.currentPage ?? 1}
                            onChange={(e, val) => handlePageClick(e, val)}
                            color="primary"
                            hidePrevButton
                            hideNextButton
                        />
                    </Box> : null}
                </Box>

                <ReportList />
            </Stack>
            <CustomDialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Create Report</DialogTitle>
                <DialogContent>
                    <Stack gap={2} margin={2}>
                        <FormControl fullWidth>
                            <TextField
                                label="Product Name"
                                value={modalData?.productName || ''}
                                name="productName"
                                onChange={handleChange}
                                error={!!modalErrors.productName}
                                helperText={modalErrors.productName}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                label="Model No"
                                value={modalData?.modelNo || ''}
                                name="modelNo"
                                onChange={handleChange}
                                error={!!modalErrors.modelNo}
                                helperText={modalErrors.modelNo}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Installation Date"
                                    value={modalData?.date || null}
                                    onChange={(date) =>
                                        setModalData((prev) => ({ ...prev, date: date }))
                                    }
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            {modalErrors?.date ?? <FormHelperText error>{modalErrors?.date}</FormHelperText>}
                        </FormControl>
                        <FormControl fullWidth error={!!modalErrors?.type}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={modalData?.type || ''}
                                name="type"
                                onChange={handleChange}

                            >
                                {["Internal", "External"]?.map((option, index) => {
                                    return <MenuItem value={option} key={index}>{option}</MenuItem>
                                })}
                            </Select>
                            {modalErrors?.type ?? <FormHelperText error>{modalErrors?.type}</FormHelperText>}
                        </FormControl>
                        {modalData?.type === "External" && <FormControl fullWidth error={!!modalErrors?.email}>
                            <TextField
                                value={modalData?.email || ''}
                                name="email"
                                type="email"
                                onChange={handleChange}
                                label="Email"
                            />

                            {modalErrors?.email ?? <FormHelperText error>{modalErrors?.email}</FormHelperText>}
                        </FormControl>}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="error" variant="contained" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                    <Button color="success" variant="contained" onClick={handleSubmit}>
                        Save
                    </Button>
                </DialogActions>
            </CustomDialog>
        </MainCard>
    );
};

export default withRouter(DeviceList);
