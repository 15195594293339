import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import withRouter from "components/withRouter";
import { SaveAdd, Trash } from "iconsax-react";
import React, { useEffect, useState } from "react";
import {
  FormatConstants,
  MasterDocumentTypeConstants,
} from "services/keyConstants";
import {
  buildUrl,
  getDocumentLifeCycleForMasterDoc,
  getFormatDataById,
  showToast,
  toCamelCase,
} from "services/utils";
import * as url from "./utils/urlHelper";
import CustomNotification from "components/CustomNotification";
const DynamicTable = (props) => {
  const {
    // errors,
    // setErrors,
    productData,
    dataType,
    defaultRow,
    samplePlan,
    setProcedures,
    handleDownload,
    handleSubmit,
    year,
    ApiServices,
    buttonTitle
  } = props;
  // Get available options for the Select dropdown
  const [columns, setColumns] = useState([]);
  const [editingHeader, setEditingHeader] = useState(null);
  const [headerInput, setHeaderInput] = useState("");
  const [newColumn, setNewColumn] = useState("");
  const [data, setData] = useState(defaultRow ?? []);
  const [openSelectUsers, setOpenSelectUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [documentData, setDocumentData] = useState({});
  const [errors, setErrors] = useState({})
  const getAvailableOptions = (tableIndex) => {
    const selectedProducts = data?.map((table) => table?.product); // Collect all selected products
    return productData?.filter((option) => {
      return (
        !selectedProducts?.includes(option?.proId) ||
        data[tableIndex]?.product === option?.proId
      );
    });
  };
  // Get product name from product ID
  const getProductName = (id) => {
    const selected = productData?.find((product) => product?.proId === id);
    return selected?.productName;
  };

  // Handle product selection change
  const handleProductChange = (e, type, index) => {
    const updatedData = [...data];
    const selectedProductId = e?.target?.value;
    delete errors[`product_${index}`];
    // Update the product field in data
    updatedData[index] = {
      ...updatedData[index],
      product: selectedProductId,
    };

    setData(updatedData);

    // Find the selected product from productData
    const selected = productData?.find(
      (product) => product?.proId === selectedProductId
    );
    if (!selected) return; // Ensure selected product exists

    // Update columns based on the selected product's process data
    const updatedColumns = updatedData[index].columns?.map((column) => {
      if (column?.type === "dropdown" && column?.defaultName === "process") {
        return {
          ...column,
          options:
            updatedData[index]?.documentName === "In process Inspection Plan"
              ? selected?.inprocessData
              : selected?.finalData,
        };
      } else {
        return column;
      }
      setErrors({ ...errors });
    });

    // Assign updated columns back to the document
    updatedData[index].columns = updatedColumns;
    // Update the procedures state
    setProcedures((prevProcedures) => {
      const updatedProcedures = { ...prevProcedures };
      if (updatedProcedures.inprocessData) {
        updatedProcedures.inprocessData[index] = selected?.inprocessData;
      }
      return updatedProcedures;
    });

    setData(updatedData);
  };

  // Add a new product table
  const handleAddTable = () => {
    const newTable = {
      documentName: dataType,
      product: "", // Default empty product
      rows: [
        {
          process: "",
          parametersToBeInspected: [],
        },
      ], // You can add more fields as necessary
      columns: columns,
    };
    setData([...data, newTable]);
  };

  // Delete a product table
  const handleDeleteTable = (index) => {
    const updatedData = data?.filter((_, i) => i !== index);
    setData(updatedData);
  };

  const handleInputChange = (e, rowIndex, field, index, parametersIndex, defaultName) => {
    const updatedData = [...data];

    if (field === "process") {
      delete errors[`${"process"}_${index}_${rowIndex}`];
    } else {
      if (parametersIndex !== undefined) {
        delete errors[`${field}_${index}_${rowIndex}_${parametersIndex}`];
      } else {
        delete errors[`${field}_${index}_${rowIndex}`];
      }
    }

    if (field === "process") {
      const procedureType =
        dataType === "In process Inspection Plan"
          ? "inprocessData"
          : dataType === "Final process Inspection Plan"
            ? "finalData"
            : "";
      const allData = [...data];
      const currentProduct = allData?.[index];
      const selectedProduct = productData?.find(
        (product) => product?.proId === currentProduct?.product
      );

      const processDetails = selectedProduct?.[procedureType];
      const selectedPorcess = processDetails?.find(
        (process) => process?._id === e?.target?.value
      );
      let updatedProcessDetails = [];
      if (selectedPorcess?.parameterToBeInspected?.length > 0) {
        updatedProcessDetails = selectedPorcess?.parameterToBeInspected?.map(
          (process) => ({
            parameterToBeInspected: process?.childProcess,
            methodOfInspection: ["Visual Inspection"],
            specification: "",
            specificationWithTolerance: "",
            acceptanceCriteria: "",
          })
        );
      } else {
        updatedProcessDetails = [
          {
            parameterToBeInspected: selectedPorcess?.processName,
            methodOfInspection: ["Visual Inspection"],
            specification: "",
            specificationWithTolerance: "",
            acceptanceCriteria: "",
          },
        ];
      }

      updatedData[index].rows[rowIndex] = {
        ...updatedData[index].rows[rowIndex],
        processName: selectedPorcess?.processName,
        [field]: e?.target?.value,
        parametersToBeInspected: updatedProcessDetails || [],
      };
    } else {
      if (parametersIndex !== undefined) {
        if (e?.target?.value === "Range" && !samplePlan?.length > 0) {
          return showToast("Please create sampling plan first!!")
        }
        updatedData[index].rows[rowIndex].parametersToBeInspected[
          parametersIndex
        ] = {
          ...updatedData[index].rows[rowIndex].parametersToBeInspected[
          parametersIndex
          ],
          [field]: e?.target?.value,
        };
      } else {
        updatedData[index].rows[rowIndex] = {
          ...updatedData[index].rows[rowIndex],
          [field]: e?.target?.value,
        };
      }
    }
    setData(updatedData);
    setErrors({ ...errors }); // Update the errors state to trigger a re-render
  };

  const getFormatData = async () => {
    try {
      const uri =
        dataType === "In process Inspection Plan"
          ? FormatConstants.MAMOFPRODUCT.INPROCESS_INSPECTION_PLAN
          : FormatConstants.MAMOFPRODUCT.FINAL_INSPECTION_Plan;
      let result = await getFormatDataById(uri);

      if (result?.structuredData?.length === 1) {
        let columnData = result?.structuredData?.[0];
        const updatedCols = columnData?.columns?.map((col) => {
          if (!col?.source) {
            return { ...col, source: "parameter", type: "text" };
          } else {
            return col;
          }
        });
        setColumns(updatedCols);
        const updatedRows = defaultRow?.map((item) => {
          if (!item?.columns) {
            return { ...item, columns: columnData?.columns };
          } else if (!item?.source) {
            return { ...item, source: "parameter", type: "text" };
          } else {
            return item;
          }
        });
        setData(updatedRows);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFormatData();
  }, [dataType]);

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      let formatUri = "";
      if (dataType === "Final process Inspection Plan") {
        formatUri = FormatConstants.MAMOFPRODUCT.FINAL_INSPECTION_Plan;
      } else if (dataType === "In process Inspection Plan") {
        formatUri = FormatConstants.MAMOFPRODUCT.INPROCESS_INSPECTION_PLAN;
      }
      const masterDocData = await getDocumentLifeCycleForMasterDoc(
        formatUri,
        MasterDocumentTypeConstants.FORMAT
      );
      const queryParams = {
        masterDocId: masterDocData?._id,
        year: year,
      };
      const uri = buildUrl(url.GET_BY_MASTERDOCID, queryParams);
      const result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        setData(result?.data?.[0]?.document);
        setDocumentData(result?.data?.[0]);
        setSelectedUsers(result?.data?.[0]?.notifiedUsers);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const startEditingHeader = (colIdx, index) => {
    setEditingHeader({ colIdx, index }); // Track which column and data index is being edited
    setHeaderInput(data[index]?.columns[colIdx]?.name); // Set the column name for editing
  };

  const saveEditingHeader = () => {
    if (editingHeader) {
      const { colIdx, index } = editingHeader;
      const newColumnValue = toCamelCase(headerInput);

      const updatedData = [...data];
      const updatedColumn = {
        name: headerInput,
        value: newColumnValue,
      };

      updatedData[index].rows = updatedData[index]?.rows?.map((row) => {
        const updatedParameters = row?.parametersToBeInspected?.map((param) => {
          const oldColumnValue = updatedData[index]?.columns[colIdx]?.value;

          // If the column name is being updated, change all related keys in the row
          if (oldColumnValue) {
            const updatedParam = { ...param };
            if (updatedParam[oldColumnValue]) {
              updatedParam[newColumnValue] = updatedParam[oldColumnValue]; // Create new key-value pair
              delete updatedParam[oldColumnValue]; // Remove the old column key
            }
            return updatedParam;
          }
          return param;
        });
        return {
          ...row,
          parametersToBeInspected: updatedParameters,
        };
      });
      updatedData[index].columns[colIdx] = {
        ...updatedColumn,
        type: updatedData[index].columns[colIdx]?.type,
      };
      // Update the state with the new data
      setData(updatedData);

      // Reset editing state
      setEditingHeader(null);
      setHeaderInput("");
    }
  };

  const addColumn = (index) => {
    if (newColumn.trim()) {
      const newColumnValue = toCamelCase(newColumn?.trim());

      // Update the data with the new column added to rows' parametersToBeInspected
      const updatedData = data?.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            columns: [
              ...item.columns,
              { name: newColumn?.trim(), value: newColumnValue, type: "text" },
            ],
            rows: item?.rows?.map((row) => {
              const updatedParameters = row?.parametersToBeInspected?.map(
                (param) => ({
                  ...param,
                  [newColumnValue]: "", // Add the new column with a default empty value
                })
              );

              return {
                ...row,
                parametersToBeInspected: updatedParameters,
              };
            }),
          };
        }
        return item;
      });

      setData(updatedData); // Update the data state with the new column in columns and parametersToBeInspected
      setNewColumn(""); // Clear the input field for adding a new column
    }
  };

  const deleteColumn = (colValue) => {
    // Create a copy of the data state to avoid direct mutation
    const updatedData = [...data];

    // Iterate through each item in the data
    updatedData?.forEach((item) => {
      item.columns = item?.columns?.filter(
        (column) => column?.value !== colValue
      );

      // Iterate through each row in the item
      item?.rows?.forEach((row) => {
        // Update the parametersToBeInspected by deleting the specified column
        row?.parametersToBeInspected?.forEach((param) => {
          if (param[colValue] !== undefined) {
            delete param[colValue]; // Remove the column from the parameter
          }
        });
      });
    });

    // Update the state with the modified data
    setData(updatedData);
  };

  const updateSelectedUsers = async (selectedUsers) => {
    try {
      const reqObj = {
        id: documentData?._id,
        updateData: { notifiedUsers: selectedUsers },
      };
      const uri = url.UPDATE_MONITORING_MEASUREMENT_PRODUCT;
      const result = await ApiServices.callServicePutWithBodyData(uri, reqObj);
      showToast(result);
      if (result?.meta?.success) {
        getData();
      }
    } catch (error) {
      console.error("ERROR TO UPDATE SELECTED USERS", error);
    }
  };

  const handleDeleteRow = (tableIndex, rowIndex) => {
    setData((prevData) => {
      return prevData.map((table, tIndex) => {
        if (tIndex === tableIndex) {
          // Remove the specified row from the rows array
          const updatedRows = table?.rows?.filter(
            (_, rIndex) => rIndex !== rowIndex
          );
          return { ...table, rows: updatedRows };
        }
        return table;
      });
    });
  };

  const handleAddRow = (index, rowIndex) => {
    const newData = data[index]
    const type = dataType === "In process Inspection Plan" ? "inprocessData" : "finalData"
    const selectedProduct = productData?.find(product => product?.proId === newData?.product)?.[type]
    if (selectedProduct?.length <= newData?.rows?.length) {
      showToast("You cannot add more data");
      return;
    }
    const updatedData = [...data];
    let defaultRow = {
      process: "",
      parametersToBeInspected: [],
    };
    updatedData[index].rows = [
      ...(updatedData[index]?.rows?.length > 0 ? updatedData[index]?.rows : []),
      defaultRow,
    ]; // Append default row

    setData(updatedData);
  };

  const validateForm = () => {
    const newErrors = {};

    data?.forEach((document, docIndex) => {
      const { rows, columns } = document;
      if (!document?.product) {
        newErrors[`product_${docIndex}`] = "Product is Required";
      }
      rows?.forEach((row, rowIndex) => {
        columns?.forEach((column) => {
          const { value, validation } = column;
          if (column?.source === "row") {
            if (validation?.required && !row[value]) {
              newErrors[`${value}_${docIndex}_${rowIndex}`] = validation?.msg;
            }
          }
          // Validate nested parameters if the source is "parameter"
          if (column?.source === "parameter" && row?.parametersToBeInspected) {
            row?.parametersToBeInspected?.forEach((parameter, paramIndex) => {
              if (validation?.required && !parameter[value]) {
                newErrors[`${value}_${docIndex}_${rowIndex}_${paramIndex}`] = validation?.msg;
              }
            });
          }
        });
      });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <MainCard
      title={dataType}
      secondary={
        <>
          <Button variant="contained" onClick={handleDownload}>
            Download
          </Button>
        </>
      }
    >
      {data?.map((value, index) => {
        return (
          <TableContainer sx={{ margin: 1 }} key={index} component={Paper}>
            <Table aria-label="dynamic table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography fontSize={15} fontWeight="bold">
                      Product Name:{" "}
                      <Typography
                        fontSize={15}
                        variant="span"
                        fontWeight="normal"
                      >
                        {getProductName(value?.product) || "-"}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <FormControl error={!!errors?.[`product_${index}`]} fullWidth>
                      <Select
                        sx={{ minWidth: "300px" }}
                        value={value?.product || ""} // Bind the value to product _id
                        onChange={(e) =>
                          handleProductChange(e, dataType, index)
                        }
                        renderValue={(selected) => {
                          const product = productData?.find(
                            (product) => product?.proId === selected
                          );
                          return product?.productName || "";
                        }}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      >
                        {getAvailableOptions(index)?.map((option) => (
                          <MenuItem key={option?.proId} value={option?.proId}>
                            {option?.productName}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.[`product_${index}`] && (
                        <FormHelperText error fontSize={12}>
                          {errors[`product_${index}`]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddTable} // Call add function
                      style={{
                        marginLeft: "16px",
                      }}
                    >
                      Add Product
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDeleteTable(index)} // Call delete function
                      style={{
                        marginLeft: "16px",
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  {value?.columns?.map((col, colIdx) => {
                    return (
                      <TableCell sx={{ minWidth: 150 }} key={colIdx}>
                        {/* {
                      editingHeader?.colIdx === colIdx && editingHeader?.index === index ?
                        <Box display="flex" alignItems="center">
                          <TextField
                            value={headerInput}
                            onChange={(e) => setHeaderInput(e.target.value)}
                            onBlur={() => saveEditingHeader(colIdx, index)}
                            autoFocus
                          />
                          <IconButton
                            onClick={() => saveEditingHeader(colIdx, index)}
                            style={{ marginLeft: "10px" }}
                          >
                            <SaveAdd />
                          </IconButton>
                        </Box> : */}
                        <Box display="flex" alignItems="center">
                          {col?.name}

                          {/* <IconButton
                            color="success"
                            onClick={() =>
                              startEditingHeader(colIdx, index)
                            }
                            style={{ marginLeft: "10px" }}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => deleteColumn(col?.value, index)}
                            style={{ marginLeft: "10px" }}
                          >
                            <Trash />
                          </IconButton> */}
                        </Box>
                        {/* } */}
                      </TableCell>
                    );
                  })}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {value?.rows?.map((row, rowIndex) => (
                  <>
                    <TableRow key={rowIndex}>
                      <TableCell
                        rowSpan={
                          (row?.parametersToBeInspected?.length || 1) + 1
                        }
                      >
                        {rowIndex + 1}
                      </TableCell>
                      <TableCell
                        rowSpan={
                          (row?.parametersToBeInspected?.length || 1) + 1
                        }
                      >
                        <FormControl error={!!errors[`${"process"}_${index}_${rowIndex}`]} fullWidth>
                          <Select
                            value={row?.process || ""}
                            onChange={(e) =>
                              handleInputChange(e, rowIndex, "process", index, "process")
                            }
                          >
                            {value?.columns
                              ?.find((col) => col?.defaultName === "process")
                              ?.options?.map((option) => (
                                <MenuItem key={option?._id} value={option?._id}>
                                  {option?.processName}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors[`${"process"}_${index}_${rowIndex}`] && (
                            <FormHelperText error fontSize={12}>
                              {errors[`${"process"}_${index}_${rowIndex}`]}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </TableCell>
                    </TableRow>

                    {/* Render parametersToBeInspected and dynamic columns */}
                    {row?.parametersToBeInspected?.map(
                      (parameter, paramIndex) => {

                        return (
                          <TableRow key={`${rowIndex} - ${paramIndex} `}>
                            {value?.columns?.map((col, colIndex) => {
                              const columnValue = parameter?.[col?.value];
                              if (col?.defaultName === "process") return null;
                              if (columnValue !== undefined) {
                                <FormControl fullWidth>
                                  <TextField
                                    label={col?.name}
                                    name={col?.value}
                                    value={columnValue || ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        rowIndex,
                                        col?.value,
                                        index,
                                        paramIndex,
                                        col?.defaultName
                                      )
                                    }
                                    error={errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`]}
                                    fullWidth
                                  />
                                  {errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`] && (
                                    <FormHelperText error fontSize={12}>
                                      {errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`]}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              }
                              if (
                                col?.type === "text" ||
                                col?.type === undefined
                              ) {

                                return (
                                  <TableCell key={colIndex}>
                                    <FormControl fullWidth>
                                      <TextField
                                        label={col?.name}
                                        name={col?.value}
                                        value={parameter?.[col?.value] || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            rowIndex,
                                            col?.value,
                                            index,
                                            paramIndex,
                                            col?.defaultName
                                          )
                                        }
                                        error={errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`]}
                                        fullWidth
                                      />
                                      {errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`] && (
                                        <FormHelperText error>
                                          {errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`]}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                );
                              } else if (col?.type === "dropdown") {
                                return (
                                  <TableCell key={colIndex}>
                                    <FormControl error={!!errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`]} fullWidth>
                                      <Select
                                        rowSpan={
                                          col.value === "process"
                                            ? (row?.parametersToBeInspected
                                              ?.length || 1) + 1
                                            : 1
                                        }
                                        value={parameter?.[col?.value] || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            rowIndex,
                                            col?.value,
                                            index,
                                            paramIndex,
                                            col?.defaultName
                                          )
                                        }
                                      >
                                        {col?.options?.map((option) =>
                                          typeof option === "string" ? (
                                            <MenuItem
                                              key={option}
                                              value={option}
                                            >
                                              {option}
                                            </MenuItem>
                                          ) : (
                                            <MenuItem
                                              key={
                                                option[col?.renderOptionValue]
                                              }
                                              value={
                                                option[col?.renderOptionValue]
                                              }
                                            >
                                              {option[col?.renderOptionValue]}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                      {errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`] && (
                                        <FormHelperText error>
                                          {errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`]}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                );
                              } else if (col?.type === "dynamicDropdown") {
                                return (
                                  <TableCell key={colIndex}>
                                    <FormControl error={errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`]} fullWidth>
                                      <Autocomplete
                                        multiple
                                        freeSolo // Allow values not in the options
                                        value={
                                          Array.isArray(parameter?.[col?.value])
                                            ? parameter?.[col?.value]
                                            : []
                                        }
                                        onChange={(e, newValue) => {
                                          handleInputChange(
                                            { target: { value: newValue } }, // Pass the updated array
                                            rowIndex,
                                            col?.value,
                                            index,
                                            paramIndex,
                                            col?.defaultName
                                          );
                                        }}
                                        options={
                                          Array.isArray(col?.options)
                                            ? col.options
                                            : []
                                        } // Provide options
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={col?.name}
                                          />
                                        )}
                                        renderOption={(props, option) => (
                                          <li
                                            {...props}
                                            style={{
                                              display:
                                                col?.defaultName === "methodOfInspection" &&
                                                  ((option === "Physical Inspection" &&
                                                    parameter?.[col?.value]?.includes("Visual Inspection")) ||
                                                    (option === "Visual Inspection" &&
                                                      parameter?.[col?.value]?.includes("Physical Inspection")))
                                                  ? "none"
                                                  : "list-item",
                                            }}
                                          >
                                            {option}
                                          </li>
                                        )}

                                      />
                                      {errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`] && (
                                        <FormHelperText error>
                                          {errors?.[`${col?.value}_${index}_${rowIndex}_${paramIndex}`]}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                );
                              } else {
                                <TextField />;
                              }
                            })}
                            {paramIndex === 0 && (
                              <TableCell
                                rowSpan={
                                  (row?.parametersToBeInspected?.length || 1)
                                }
                              >
                                <Stack
                                  sx={{ display: "flex", flexDirection: "row" }}
                                  gap={1}
                                >
                                  <IconButton
                                    color="success"
                                    onClick={() => handleAddRow(index)}
                                  >
                                    <Add />
                                  </IconButton>
                                  <IconButton
                                    color="error"
                                    onClick={() =>
                                      handleDeleteRow(index, rowIndex)
                                    }
                                  >
                                    <Delete />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      }
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
            {/* <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              mt={3}
              mb={3}
            >
              <Box display="flex" alignItems="center" ml={2}>
                <TextField
                  label="Add New Column"
                  value={newColumn}
                  onChange={(e) => setNewColumn(e.target.value)}
                  style={{ marginRight: "10px" }}
                />
                <Button onClick={() => addColumn(index)} variant="contained" color="primary">
                  Add Column
                </Button>
              </Box>
            </Box> */}
          </TableContainer>
        );
      })}
      {documentData?._id &&
        <Box margin={2}>
          <Typography fontWeight={"bold"}>
            Please select the users to prepare the report:
          </Typography>
          <Button
            variant="contained"
            color="success"
            onClick={() => setOpenSelectUsers(true)}
          >
            Select Users
          </Button>
          {selectedUsers?.length > 0 && (
            <Box margin={2}>
              <Typography fontWeight={"bold"}>
                Selected users for prepare report:
              </Typography>
              <Typography>
                {selectedUsers?.map((user) => user?.userDesignation).join(", ")}
              </Typography>
            </Box>
          )}
        </Box>}

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            if (validateForm()) {
              handleSubmit(
                dataType === "Final process Inspection Plan"
                  ? "Final Inspection Plan"
                  : "In Process Inspection Plan",
                data,
                getData,
                selectedUsers
              )
            } else {
              return showToast("Please select all process and fill all data!!")
            }
          }
          }
        >
          {buttonTitle ?? "Save"}
        </Button>
      </Box>
      {openSelectUsers ? (
        <CustomNotification
          dialogTitle={"Please select user to assign report"}
          open={openSelectUsers}
          setOpen={setOpenSelectUsers}
          handleSubmit={(selecedUser) => {
            updateSelectedUsers(selecedUser);
          }}
          selectedUsers={selectedUsers}
          isMultipleUser={true}
        />
      ) : null}
    </MainCard>
  );
};

export default withRouter(DynamicTable);
