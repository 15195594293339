import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Button,
  Table,
  Box,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Pagination,
  MenuItem,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  DialogContent,
  DialogTitle,
  Stack,
  Paper,
  IconButton,
  Divider,
} from "@mui/material";
import { Eye, Filter as FilterIcon } from "iconsax-react";
import { Colors } from "services/keyConstants";
import CustomDialog from "components/reusables/CustomDialog";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import withRouter from "components/withRouter";
import { FIND_BMR_BY_PRODUCT, GET_ALL } from "./Utils/UrlHelper";
import SearchAndViewToggle from "components/reusables/SearchAndViewToggle";
import dayjs from "dayjs";
import MainCard from "components/MainCard";
import { useLocation } from "react-router";
import ProcessListTable from "./ProcessListTable";
import BMRProcessModal from "./BMRProcessModal";

const BMRListTable = (props) => {
  const { ApiServices } = props,
    { navigate } = props?.router;
  const location = useLocation();
  const { productId, childProductId } = location.state || {};
  const [filterObj, setFilterObj] = useState({
    dateRange: [null, null],
    process: "",
    processId: "",
  });
  const [isFilterModelOpen, setIsFilterModelOpen] = useState(false);
  const [processesOptions, setProcessesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    limit: 10,
    currentPage: 1,
  });
  const [filter, setFilter] = useState({
    viewMode: "grid",
    search: "",
  });
  const bmrFilterData = useRef({
    currentPage: 1,
    totalPage: 1,
  });
  const [filteredData, setFilteredData] = useState([]);
  const [openProcessModal, setOpenProcessModal] = useState(false);
  const [selectedProcessData, setSelectedProcessData] = useState([]);

  const column = useMemo(() => [
    {
      Header: "BMR No",
      accessor: "bmrNo",
      type: "string",
      Cell: (cellProps) => {
        const { bmrNumber, isFinalCreated, _id } =
          cellProps?.row?.original || {};
        const navigatePath = isFinalCreated
          ? `/production/final-split-bmr?id=${_id}`
          : `/production/batch-manufacturing-record?id=${_id}`;

        return (
          <div
            className="d-flex align-items-center"
            style={{
              cursor: "pointer",
              color: "blue",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              onClick={() => navigate(navigatePath)}
              style={{ marginRight: "8px" }}
            >
              {bmrNumber || "N/A"}
            </span>
          </div>
        );
      },
    },
    {
      Header: "Reference BMR No",
      accessor: "referenceBmrNo",
      type: "string",
      Cell: (cellProps) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0"></div>
            {cellProps?.row?.original?.referenceBmrNumber || "N/A"}
          </div>
        </>
      ),
    },
    {
      Header: "BMR Status",
      accessor: "bmrStatus",
      type: "string",
      Cell: (cellProps) => (
        <>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0"></div>
            {cellProps?.row?.original?.bmrStatus || "N/A"}
          </div>
        </>
      ),
    },
    {
      Header: "BMR Quantity",
      accessor: "quantity",
      type: "string",
      Cell: (cellProps) => {
        const { processData } = cellProps?.row?.original || {};
        const selectedProcessId = filterObj.processId;

        // Find the selected process from processData
        const selectedProcess = processData?.find(
          (process) => process.processId === selectedProcessId
        );

        return (
          <>
            {selectedProcess ? (
              <span>
                {selectedProcess.processName} - {selectedProcess.balanceQnty}
              </span>
            ) : (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickOpenProcessModal(processData);
                }}
              >
                <Eye />
              </IconButton>
            )}
          </>
        );
      },
    },
  ]);

  const getAllBMR = async (filters = {}) => {
    try {
      const queryParams = new URLSearchParams({
        productId: productId,
        childProductId: childProductId,
        page: bmrFilterData?.current?.currentPage,
        limit: paginationData?.limit,
        ...(filter?.search?.length > 0 && { search: filter?.search }),
        ...(filters.startDate && { startDate: filters.startDate }),
        ...(filters.endDate && { endDate: filters.endDate }),
        ...(filters.process && { process: filters.process }),
        ...(filters.processId && { process: filters.processId }),
      }).toString();

      const uri = `${FIND_BMR_BY_PRODUCT}?${queryParams}`;
      let result = await ApiServices?.callServiceGet(uri);
      if (result?.meta?.success) {
        setData(result.data);
        setFilteredData(result.data);
        bmrFilterData.current.totalPage = result?.meta?.totalPages;
        const processOptions = [
          ...(result?.metaData?.inprocessData || []).map((item) => ({
            value: item._id,
            label: item.processName,
            type: "In-Process", // Add type
          })),
          ...(result?.metaData?.finalData || []).map((item) => ({
            value: item._id,
            label: item.processName,
            type: "Final Process", // Add type
          })),
        ];
        setProcessesOptions(processOptions);
      } else {
        setData([]);
        setFilteredData([]);
        bmrFilterData.current.currentPage = 1;
        bmrFilterData.current.totalPage = 1;
      }
    } catch (error) {
      console.error("GET_ALL_PRODUCTION_PLANNING", error);
    }
  };

  useEffect(() => {
    bmrFilterData.current.currentPage = 1;
    getAllBMR();
  }, [filter?.search]);

  const handlePageClick = (e, val) => {
    bmrFilterData.current.currentPage = val;
    getAllBMR();
  };

  const handleClickOpenProcessModal = (processData) => {
    setSelectedProcessData(processData || []); // Default to an empty array if undefined
    setOpenProcessModal(true);
  };

  // Function to close the modal
  const handleCloseProcessModal = () => {
    setOpenProcessModal(false);
  };

  const handleModel = () => {
    setIsFilterModelOpen(!isFilterModelOpen);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleSearch = (searchKey, value) => {
    setPaginationData((prevData) => ({
      ...prevData,
      currentPage: 1, // Reset to first page on search/filter update
    }));

    setFilterObj({
      dateRange: [null, null],
      process: "",
      processId: "",
    });
  };

  const submitFilter = () => {
    const isDateRangeValid =
      Array.isArray(filterObj.dateRange) && filterObj.dateRange.length === 2;

    const startDate = isDateRangeValid ? filterObj.dateRange[0] : null;
    const endDate = isDateRangeValid ? filterObj.dateRange[1] : null;

    const filteredData = data?.filter((item) => {
      const createdAt = new Date(item.createdAt);
      const isDateMatched =
        startDate && endDate
          ? createdAt >= new Date(startDate) && createdAt <= new Date(endDate)
          : true;

      // Filter by process if a process is selected
      const isProcessMatched = filterObj.processId
        ? item.processData.some(
            (process) => process.processId === filterObj.processId
          )
        : true;

      return isDateMatched && isProcessMatched;
    });
    setFilteredData(filteredData);
    handleModel();
  };

  const columns = useMemo(() => [
    {
      Header: "Sr.No",
      accessor: "srno",
      Cell: (cellProps) => (
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 ms-2 name">
            {cellProps.row.index +
              1 +
              (paginationData?.currentPage - 1) * paginationData?.limit}
          </div>
        </div>
      ),
    },
    ...column,
  ]);

  const resetFilter = () => {
    setFilteredData(data); // Reset to the original data
    setFilterObj({
      dateRange: [null, null],
      process: "",
      processId: "",
    });
  };

  return (
    <>
      <MainCard
        title="Batch Manufacturing Record"
        secondary={
          <Stack sx={{ display: "flex", flexDirection: "row" }} gap={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={resetFilter}
              sx={{
                whiteSpace: "nowrap",
                minHeight: "35px",
                marginLeft: "10px",
              }}
            >
              Clear Filter
            </Button>

            <Button
              variant="contained"
              color="success"
              id="filter-btn"
              sx={{ whiteSpace: "nowrap", minHeight: "35px" }}
              onClick={handleModel}
            >
              <FilterIcon />
            </Button>
          </Stack>
        }
      >
        <CustomDialog
          open={isFilterModelOpen}
          onClose={handleModel}
          ariaLabelledby="date-range-dialog"
        >
          <DialogTitle>Apply Filter</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              paddingTop: "10px !important",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                value={filterObj?.dateRange}
                onChange={(newValue) =>
                  setFilterObj({ ...filterObj, dateRange: newValue })
                }
                localeText={{ start: "Start Date", end: "End Date" }}
              />
            </LocalizationProvider>

            <FormControl fullWidth>
              <InputLabel>Select Process</InputLabel>
              <Select
                value={filterObj?.process || ""}
                onChange={(event) => {
                  const selectedProcess = processesOptions.find(
                    (option) => option.value === event.target.value
                  );

                  setFilterObj((prev) => ({
                    ...prev,
                    process: event.target.value,
                    processId: selectedProcess?.value || "", // Ensure processId is stored correctly
                  }));
                }}
              >
                {/* Group for In-Process */}
                {processesOptions.some(
                  (option) => option.type === "In-Process"
                ) && (
                  <MenuItem disabled>
                    <strong>In-Process</strong>
                  </MenuItem>
                )}
                {processesOptions
                  .filter((option) => option.type === "In-Process")
                  .map((option, index) => (
                    <MenuItem key={`in-${index}`} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}

                {/* Group for Final Process */}
                {processesOptions.some(
                  (option) => option.type === "Final Process"
                ) && (
                  <MenuItem disabled>
                    <strong>Final Process</strong>
                  </MenuItem>
                )}
                {processesOptions
                  .filter((option) => option.type === "Final Process")
                  .map((option, index) => (
                    <MenuItem key={`final-${index}`} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModel} color="error" variant="contained">
              Cancel
            </Button>
            <Button onClick={submitFilter} color="success" variant="contained">
              Apply
            </Button>
          </DialogActions>
        </CustomDialog>

        <Box>
          {isLoading ? (
            <>No Data Found</>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end", // Aligns content to the right
                  padding: "8px 0",
                  gap: 2, // Maintains spacing
                  flexWrap: "nowrap", // Prevents wrapping
                }}
              >
                <SearchAndViewToggle
                  filter={filter}
                  setFilter={setFilter}
                  width={12}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "5px 0px 10px 0px",
                  gap: "10px",
                }}
              >
                <TableContainer component={Paper}>
                  <Table style={{ borderRadius: "10px" }}>
                    {/* Table Head */}
                    <TableHead>
                      <TableRow>
                        {columns.map((column, colIndex) => (
                          <TableCell
                            key={column.accessor}
                            className="text-center"
                            onClick={() => handleSearch(column.accessor)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  minWidth:
                                    column?.type === undefined ? "" : "150px",
                                  maxWidth:
                                    column?.type === undefined ? "" : "200px",
                                }}
                              >
                                {column?.Header}
                              </span>
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {/* Table Body */}
                    <TableBody
                      style={{ boxShadow: "0px 2px 5px rgba(0,0,0,0.3)" }}
                    >
                      {filteredData.length > 0 ? (
                        filteredData.map((item, rowIndex) => (
                          <TableRow
                            key={item?.id || rowIndex}
                            style={{ backgroundColor: Colors.DEFAULT_COLOR }}
                          >
                            {columns.map((column, colIndex) => (
                              <TableCell key={column.accessor}>
                                {column.Cell
                                  ? column.Cell({
                                      row: { original: item, index: rowIndex },
                                    })
                                  : item[column.accessor]}

                                {colIndex !== columns.length - 1 && (
                                  <Typography
                                    variant="span"
                                    style={{
                                      position: "absolute",
                                      width: "1px",
                                      height: "50%",
                                      backgroundColor:
                                        "rgba(219, 224, 229, 0.65)",
                                      right: 0,
                                      top: "20%",
                                    }}
                                  />
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={columns.length} align="center">
                            No Data Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          )}
        </Box>

        <CustomDialog
          open={openProcessModal}
          onClose={handleCloseProcessModal}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Process List</DialogTitle>
          <Divider />
          <DialogContent>
            {/* <BMRProcessModal data={documentData?.processData} /> */}
            <BMRProcessModal data={selectedProcessData} />
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Button
              onClick={handleCloseProcessModal}
              variant="contained"
              color="error"
            >
              Close
            </Button>
          </DialogActions>
        </CustomDialog>
        <Box display="flex" justifyContent="center">
          <Pagination
            count={bmrFilterData?.current?.totalPage ?? 1}
            page={bmrFilterData?.current?.currentPage ?? 1}
            onChange={(e, val) => handlePageClick(e, val)}
            color="primary"
            hidePrevButton
            hideNextButton
          />
        </Box>
      </MainCard>
    </>
  );
};

export default withRouter(BMRListTable);
