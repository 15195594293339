import { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";

// render - pages
const PurchaseRecords = Loadable(lazy(() => import("../PurchaseRecords")));
const View = Loadable(lazy(() => import("../View")));
const InitialSupplier = Loadable(lazy(() => import("../InitialSupplier")));
const PurchaseOrder = Loadable(lazy(() => import("../PurchaseOrder")));
const InspectionTableByPurchaseOrderId = Loadable(
  lazy(() => import("./molecules/InspectionTableByPurchaseOrderId"))
);
const SupplierServiceProvider = Loadable(
  lazy(() => import("../SupplierServiceProvider"))
);
const IncomingInspectionReport = Loadable(
  lazy(() => import("../IncomingInspectionReport"))
);
const SupplierAgreementRecord = Loadable(
  lazy(() => import("../SupplierAgreementRecord"))
);
const RequisitionSlipRecord = Loadable(
  lazy(() => import("../RequisitionSlipRecord"))
);
const IssueSlipRecords = Loadable(lazy(() => import("../IssueSlipRecords")));

const RawMaterialStockRegister = Loadable(
  lazy(() => import("../RawMaterialStockRegister"))
);
const SupplierEvaluatioRecords = Loadable(
  lazy(() => import("../SupplierEvaluatioRecords"))
);
const SubContractorAgreementRecord = Loadable(
  lazy(() => import("../SubContractorAgreementRecord"))
);

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// ==============================|| PAGE ROUTES ||============================== //

const newPurchaseRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.PURCHASE, null, [
      createRoute("records", <PurchaseRecords />),
      createRoute("intiateSuppliers", <InitialSupplier />),
      createRoute("view/:id/:supplierFormId?/:purchaseOrderId?", <View />),
      createRoute("supplier-service-provider", <SupplierServiceProvider />),
      createRoute("purchase-orders", <PurchaseOrder />),
      createRoute(
        "inspectionTable/:purchaserOrderId",
        <InspectionTableByPurchaseOrderId />
      ),
      createRoute("incomming-inspection-report", <IncomingInspectionReport />),
      createRoute("stock-register", <RawMaterialStockRegister />),
      createRoute("supplier-evaluation-record", <SupplierEvaluatioRecords />),
      createRoute("supplier-agreement-record", <SupplierAgreementRecord />),
      createRoute("requistion-slip-record", <RequisitionSlipRecord />),
      createRoute("issue-slip-record", <IssueSlipRecords />),
      createRoute(
        "subContractor-agreement-record",
        <SubContractorAgreementRecord />
      ),
    ]),
  ]
);

export default newPurchaseRoute;
