import moment from "moment";
import { FormatConstants } from "services/keyConstants";
import { showToast } from "services/utils";
export const getDefaultData = (columnData, options = {}, functions = {}) => {
  return columnData?.map((item) => {
    // Create a shallow copy of the item to avoid mutating the original object
    const newItem = { ...item };

    // Handle defaultValue with dynamicField
    const dynamicField = newItem?.defaultValue?.dynamicField;
    if (dynamicField) {
      const dynamicData = options[dynamicField];

      if (dynamicData) {
        // Check if defaultValue is an object with key-value mappings
        if (typeof newItem?.defaultValue?.defaultValue === 'object') {
          // Initialize defaultObject
          newItem.defaultObject = {};

          // Iterate over the keys in defaultValue.defaultValue
          for (const [key, value] of Object.entries(newItem?.defaultValue?.defaultValue)) {
            // Assign properties dynamically using computed property names
            newItem.defaultValue.defaultValue[key] = dynamicData[value];
          }
          newItem.inputValue = newItem.defaultValue.defaultValue;
        } else {
          // Set defaultValue to the corresponding value from dynamic data
          const key = newItem?.defaultValue?.defaultValue;
          newItem.defaultValue.defaultValue = dynamicData[key] ?? newItem?.defaultValue?.defaultValue;
          newItem.inputValue = dynamicData[key] ?? newItem?.defaultValue?.defaultValue;
        }
      }
    }

    if (newItem?.isCustomer && newItem?.isCustomer?.dynamicKey) {
      // Retrieve the dynamic field and key from the options
      const dynamicData = options[newItem?.isCustomer?.dynamicField];
      const key = newItem?.isCustomer?.dynamicKey;

      // Function to safely access nested properties by a dot-notation key
      const getNestedValue = (obj, keyPath) => {
        return keyPath?.split('.')?.reduce((acc, part) => {
          return acc && acc[part] !== undefined ? acc[part] : undefined;
        }, obj);
      };

      // Get the value using the nested key path
      const value = getNestedValue(dynamicData, key);

      // Assign the value to inputValue if found
      if (value !== undefined) {
        newItem.inputValue = value;
      }
    }

    // Handle defaultValue with format 'currentDate' using Moment.js
    if (newItem.defaultValue?.format === 'currentDate') {
      if (newItem?.defaultValue?.type === 'iso') {
        newItem.defaultValue.defaultValue = moment().toISOString();
        newItem.inputValue = moment().toISOString();
      } else {
        newItem.defaultValue.defaultValue = moment().format('DD/MM/YYYY');
        newItem.inputValue = moment().format('DD/MM/YYYY');
      }
    }

    // Handle the extraOnChange field if it exists and if it's a function name in the functions object
    if (newItem?.extraOnChange && typeof newItem?.extraOnChange === 'string') {
      const extraOnChangeFunction = functions[newItem?.extraOnChange];
      // Check if the function exists and is a function
      if (typeof extraOnChangeFunction === 'function') {
        try {
          // If the function is asynchronous, wait for it
          if (extraOnChangeFunction?.constructor?.name === 'AsyncFunction') {
            newItem.extraOnChange = async (e) => {
              await extraOnChangeFunction(e);
            };
          } else {
            // Normal (synchronous) function
            newItem.extraOnChange = extraOnChangeFunction;
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        console.error(`Function ${newItem?.extraOnChange} not found in the provided functions object.`);
      }
    }

    return newItem;
  });
};

export const getDefaultDataForDynamicTable = (columnData, options = {}, functions = {}) => {
  return columnData?.map((item) => {
    // Create a shallow copy of the item to avoid mutating the original object
    const newItem = { ...item };

    // Handle defaultValue with dynamicField and functionName
    const dynamicField = newItem?.defaultValue?.dynamicField;
    const functionName = newItem?.defaultValue?.functionName;
    if (dynamicField && functionName) {
      const dynamicData = options[dynamicField];
      const dynamicFunction = functions[functionName];

      if (typeof dynamicFunction === 'function') {
        try {
          // Call the function with the dynamic data
          const result = dynamicFunction(dynamicData);

          // Check if the function is asynchronous
          if (result instanceof Promise) {
            result
              .then((resolvedValue) => {
                newItem.defaultValue = resolvedValue;
              })
              .catch((error) => console.error(`Error resolving function ${functionName}:`, error));
          } else {
            // Synchronous function result
            newItem.defaultValue = result;
          }
        } catch (error) {
          console.error(`Error calling function ${functionName}:`, error);
        }
      } else {
        console.error(`Function ${functionName} not found in the provided functions object.`);
      }
    }

    if (newItem?.isHidden) {
      if (newItem?.field)
        newItem.inputValue = newItem?.defaultValue?.[newItem?.field];
    }

    return newItem;
  });
};

export const getStatusTypes = (type) => {
  switch (type) {
    case FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.ORDER_FORM:
      return {
        filteredData: {
          currentPage: 1,
          totalPage: 1,
          index: 0,
          orderStatus: "",
          status: "",
          documentName: "Customer Order",
          subDocumentName: FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.ORDER_FORM,
          revisionNumber: "",
        },
        defaultOptionData: {
          statusOptions: [
            { label: "Pending From Customer Side", value: "Pending From Customer Side" },
            { label: "Waiting For Approval", value: "Waiting For Approval" },
            { label: 'Pending For Allocation', value: 'Pending For Allocation' },
            { label: 'Completed', value: 'Completed' },
            { label: "Rejected", value: "Rejected" },


            { label: 'Initiated', value: 'Initiated' },
            { label: 'Pending', value: 'pending' },
            { label: 'Pending For Approval', value: 'Pending For Approval' },
            { label: 'Review', value: 'review' },
          ]
        }
      };
      break;

    case FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.ORDER_REVIEW_FORM:
      return {
        filteredData: {
          currentPage: 1,
          totalPage: 1,
          index: 1,
          orderStatus: "",
          status: "",
          documentName: "Customer Order",
          subDocumentName: FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.ORDER_REVIEW_FORM,
          revisionNumber: "",
        },
        defaultOptionData: {
          statusOptions: [
            { label: 'Initiated', value: 'Initiated' },
            { label: 'Rejected', value: 'Rejected' },
            { label: 'Accepted', value: 'Accepted' },
            { label: "Request For Modified", value: "Request For Modified" },
            { label: 'Pending', value: 'Pending' },
          ]
        }
      };
      break;

    // Add more cases as needed
    case FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.DISTRIBUTION_RECORD:
      return {
        filteredData: {
          orderStatus: "Pending",
          status: "",
          documentName: "Customer Order",
          subDocumentName: FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.DISTRIBUTION_RECORD,
          index: 2,
        },
        defaultOptionData: {
          statusOptions: [
            { label: 'Initiated', value: 'Initiated' },
            { label: "Product Allocated", value: "Product Allocated" },
            { label: "Product Partially", value: "Product Partially" },
            { label: "Product Not Allocated", value: "Product Not Allocated" }
          ]
        }
      };
      break;

    case FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.DISTRIBUTOR_FORM:
      return {
        filteredData: {
          currentPage: 1,
          totalPage: 1,
          status: "",
          documentName: "Distributor Form",
          subDocumentName: FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.DISTRIBUTOR_FORM,
          revisionNumber: "",
        },
        defaultOptionData: {
          statusOptions: [
            { label: 'Initiated', value: 'Initiated' },
            { label: 'Waiting for review', value: 'Waiting for review' },
            { label: 'Approved', value: 'Approved' },
            { label: "Request For Modified", value: "Request For Modified" },
            { label: 'Rejected', value: 'Rejected' },
          ]
        }
      };
      break;

    case FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.DISTRIBUTOR_AGREEMENT:
      return {
        filteredData: {
          currentPage: 1,
          totalPage: 1,
          status: "",
          documentName: "Distributor Form",
          subDocumentName: FormatConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION.DISTRIBUTOR_AGREEMENT,
          revisionNumber: "",
        },
        defaultOptionData: {
          statusOptions: [
            { label: "Waiting for Agreement", value: "Waiting for Agreement" },
            { label: "Waiting for review", value: "Waiting for review" },
            { label: "Request for Modify", value: "Request for Modify" },
            { label: "Waiting for Document", value: "Waiting for Document" },
            { label: "Waiting for Document Approver", value: "Waiting for Document Approver" },
            { label: "Document Approved", value: "Document Approved" }
          ]
        }
      };
      break;

    // Default case if no matching type is found
    default:
      return {
        filteredData: {
          orderStatus: "Unknown",
          status: "Unknown",
          documentName: "Unknown Document",
        },
        defaultOptionData: {
          statusOptions: []
        }
      };
  }
};

export const handleFileUpload = (event, productRowsState, setProductRowsState, productColumnData) => {
  const file = event?.target?.files[0];
  if (!file) return;

  const reader = new FileReader();
  reader.onload = (e) => {
    const text = e?.target?.result;
    const rows = text?.split("\n")?.map(row => row?.split(",")); // Parse CSV into array

    if (!rows || rows.length < 4) { // Ensure at least headers + 1 data row
      console.error("Invalid CSV structure.");
      return;
    }

    // Extract headers after skipping the first two static rows
    const headers = rows[2]?.map(header => header.replace(/"/g, "").trim()); // Trim quotes & spaces
    const dataRows = rows.slice(3); // Data starts from row index 3

    const updatedData = [...productRowsState];

    // Find the column name dynamically for "quantity"
    const quantityColumn = productColumnData?.columns?.find(col => col?.defaultName === "quantity")?.name;

    if (!quantityColumn) {
      console.error("Quantity column not found!");
      return;
    }

    dataRows?.forEach((row) => {
      const rowData = Object.fromEntries(headers?.map((key, i) => [key, row[i]?.replace(/"/g, "").trim()])); // Convert row array into object

      // Find matching product in productRowsState based on "Product Details"
      const matchingIndex = updatedData?.findIndex(product => product?.productDetails === rowData["Product Details"]);

      if (matchingIndex !== -1) {
        let newQuantity = parseInt(rowData[quantityColumn]) || 0; // Ensure 0 if empty or invalid

        // If quantity is negative, set it to 0
        if (newQuantity < 0) {
          console.warn(`Negative quantity detected for ${rowData["Product Details"]}, setting to 0.`);
          newQuantity = 0;
        }

        updatedData[matchingIndex] = {
          ...updatedData[matchingIndex],
          quantity: newQuantity, // Update quantity dynamically
          checked: newQuantity > 0, // Check if quantity > 0, else uncheck
        };
      }
    });
    setProductRowsState(updatedData); // Update state

    showToast("File Uploaded Successfully", true);
  };

  reader.readAsText(file); // Read file as text
};

export const downloadCSV = (productRowsState, productColumnData) => {
  if (!productColumnData?.columns || !productRowsState) return;

  const headers = productColumnData?.columns?.map(col => col.name);

  const staticRow = `"Specify the Order Quantity",${Array(headers.length - 1).fill('""').join(",")}`;
  const blankRow = Array(headers.length).fill('""').join(",");
  const headerRow = headers.join(",");

  const rows = productRowsState?.map(row =>
    productColumnData?.columns?.map(col => {
      if (col?.defaultName === "quantity") {
        return `"${row[col?.defaultName] !== undefined ? row[col?.defaultName] : 0}"`; // Ensure 0 is printed
      }
      return `"${row[col?.defaultName] !== undefined ? row[col?.defaultName] : ""}"`;
    }).join(",")
  );

  const csvContent = [
    staticRow,  // Static message row
    blankRow,   // Blank row
    headerRow,  // Headers
    ...rows,    // Dynamic product rows
  ].join("\n");

  // Create a Blob with CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a download link
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "Product_Details.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
