import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants } from "services/keyConstants";
// Define lazy-loaded components
const View = Loadable(lazy(() => import("../View")));
// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const ArchiveRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.ARCHIVE, null, [
      createRoute("view/*", <View />) // Wildcard (*) allows infinite nesting
    ])
  ]
);
export default ArchiveRoute;
