import React, { useState } from "react";
import {
  Button,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import CustomDialog from "components/reusables/CustomDialog";
import { dispatchFunction, showToast } from "services/utils";
import withRouter from "./withRouter";

const ESign = (props) => {
  const { open, setOpen, handleSubmit, userData } = props;
  const [password, setPassword] = useState(
    window.location.href.includes("me.mavenregulatory.com") ? "12345" : ""
  );
  const [confirmPassword, setConfirmPassword] = useState(
    window.location.href.includes("me.mavenregulatory.com") ? "12345" : ""
  );

  const handleModal = () => {
    setOpen(!open);
    setPassword("");
    setConfirmPassword("");
  };

  const handlePasswordSubmit = async () => {
    if (password === confirmPassword && password !== "") {
      let reqObj = {
        password: password,
        clientUserId: userData?._id,
      };
      const uri = props?.commonUrl?.SIGN_IN;
      let result = await props?.ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );
      if (result?.result?.type === "success") {
        showToast("ESign Added Succesfully", true);
        handleSubmit(true);
        handleModal();
      } else {
        dispatchFunction(result);
      }
    } else {
      showToast("Passwords do not match or are empty");
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleModal}
        style={{ marginLeft: "10px" }}
      >
        E-Sign
      </Button>
      <CustomDialog open={open} onCancel={(e) => handleModal(e)} maxWidth="sm">
        <DialogTitle>Enter Password To E-Sign</DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ p: 2.5 }}>
          <Button variant="contained" color="error" onClick={handleModal}>
            Close
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handlePasswordSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};
export default withRouter(ESign);
