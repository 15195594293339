
import React, { useState, useRef } from "react";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  Drawer,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import CustomTooltip from "components/reusables/CustomTooltip";
import useConfig from "hooks/useConfig";
import { Add } from "iconsax-react";
import MainCard from "components/MainCard";
import withRouter from "components/withRouter";
import SimpleBar from "simplebar-react";
import { HEADER_HEIGHT } from "config";
import { SettingSVG } from "assets/Icons/HeaderContentSVGs";
import {
  theamDocument,
  headerDocument,
  documentSetting,
} from "assets/Icons/SVGs";
import { checkPermission, getItem } from "services/utils";
import {
  AdminAccessConstants,
  Constants,
  permissionConstants,
} from "services/keyConstants";
import persmissionData from "components/PermissionData";

const Setting = (props) => {
  const { userData } = props, { navigate } = props.router;
  const standardName = getItem(Constants.STANDARDNAME);
  const selectedTheme = useRef("");
  const { mode, menuOrientation } = useConfig();
  const [sideBaropen, setSideBarOpen] = useState(false);

  const handleSideBarToggle = () => {
    setSideBarOpen(!sideBaropen);
  };

  const handleTheme = () => {
    navigate(
      `/standards/themes?orgId=${userData?.orgId}&standardName=${standardName}&theme=${selectedTheme.current}`
    );
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <CustomTooltip title="Setting">
        <IconButton
          color="secondary"
          aria-label="setting"
          onClick={handleSideBarToggle}
          size="medium"
          sx={{
            color: "secondary.main",
            p: 1,
            bgcolor: "#fff",
            borderRadius: "100px",
          }}
        >
          <SettingSVG width={30} />
        </IconButton>
      </CustomTooltip>
      <Drawer
        sx={{
          zIndex: 2001,
        }}
        anchor="right"
        onClose={handleSideBarToggle}
        open={sideBaropen}
        PaperProps={{
          sx: {
            width: 350,
          },
        }}
      >
        {sideBaropen && (
          <MainCard content={false} border={false}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1.5}
              sx={{ p: 2.5 }}
            >
              <Typography variant="h5">Settings</Typography>
              <IconButton
                color="error"
                sx={{ p: 0 }}
                onClick={handleSideBarToggle}
              >
                <Add size={28} style={{ transform: "rotate(45deg)" }} />
              </IconButton>
            </Stack>
            <SimpleBar
              sx={{
                "& .simplebar-content": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <Box
                sx={{
                  p: 3,
                  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
                  margin: "0px 8px",
                }}
                border={0.1}
                borderColor={"#E7E8EA"}
                borderRadius={1.5}
              >
                <Grid container spacing={2.5} marginLeft={"-10px"}>
                  {/* theme-mode */}
                  <Grid item xs={12} borderBottom={"1px solid #F5F5F5"}>
                    <Stack>
                      <Typography variant="subtitle1" color="text.primary">
                        Document Style
                      </Typography>
                      <Typography
                        variant="caption"
                        color="#9FA2AA"
                        paddingBottom={1}
                      >
                        Adjust layout for a better reading experience
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <Button
                      onClick={handleTheme}
                      sx={{
                        marginTop: "10px",
                        marginLeft: "-10px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        width: "213px",
                        height: "35px",
                        gap: "10px",
                        border: "1px solid transparent",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          border: "1px solid grey",
                        },
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color="text.primary"
                        sx={{ marginTop: "9px" }}
                      >
                        {theamDocument()}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#353535",
                        }}
                      >
                        Document Theme
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.primary"
                        sx={{ marginTop: "9px" }}
                      >
                        {documentSetting()}
                      </Typography>
                    </Button>
                    <Button
                      sx={{
                        marginLeft: "-10px",
                        marginTop: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        width: "275px",
                        height: "35px",
                        gap: "10px",
                        border: "1px solid transparent",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          border: "1px solid grey",
                        },
                      }}
                      onClick={() => navigate("/standards/header-field")}
                      disabled={
                        !checkPermission(
                          persmissionData,
                          AdminAccessConstants?.HEADER,
                          permissionConstants?.CREATE
                        )
                      }
                    >
                      <Typography
                        variant="subtitle1"
                        color="text.primary"
                        sx={{ marginTop: "9px" }}
                      >
                        {headerDocument()}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#353535",
                        }}
                      >
                        Document Header Theme
                      </Typography>
                      <Typography sx={{ marginTop: "9px" }}>
                        {documentSetting()}
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Box>
            </SimpleBar>
          </MainCard>
        )}
      </Drawer>
    </Box>
  );
};

export default withRouter(Setting);