import { useRef, useState } from "react";
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import ProfileTab from "./ProfileTab";
import Avatar from "components/@extended/Avatar";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import useAuth from "hooks/useAuth";
import { capitalizeFirstLetter } from "services/utils";
import withRouter from "components/withRouter";
import { UserAccountSVG } from "assets/Icons/HeaderContentSVGs";

const ProfilePage = (props) => {
  const { theme, service, userData: initialUserData, userRole } = props,
    { navigate } = props?.router;
  const [userData, setUserData] = useState(initialUserData);
  const { logout } = useAuth();
  const [role, setRole] = useState(userRole);
  const handleLogout = async () => {
    try {
      await logout();
      navigate(`/`);
    } catch (err) {
      console.error(err);
    }
  };
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          width: 33,
          height: 33,
          p: 1,
          borderRadius: 100,
          "&:focus-visible": {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
          bgcolor: "#6293FF",
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <UserAccountSVG />
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position="top-right"
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 290,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 250,
                },
                borderRadius: 1.5,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} content={false}>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Stack
                          direction="row"
                          spacing={1.25}
                          alignItems="center"
                        >
                          <Avatar
                            sx={{ backgroundColor: "transparent" }}
                            alt={userData?.name}
                            src={service.IMG_URL + userData?.profileImage}
                          />
                          <Stack>
                            <Typography variant="subtitle1">
                              {userData?.name ?? "-"}
                            </Typography>
                            <Typography variant="body2" color="secondary">
                              {userData?.externalUser?.type === "customer"
                                ? capitalizeFirstLetter(
                                    userData?.externalUser?.type
                                  )
                                : role?.departmentName ?? "-"}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <ProfileTab
                    handleToggle={handleToggle}
                    handleLogout={handleLogout}
                    setRole={setRole}
                    role={role}
                  />
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default withRouter(ProfilePage);
