import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  
    Text : 'Dashboard'
};

const navText = createSlice({
    name : 'onBoarding',
    initialState,
    reducers : {
        currentText (state, action) {
            state.Text = action.payload.Text
        }
    }
})

export default navText.reducer

export const { currentText } = navText.actions
