import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Box, ButtonBase } from "@mui/material";
import withRouter from "components/withRouter";
import { useSelector } from "react-redux";
import { dispatch } from "store";
import { pinUnpinMenu } from "store/reducers/menu";
import { useState } from "react";

const Pin = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 21.9686L8.13 17.3376M8.135 17.3326L5.355 14.5526C4.401 13.5996 5.361 11.5566 6.665 11.4746C7.843 11.3996 10.57 11.8266 11.477 10.9196L13.967 8.42956C14.584 7.81156 14.192 6.42956 14.152 5.66756C14.094 4.65156 15.71 3.39656 16.567 4.25356L21.214 8.90156C22.074 9.75956 20.814 11.3706 19.801 11.3166C19.039 11.2766 17.656 10.8846 17.038 11.5016L14.548 13.9916C13.642 14.8986 14.068 17.6246 13.994 18.8026C13.912 20.1076 11.869 21.0676 10.914 20.1126L8.135 17.3326Z"
      stroke="#7EA6FF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const Unpin = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 21.9686L8.13 17.3376M8.135 17.3326L5.355 14.5526C4.401 13.5996 5.361 11.5566 6.665 11.4746C7.843 11.3996 10.57 11.8266 11.477 10.9196L13.967 8.42956C14.584 7.81156 14.192 6.42956 14.152 5.66756C14.094 4.65156 15.71 3.39656 16.567 4.25356L21.214 8.90156C22.074 9.75956 20.814 11.3706 19.801 11.3166C19.039 11.2766 17.656 10.8846 17.038 11.5016L14.548 13.9916C13.642 14.8986 14.068 17.6246 13.994 18.8026C13.912 20.1076 11.869 21.0676 10.914 20.1126L8.135 17.3326Z"
      stroke="#7EA6FF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="4.04526"
      y1="3.48503"
      x2="21.0453"
      y2="21.485"
      stroke="#7EA6FF"
      stroke-width="1.5"
    />
  </svg>
);

const LogoSection = (props) => {
  const { drawerOpen } = useSelector((state) => state.menu);
  const { sx, to, images, objectFit, borderRadius, height, width } = props,
    { APP_DEFAULT_PATH } = props?.config;
  const { pinUnpin } = useSelector((state) => state.menu);
  const [pin, setPin] = useState(false);
  return (
    <ButtonBase
      disableRipple
      component={Link}
      to={!to ? APP_DEFAULT_PATH : to}
      sx={[
        sx,
        {
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 10px",
        },
      ]}
    >
      <img
        src={images}
        alt="Logo"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: width ?? "auto",
          height: height ?? "auto",
          objectFit: objectFit,
          borderRadius: borderRadius,
        }}
      />
      <Box
        sx={drawerOpen ? { display: "block" } : { display: "none" }}
        onClick={() => dispatch(pinUnpinMenu(!pinUnpin), setPin(!pin))}
      >
        {pin ? <Unpin /> : <Pin />}
      </Box>
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string,
};

export default withRouter(LogoSection);
