import { Box, useMediaQuery } from "@mui/material";
import Message from "./Message";
import Profile from "./Profile";
import Notification from "./Notification";
import useConfig from "hooks/useConfig";
import DrawerHeader from "layout/MainLayout/Drawer/DrawerHeader";
import MegaMenuSection from "./MegaMenuSection";
import withRouter from "components/withRouter";
import Setting from "./Setting";
import FullScreen from "./FullScreen";
import ISOCard from "./ISOCard";

const HeaderContent = (props) => {
  const { userData } = props;
  const { MenuOrientation } = props?.config;
  const { menuOrientation } = useConfig();
  const downLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && (
        <DrawerHeader open={true} />
      )}
      <Box sx={{ width: "100%", ml: 1 }} />
      <ISOCard />
      <Setting />
      <FullScreen />
      {userData?.externalUser ? null : <MegaMenuSection />}
      <Notification />
      <Message />
      <Profile />
    </>
  );
};

export default withRouter(HeaderContent);
