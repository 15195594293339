import { lazy } from "react";
import GuestGuard from "utils/route-guard/GuestGuard";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import { routeConstants } from "services/keyConstants";

const Login = Loadable(lazy(() => import("pages/Public/Login")));
const ResetPassword = Loadable(
  lazy(() => import("pages/Public/ResetPassword"))
);
const ForgotPassword = Loadable(
  lazy(() => import("pages/Public/ForgotPassword"))
);
const ReportListForExternalUser = Loadable(
  lazy(() => import("pages/Installation/ReportListForExternalUser"))
);
const GuestFeedback = Loadable(
  lazy(() => import("pages/Feedback/GuestFeedback"))
);
const GuestServiceRecordForm = Loadable(
  lazy(() => import("pages/CustomerService/GuestServiceRecordForm"))
);
const GuestOrderForm = Loadable(
  lazy(() => import("pages/CRPD/GuestOrderForm"))
);
const DistributorForm = Loadable(
  lazy(() => import("pages/CRPD/DistributorForm"))
);
const SupplierServiceProviderSelectionForm = Loadable(
  lazy(() => import("pages/Purchase/SupplierServiceProviderSelectionForm"))
);
const InstallationExternalFormat = Loadable(
  lazy(() => import("pages/Installation/InstallationExternalFormats"))
);
const ServiceForm = Loadable(
  lazy(() => import("pages/CustomerService/ServicingForm"))
);
const SupplierForm = Loadable(
  lazy(() => import("pages/NewPurhcase/SupplierForm"))
);

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "reset-password/:token",
          element: <ResetPassword />,
        },
        {
          path: "feedback-guest/:orgId/:id",
          element: <GuestFeedback />,
        },
        {
          path: "service-report/:orgId/:id",
          element: <GuestServiceRecordForm />,
        },
        {
          path: "order-form/:id/:orgId",
          element: <GuestOrderForm />,
        },
        {
          path: "distributor-form/:id/:orgId/:docId",
          element: <DistributorForm />,
        },
        {
          path: "purchase/supplier-service-form",
          element: <SupplierServiceProviderSelectionForm />,
        },
        {
          path: "installation-external-format",
          element: <InstallationExternalFormat />,
        },
        {
          path: `${routeConstants.CUSTOMER_SERVICE}/service-form/:orgId/:id`,
          element: <ServiceForm />,
        },
        {
          path: `supplier-form/:id/org-id/:orgId`,
          element: <SupplierForm />,
        },
        {
          path: `report-details/:orgId/:id/:productId`,
          element: <ReportListForExternalUser />,
        },
      ],
    },
  ],
};

export default LoginRoutes;
