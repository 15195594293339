import React, { useEffect, useRef, useState } from "react";
import { Button, Pagination, Box } from "@mui/material";
import MainCard from "components/MainCard";
import { Edit } from "iconsax-react";
import withRouter from "components/withRouter";
import * as url from "./utils/urlHelper";
import { Delete } from "@mui/icons-material";
import DeleteModal from "components/DeleteModal";
import { checkPermission, showToast } from "services/utils";
import Table from "components/Table";

const List = (props) => {
  const {
    ApiServices,
    type,
    moduleType,
    formFields,
    validationSchema,
    initialValues,
    tableColumns,
    Component,
    permissionData,
    AdminAccessConstants,
    permissionConstants,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalMode, setModalMode] = useState("add");

  const filterData = useRef({
    currentPage: 1,
    totalPage: 1,
    limit: 10,
  });

  const handlePageClick = (e, val) => {
    filterData.current.currentPage = val;
    getData();
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      let reqObj = {
        page: filterData.current?.currentPage,
        moduleType: moduleType,
      };

      const queryString = new URLSearchParams(reqObj).toString();
      let result = await ApiServices.callServiceGet(
        `${url.ADD_NEW_PROCEDURE}?${queryString}`
      );

      if (result?.meta?.success) {
        setData(result?.data);
        filterData.current.totalPage = result?.meta?.totalPages;
        filterData.current.limit = result?.meta?.limit;
        filterData.current.page = result?.meta?.page;
      } else {
        setData([]);
        filterData.current.page = 1;
        filterData.current.totalPage = 1;
        filterData.current.limit = 10;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModal = (mode = "add", item) => {
    setIsOpen(!isOpen);
    setModalMode(mode);
    if (mode === "edit") {
      window.dispatchEvent(
        new CustomEvent("editDynamicDocument", {
          detail: item,
        })
      );
    }
  };

  const handleDelete = async () => {
    let reqObj = {
      module: moduleType,
    };
    const queryString = new URLSearchParams(reqObj).toString();
    const result = await ApiServices.callServiceDelete(
      `${url.DELETE}${selectedData?._id}?${queryString}`
    );
    showToast(result);
    if (result?.meta?.success) {
      await getData();
    }
    handleDeleteModal();
  };

  // handler for delete modal
  const handleDeleteModal = (item) => {
    setIsOpenDelete(!isOpenDelete);
    if (isOpenDelete) {
      setSelectedData(null);
    } else {
      setSelectedData(item);
    }
    window.dispatchEvent(
      new CustomEvent("deleteData", {
        detail: { ...item, moduleType: moduleType },
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    ...(tableColumns ?? []),
    {
      header: "Actions",
      actions: [
        {
          tooltip: "Edit",
          icon: <Edit />,
          onClick: (item) => handleModal("edit", item),
          className: "edit-button",
          color: "success",
          disabled: !checkPermission(permissionData, AdminAccessConstants?.DYNAMIC_DOCUMENT, permissionConstants?.UPDATE)
        },
        {
          tooltip: "Delete",
          icon: <Delete />,
          onClick: (item) => handleDeleteModal(item),
          className: "delete-button",
          color: "error",
          disabled: !checkPermission(permissionData, AdminAccessConstants?.DYNAMIC_DOCUMENT, permissionConstants?.DELETE)
        },
      ],
    },
  ];

  return (
    <>
      <MainCard
        title={`${type} List`}
        secondary={
          <Button
            variant="contained"
            color="success"
            onClick={() => handleModal()}
            disabled={!checkPermission(permissionData, AdminAccessConstants?.DYNAMIC_DOCUMENT, permissionConstants?.CREATE)}
          >
            {`Add New ${type}`}
          </Button>
        }
      >
        <Table data={data} columns={columns} isLoading={isLoading} />

        {data?.length > 0 ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <Pagination
              count={filterData?.current?.totalPage ?? 1}
              page={filterData?.current?.currentPage ?? 1}
              onChange={(e, val) => handlePageClick(e, val)}
              color="primary"
              hidePrevButton
              hideNextButton
            />
          </Box>
        ) : null}

        {Component && (
          <Component
            getData={getData}
            type={type}
            moduleType={moduleType}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            modalMode={modalMode}
            setModalMode={setModalMode}
            validationSchema={validationSchema}
            initialValues={initialValues}
            formFields={formFields}
          />
        )}

        <DeleteModal
          open={isOpenDelete}
          handleModal={handleDeleteModal}
          getData={getData}
          handleDelete={handleDelete}
          field="name"
        />
      </MainCard>
    </>
  );
};

export default withRouter(List);
