import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import CommonYearList from "components/reusables/CommonYearList";
import * as url from "./urlHelper";

// Define lazy-loaded components
const Report = Loadable(lazy(() => import("../Report")));
const DynamicList = Loadable(lazy(() => import("../DynamicList")));
const View = Loadable(lazy(() => import("../View")));
const CommonRecord = Loadable(lazy(() => import("../CommonRecord")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const CONCPRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.CONTROL_OF_NON_CONFIRMING}`, null, [
      createRoute("report/:id/:childId", <Report />),
      createRoute("record-type/:id", <CommonRecord />),
      createRoute("list/:year", <DynamicList />),
      createRoute("view/:year/:id", <View />),
      createRoute(commonRoutesConstants.YEAR_LIST, <CommonYearList
        uri={url.GET_BY_YEAR}
        title={routeConstants.CONTROL_OF_NON_CONFIRMING}
        navigateToFolder={`/${routeConstants.CONTROL_OF_NON_CONFIRMING}/list/`}
      />),
    ]),
  ]
);

export default CONCPRoute;
