import { Modal, Typography } from "@mui/material";
import withRouter from "./withRouter";
import { Box } from "@mui/system";
import gir1 from "assets/images/welcomeImages/gif1.gif";
import gir2 from "assets/images/welcomeImages/gif2.gif";
import gir3 from "assets/images/welcomeImages/gif3.gif";
import back1 from "assets/images/back1.jpeg";
import MainCard from "./MainCard";
import { useEffect, useState } from "react";

const OnBoardingPage = (props) => {
    const { navigate } = props?.router
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/");
        }, 5000);

        return () => clearTimeout(timer); // Cleanup on unmount
    }, [navigate]);

    return (
        <>
            <MainCard>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ backdropFilter: "blur(5px)" }}
                    disableAutoFocus={true}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100vh",
                            backgroundImage: `url(${gir3}),url(${gir2})`,
                            backgroundSize: "10rem",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "bottom 0px left 0px,top 0px right 0px",
                        }}
                    >
                        <Box
                            sx={{
                                width: { lg: "50%", sm: "70%", xs: "80%" },
                                padding: "40px ",
                                backgroundImage: `url(${gir2}),url(${gir3})`,
                                transform: "translate(0px, 70%)",
                                margin: "auto auto",
                                backgroundSize: "10rem",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition:
                                    "top -3rem left -0.5rem, bottom -1.5rem right -0.5rem;",
                            }}
                        >
                            <Box
                                sx={{
                                    border: "1px solid #FFFFFF",
                                    color: "#4680FF",
                                    borderRadius: "10px",
                                    textAlign: "center",
                                    width: "90%",
                                    height: "30vh",
                                    margin: "auto",
                                    backgroundImage: `url(${back1})`,
                                    backgroundAttachment: "fixed",
                                    backgroundRepeat: "no-repeat",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="h2">🎉 Onboarding Complete!</Typography>
                                <Typography
                                    variant="h6"
                                    sx={{ width: "60%", textAlign: "center" }}
                                >
                                    Congrats! You’ve completed onboarding and are all set to start
                                    exploring
                                </Typography>
                                <img
                                    src={gir1}
                                    style={{
                                        position: "absolute",
                                        bottom: "-6.5rem",
                                        left: "-5rem",
                                    }}
                                />
                            </Box>
                        </Box>
                    </div>
                </Modal>
            </MainCard>
        </>
    );
};

export default withRouter(OnBoardingPage);