import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import CommonYearList from "components/reusables/CommonYearList";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import * as url from "./urlHelper";
// Define lazy-loaded components
const CalibrationPlan = Loadable(
  lazy(() => import("../CalibrationPlan"))
);

const CalibrationReport = Loadable(
  lazy(() => import("../ClibrationReport"))
);

const ReportList = Loadable(
  lazy(() => import("../ReportList"))
);

const ReportDetails = Loadable(
  lazy(() => import("../ReportDetails"))
);

const List = Loadable(
  lazy(() => import("../List"))
);

const ReportListAll = Loadable(
  lazy(() => import("../ReportListAll"))
);
// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const MAMEquipmentRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.MAM_OF_EQUIPMENT}`, null, [
      createRoute("calibration-plan/:year", <CalibrationPlan />),
      createRoute("list/:year", <List />),
      createRoute("calibration-report/:year", <CalibrationReport />),
      createRoute(
        commonRoutesConstants.YEAR_LIST,
        <CommonYearList
          uri={url.GET_BY_YEAR}
          title={routeConstants.MAM_OF_EQUIPMENT}
          navigateToFolder={`/${routeConstants.MAM_OF_EQUIPMENT}/list/`}
        />
      ),
      createRoute("calibration-report-detail/:type/:id", <ReportDetails />),
      createRoute("calibration-report-list/:type", <ReportList />),
      createRoute("report-list/:year", <ReportListAll />),
    ]),
  ]
);

export default MAMEquipmentRoutes;
