import React from "react";
import withRouter from "components/withRouter";
import DynamicTableForm from "./DynamicTableForm";
import DynamicTable from "components/reusables/DynamicTable";

const DynamicFormatStructure = (props) => {
  const {
    data,
    columns,
    setColumns,
    errors,
    setErrors,
    rows,
    setRows,
    options,
    isUpdate = false,
  } = props;

  if (isUpdate) {
    if (options?.structureType === "form-table") {
      return (
        <DynamicTableForm
          columns={columns}
          setColumns={setColumns}
          setErrors={setErrors}
          errors={errors}
          {...options}
        />
      );
    }
    if (options?.structureType === "table") {
      return (
        <DynamicTable
          columns={columns}
          setColumns={setColumns}
          setErrors={setErrors}
          errors={errors}
          rows={rows}
          setRows={setRows}
          {...options}
        />
      );
    }
  } else if (Array.isArray(data) && data.length === 1) {
    const structureType = data[0]?.structureType;
    if (structureType === "form-table") {
      return (
        <DynamicTableForm
          columns={columns}
          setColumns={setColumns}
          setErrors={setErrors}
          errors={errors}
          {...options}
        />
      );
    }
    if (structureType === "table") {
      return (
        <DynamicTable
          columns={columns}
          setColumns={setColumns}
          setErrors={setErrors}
          errors={errors}
          rows={rows}
          setRows={setRows}
          {...options}
        />
      );
    }
  } else {
    return data?.map((item, index) => {
      if (item?.structureType === "form-table") {
        return (
          <DynamicTableForm
            key={index}
            columns={columns}
            setColumns={setColumns}
            setErrors={setErrors}
            errors={errors}
            {...options}
          />
        );
      } else if (item?.structureType === "table") {
        return (
          <DynamicTable
            key={index}
            columns={columns}
            setColumns={setColumns}
            setErrors={setErrors}
            errors={errors}
            {...options}
          />
        );
      }
      return null; // Handle other cases explicitly
    });
  }
};
export default withRouter(DynamicFormatStructure);
