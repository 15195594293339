import { Button, Grid, TextField } from "@mui/material";
import MainCard from "components/MainCard";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getUserDataByDesignation } from "services/organogramServices";
import {
  extractUserIdAndName,
  formatDateWithYear,
  getDocumentLifeCycleForMasterDoc,
  removeItem,
  sendNotification,
  showToast,
} from "services/utils";
import * as url from "./utils/urlHelper";
import withRouter from "components/withRouter";
import CustomNotification from "components/CustomNotification";

const CustomerServivingForm = (props) => {
  const { params, navigate } = props?.router,
    {
      ApiServices,
      userData,
      Constants,
      TypeConstants,
      DepartmentConstants,
      MasterDocumentTypeConstants,
      FormatConstants,
      routeConstants,
    } = props;
  const { id } = params;
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const year = formatDateWithYear(new Date());
  const [customerService, setCustomerService] = useState({
    customerName: "",
    customerAddress: "",
    dateAndTimeEvent: "",
    deviceDetails: "",
    descriptionOfProblem: "",
    servicingAt: "",
    notification: false,
    makeDecision: false,
  });
  const [errors, setErrors] = useState({
    customerName: "",
    customerAddress: "",
    dateAndTimeEvent: "",
    deviceDetails: "",
    descriptionOfProblem: "",
    servicingAt: "",
    notification: "",
  });

  const handleChangeOfCustomerService = (e) => {
    const { name, value } = e?.target;
    setCustomerService({
      ...customerService,
      [name]: value,
    });
    // Clear error for this field
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear error for this specific field
      }));
    }
  };

  const validateCustomerService = (service) => {
    let validationErrors = {};
    if (!service?.customerName)
      validationErrors.customerName = "Customer name is required";
    if (!service?.customerAddress)
      validationErrors.customerAddress = "Customer address is required";
    if (!service?.dateAndTimeEvent)
      validationErrors.dateAndTimeEvent = "Event date is required";
    if (!service?.deviceDetails)
      validationErrors.deviceDetails = "Device details are required";
    if (!service?.descriptionOfProblem)
      validationErrors.descriptionOfProblem = "Problem description is required";
    if (!service?.servicingAt)
      validationErrors.servicingAt = "Servicing location is required";
    return validationErrors;
  };

  const handleSubmitOfCustomerService = async (selectedUser) => {
    const masterDocData = await getDocumentLifeCycleForMasterDoc(
      FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_FORM,
      MasterDocumentTypeConstants.FORMAT
    );
    const validationErrors = validateCustomerService(customerService);
    if (Object.keys(validationErrors)?.length > 0) {
      setErrors(validationErrors);
      return;
    }
    if (Object.keys(validationErrors)?.length > 0) return;
    try {
      let userDatas = await getUserDataByDesignation(
        [
          {
            type: TypeConstants.TYPE_FIND_HEAD,
            department: [DepartmentConstants.QUALITY_CONTROL_DEPARTMENT],
            ischeckCondition: true,
            masterDoc: [
              {
                documentName:
                  FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_FORM,
                documentType: MasterDocumentTypeConstants.RECORD,
              },
            ],
          },
        ],
        false
      );
      if (customerService?.notification === true) {
        setIsOpenNotification(true);
        return;
      }
      let reqObj = {
        masterDocId: masterDocData?._id,
        orgId: userData?.orgId,
        documentName: "Customer Servicing Details",
        document: {
          ...customerService,
          notificationUser: userDatas,
          ...(selectedUser && {
            selectedUser: extractUserIdAndName(selectedUser),
          }),
        },
      };

      let uri = url.POST_CUSTOMER_SERVICING;

      const response = await ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );

      let temp;

      if (!selectedUser) {
        temp = {
          recipients: [{ userId: extractUserIdAndName(selectedUser)?.userId }],
          msg: "Servicing is comming you are praprer for service record",
          status: "Please Fill the service record",
          location: `${routeConstants.CUSTOMER_SERVICE}/service-record/${response?.data?._id}`,
        };
      }

      if (response?.meta?.success) {
        if (customerService?.notification === false) {
          let notificationObj = {
            dataArray: [
              {
                recipients: userDatas?.map((user) => ({
                  userId: user?.userId,
                })),
                msg: "Servicing record has been received. Please make a decision.",
                location: `${routeConstants.CUSTOMER_SERVICE}/service-record-history/${year}`,
                status: "Servicing record has been received.",
              },
            ],
          };
          await sendNotification(notificationObj);
        } else {
          let notificationObj = {
            dataArray: [selectedUser !== null && temp],
          };
          await sendNotification(notificationObj);
        }
      }
      setTimeout(() => {
        navigate("/");
      }, 1000);

      showToast(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomerService = async (id) => {
    try {
      const uri = url.GET_BY_ID_CUSTOMER + id;
      const result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        let obj = { ...result?.data?.document, notification: true };
        setCustomerService(obj);
        removeItem(Constants.TEMPID);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    getCustomerService(id);
  }, []);

  return (
    <MainCard title="Customer Servicing Form">
      {isOpenNotification ? (
        <CustomNotification
          open={isOpenNotification}
          setOpen={setIsOpenNotification}
          isMultipleUser={false}
          handleSubmit={
            customerService?.notification === true
              ? handleSubmitOfCustomerService
              : null
          }
        />
      ) : null}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="customerName"
            name="customerName"
            label="Customer Name"
            value={customerService?.customerName}
            onChange={handleChangeOfCustomerService}
            error={!!errors?.customerName}
            helperText={errors?.customerName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="customerAddress"
            name="customerAddress"
            label="Customer Address"
            value={customerService?.customerAddress}
            onChange={handleChangeOfCustomerService}
            error={!!errors?.customerAddress}
            helperText={errors?.customerAddress}
          />
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
            <DatePicker
              fullWidth
              name="dateAndTimeEvent"
              label="Date And Time Event"
              format="YYYY-MM-DD"
              value={
                customerService?.dateAndTimeEvent
                  ? dayjs(customerService?.dateAndTimeEvent)
                  : null
              }
              onChange={(date) => {
                const selectedDate = date ? date.format("YYYY-MM-DD") : "";
                handleChangeOfCustomerService({
                  target: { name: "dateAndTimeEvent", value: selectedDate },
                });
              }}
              componentsProps={{
                textField: {
                  error: !!errors?.dateAndTimeEvent, // Check if there's an error for the date
                  helperText: errors?.dateAndTimeEvent || "", // Show the error message if any
                  sx: {
                    width: "100%",
                    ...(errors?.dateAndTimeEvent
                      ? { borderColor: "red", color: "red" } // Custom error styles for date field
                      : {}),
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="deviceDetails"
            name="deviceDetails"
            label="Device Details"
            value={customerService?.deviceDetails}
            onChange={handleChangeOfCustomerService}
            error={!!errors?.deviceDetails}
            helperText={errors?.deviceDetails}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="descriptionOfProblem"
            name="descriptionOfProblem"
            label="Description of Problem"
            value={customerService?.descriptionOfProblem}
            onChange={handleChangeOfCustomerService}
            error={!!errors?.descriptionOfProblem}
            helperText={errors?.descriptionOfProblem}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="servicingAt"
            name="servicingAt"
            label="Servicing At"
            value={customerService?.servicingAt}
            onChange={handleChangeOfCustomerService}
            error={!!errors?.servicingAt}
            helperText={errors?.servicingAt}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleSubmitOfCustomerService}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default withRouter(CustomerServivingForm);
