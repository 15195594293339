import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import {
  Badge,
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  Snackbar,
  Skeleton,
  Alert,
  Button,
  DialogActions,
  Grid,
  DialogContent,
} from "@mui/material";
import MainCard from "components/MainCard";
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";
import { TickCircle, Trash } from "iconsax-react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotificationStyle from "components/NotificationStyle";
import { format, isValid } from "date-fns";
import { setItem } from "services/utils";
import withRouter from "components/withRouter";
import { ApiServices } from "services/apiServices";
import CustomDialog from "components/reusables/CustomDialog";
import { NotificationSVG } from "assets/Icons/HeaderContentSVGs";
import { bgcolor, borderRadius } from "@mui/system";
import notificationPng from "assets/images/notification/notification.png";
import {
  SuccessSVG,
  WarningSVG,
  RoleSVG,
  InProcessSVG,
  ApprovalSVG,
  WaitingSVG,
  PendingSVG,
  CompletedSVG,
  DelaySVG,
} from "assets/SVGs/notification";

const Arrow = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.9375 13.2787L10.2842 8.93208C10.7975 8.41875 10.7975 7.57875 10.2842 7.06542L5.9375 2.71875"
      stroke="#4680FF"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Delete = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0036 6.72656C20.9836 6.72656 20.9536 6.72656 20.9236 6.72656C15.6336 6.19656 10.3536 5.99656 5.12358 6.52656L3.08358 6.72656C2.66358 6.76656 2.29358 6.46656 2.25358 6.04656C2.21358 5.62656 2.51358 5.26656 2.92358 5.22656L4.96358 5.02656C10.2836 4.48656 15.6736 4.69656 21.0736 5.22656C21.4836 5.26656 21.7836 5.63656 21.7436 6.04656C21.7136 6.43656 21.3836 6.72656 21.0036 6.72656Z"
      fill="#E76767"
    />
    <path
      d="M8.50074 5.72C8.46074 5.72 8.42074 5.72 8.37074 5.71C7.97074 5.64 7.69074 5.25 7.76074 4.85L7.98074 3.54C8.14074 2.58 8.36074 1.25 10.6907 1.25H13.3107C15.6507 1.25 15.8707 2.63 16.0207 3.55L16.2407 4.85C16.3107 5.26 16.0307 5.65 15.6307 5.71C15.2207 5.78 14.8307 5.5 14.7707 5.1L14.5507 3.8C14.4107 2.93 14.3807 2.76 13.3207 2.76H10.7007C9.64074 2.76 9.62074 2.9 9.47074 3.79L9.24074 5.09C9.18074 5.46 8.86074 5.72 8.50074 5.72Z"
      fill="#E76767"
    />
    <path
      d="M15.2104 22.7515H8.79039C5.30039 22.7515 5.16039 20.8215 5.05039 19.2615L4.40039 9.19154C4.37039 8.78154 4.69039 8.42154 5.10039 8.39154C5.52039 8.37154 5.87039 8.68154 5.90039 9.09154L6.55039 19.1615C6.66039 20.6815 6.70039 21.2515 8.79039 21.2515H15.2104C17.3104 21.2515 17.3504 20.6815 17.4504 19.1615L18.1004 9.09154C18.1304 8.68154 18.4904 8.37154 18.9004 8.39154C19.3104 8.42154 19.6304 8.77154 19.6004 9.19154L18.9504 19.2615C18.8404 20.8215 18.7004 22.7515 15.2104 22.7515Z"
      fill="#E76767"
    />
    <path
      d="M13.6581 17.25H10.3281C9.91813 17.25 9.57812 16.91 9.57812 16.5C9.57812 16.09 9.91813 15.75 10.3281 15.75H13.6581C14.0681 15.75 14.4081 16.09 14.4081 16.5C14.4081 16.91 14.0681 17.25 13.6581 17.25Z"
      fill="#E76767"
    />
    <path
      d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z"
      fill="#E76767"
    />
  </svg>
);

const NotificationPage = (props) => {
  const { theme, service, userData, Constants } = props,
    { navigate } = props?.router,
    { ThemeMode } = props?.config;
  const [isLoading, setIsLoading] = useState(true);
  const anchorRef = useRef(null);
  const socketRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [tempNotification, setTempNotification] = useState(null);
  const [hoveredNotification, setHoveredNotification] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const user = userData;

  const formatDate = (timestamp) => {
    const date = timestamp instanceof Date ? timestamp : new Date(timestamp);
    const dayName = date.toLocaleDateString("en-US", { weekday: "long" });

    if (!isValid(date)) {
      return { time: "Invalid date", formattedDate: "Invalid date" };
    }
    return {
      time: format(date, "hh:mm a"),
      formattedDate: format(date, "do MMM"),
    };
  };

  const handleNotification = async () => {
    setIsLoading(true);
    try {
      const reqObj = { clientUserId: user?._id };
      let uri = props?.commonUrl?.GET_NOTIFICATIONS;
      let result = await props?.ApiServices.callServicePostWithBodyData(
        uri,
        reqObj
      );
      if (result?.success) {
        setNotifications(result?.notifications?.notifications?.reverse());
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const countMarkedCompleteFalse = notifications?.reduce((acc, obj) => {
    if (!obj.markedComplete) {
      acc++;
    }
    return acc;
  }, 0);

  useEffect(() => {
    handleNotification();

    if (socketRef.current) {
      socketRef.current.off("new_msg");
      socketRef.current.off("disconnect");
      socketRef.current.off("reconnect");
      socketRef.current.close();
    }

    const socket = io(service.IMG_URL, {
      transports: ["websocket", "polling"],
      reconnection: true,
      secure: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 2000,
    });

    socketRef.current = socket;

    const userId = user?._id;

    const joinRoom = () => {
      socket.emit("join", { id: userId });
      console.log(`Joining room with ID: ${userId}`);
    };

    joinRoom(); // Join room initially

    socket.on("new_msg", (data) => {
      if (
        data?.title === "New Permissions Assign" ||
        data?.title === "Permissions Deleted" ||
        data?.title === "Permissions Updated" ||
        data?.title === "Other Permissions Assign"
      ) {
        setPermissions();
      }

      getChangeNoteById(data?._id);
      const newNotification = {
        title: data?.title,
        message: data?.message,
        timestamp: new Date(),
        location: data?.location,
        _id: data?._id,
      };

      setTempNotification(newNotification);
      setNotifications((prevNotifications) => [
        newNotification,
        ...prevNotifications,
      ]);
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected. Attempting to reconnect...");
      joinRoom(); // Rejoin the room after reconnection
    });

    socket.on("reconnect", () => {
      console.log("Socket reconnected successfully.");
      joinRoom(); // Rejoin the room after reconnection
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.off("new_msg");
        socketRef.current.off("disconnect");
        socketRef.current.off("reconnect");
        socketRef.current.close();
      }
    };
  }, [user?._id, service.IMG_URL]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const setPermissions = async () => {
    try {
      const reqObj = {
        clientUserId: userData?._id,
      };

      const uri = "/role/get-permission-list";
      const result = await ApiServices.callServicePostWithBodyData(uri, reqObj);

      if (result?.success) {
        setItem(Constants.PERMISSIONDATA, JSON.stringify(result?.data));
      } else {
        console.error("Failed to fetch permissions:", result?.message);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "secondary.200" : "secondary.200";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK
      ? "background.default"
      : "secondary.100";

  const actionSX = {
    ml: 1,
    top: "auto",
    right: "auto",
    alignSelf: "flex-start",
    transform: "none",
  };

  const handleNotificationPage = (notification) => {
    if (notification?.metaInfo?.isThroughState) {
      navigate(notification?.location, notification?.metaInfo?.state);
      return;
    }
    if (notification?.metaInfo?.openModal) {
      if (notification?.metaInfo?.id) {
        setItem(Constants.TEMPID, notification?.metaInfo?.id);
      } else if (notification?.metaInfo?.isMultiId) {
        setItem(Constants.TEMPID, notification?.metaInfo?.multipleIds);
      }
      window.dispatchEvent(new Event(`${notification?.metaInfo?.modalName}`));
      return;
    }
    if (notification?.location === "changeNoteHistory") {
      navigate(notification?.location, {
        state: {
          changeNoteId: notification?._id,
        },
      });
      getChangeNoteById(notification?._id);
    } else if (notification?.location === "feedback") {
      navigate(`/${notification?.location}/${notification?._id}`);
    } else {
      navigate(notification?.location, {
        state: {
          travelId: notification?._id,
        },
      });
    }
  };

  const getChangeNoteById = async (changeNoteId) => {
    if (changeNoteId?.length > 0 && changeNoteId !== null) {
      try {
        let uri = props?.commonUrl?.GET_CHANGE_NOTE_BY_ID + changeNoteId;
        let result = await props?.ApiServices.callServiceGet(uri);
        if (result?.result?.type === "success") {
          if (
            result?.result?.data?.changeNoteData?.currentStatus ===
              "Document Approval" ||
            result?.result?.data?.changeNoteData?.currentStatus ===
              "Change request note approved"
          ) {
            localStorage.removeItem(Constants.CHANGENOTEDATA);
          } else {
            let changeNoteObj = result?.result?.data?.changeNoteData;
            changeNoteObj.changeNoteId = result?.result?.data?._id;
            setItem(Constants.CHANGENOTEDATA, JSON.stringify(changeNoteObj));
          }
        }
      } catch (error) {
        console.error("GET_CHANGE_NOTE_BY_ID API", error);
      }
    }
  };

  const avatarBorderColors = {
    Submitted: "#12372A",
    Approved: "#820300",
    "Requested Approval": "blue",
    "Requested Review": "black",
    "Requested Initiated": "#2f3645",
  };

  const ListItemButtonTypes = {
    Submitted: {
      backgroundColor:
        theme.palette.mode === "dark" ? "#1e5d1e75" : "#c5ff9b8a",
      hoverColor: "#b3d69e",
      icon: (
        <TickCircle
          style={{
            color: theme.palette.mode === "dark" ? "#07d400" : "#00b500",
          }}
          variant="Bold"
          size="20"
        />
      ),
    },
    Approved: {
      backgroundColor:
        theme.palette.mode === "dark" ? "#1d1da64a" : "#dce7ffb3",
      hoverColor: "#e4c6c0",
      icon: (
        <CheckCircleIcon
          sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#00b500" }}
          variant="Bold"
          fontSize="large"
        />
      ),
    },
  };

  const handleMouseEnter = (notification) => {
    setHoveredNotification(notification);
  };

  const handleMouseLeave = () => {
    setHoveredNotification(null);
  };

  const handleDeleteAll = async () => {
    let uri = props?.commonUrl?.Delete_All_Notifications;
    let result = await props?.ApiServices.callServiceDelete(uri);
    if (result) {
      setNotifications([]);
    }
  };

  const DeleteOneNotification = async (uniqueId) => {
    let uri = props?.commonUrl?.Delete_Notification_By_Id + uniqueId;
    let result = await props?.ApiServices.callServiceDelete(uri);
    if (result?.success) {
      handleNotification();
      setOpenModal(true);
      navigate(-1);
    }
  };
  const category = [
    {
      title: "Warning",
      headNames: ["evidence", "Required"],
      color: "rgba(220, 38, 38, 0.07)",
      svg: WarningSVG,
      clock: "#DC2626",
    },
    {
      title: "Success",
      headNames: [
        "proceeding",
        "Review",
        "Submitted",
        "successfully",
        "Received",
        "Complaint",
        "Approved",
      ],
      color: "rgba(44, 168, 127, 0.07)",
      svg: SuccessSVG,
      clock: "#2CA87F",
    },
    {
      title: "InProcess",
      headNames: ["Process", "Inspection", "Planned"],
      color: "rgba(255, 165, 0, 0.07)",
      svg: InProcessSVG,
      clock: "#F99D00",
    },
    {
      title: "Approval",
      headNames: ["approve", "approval", "Approver"],
      color: "rgba(70, 128, 255, 0.07)",
      svg: ApprovalSVG,
      clock: "#4680FF",
    },
    {
      title: "Waiting",
      headNames: ["Request", "received", "review", "evidence"],
      color: "rgba(48, 176, 199, 0.07)",
      svg: WaitingSVG,
      clock: "#30B0C7",
    },
    {
      title: "Delay",
      headNames: ["closed"],
      color: "rgba(128, 128, 128, 0.07)",
      svg: DelaySVG,
      clock: "black",
    },
    {
      title: "Pending",
      headNames: ["assigned", "Saved"],
      color: "rgba(159, 162, 170, 0.07)",
      svg: PendingSVG,
      clock: "#9FA2AA",
    },
    {
      title: "Completed",
      headNames: ["Responsibility", "Signature", "signed"],
      color: "rgba(60, 0, 88, 0.07)",
      svg: CompletedSVG,
      clock: "#3C0058",
    },
    {
      title: "Role",
      headNames: ["Role"],
      color: "rgba(220, 38, 38, 0.05)",
      svg: RoleSVG,
      clock: "#964B00",
    },
  ];

  const getCategoryForTitle = (title) => {
    return category.find((cat) =>
      cat.headNames.some((name) =>
        title?.toLowerCase().includes(name.toLowerCase())
      )
    );
  };

  const getBackgroundStyle = (title) => {
    const matchedCategory = getCategoryForTitle(title);
    return matchedCategory
      ? `radial-gradient(circle at top left,${matchedCategory.color} 15%, transparent 40%), radial-gradient(circle at bottom left, rgb(255, 255, 255) 5%, transparent 20%)`
      : "white";
  };

  const getSvgIcon = (title) => {
    const matchedCategory = getCategoryForTitle(title);
    return matchedCategory?.svg ? matchedCategory.svg() : null;
  };
  const clock = (title) => {
    const matchedCategory = getCategoryForTitle(title);
    return matchedCategory?.clock ? matchedCategory.clock : null;
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.5 }}>
      <IconButton
        color="secondary"
        variant="light"
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        size="medium"
        sx={{
          color: "secondary.main",
          p: 1,
          borderRadius: "100px",
          bgcolor: "#ffffff",
        }}
      >
        <Badge
          badgeContent={countMarkedCompleteFalse ?? 0}
          color="success"
          sx={{ "& .MuiBadge-badge": { top: 2, right: 4 } }}
        >
          <NotificationSVG />
        </Badge>
      </IconButton>
      <Popper
        sx={{
          boxShadow:
            theme.palette.mode === ThemeMode.DARK
              ? "rgb(222, 222, 222) 0px 9px 32px -38px"
              : "rgba(222, 222, 222, 0.53) 0px 0px 35px 2px",
          borderRadius: 1.5,
          overflow: "hidden",
          border: "1px solid #dedede1a",
        }}
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [50, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position="top-right"
            sx={{ overflow: "hidden" }}
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                borderRadius: 1.5,
                width: isLoading ? "520px" : "100%",
                width: 520,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  elevation={0}
                  border={false}
                  sx={{
                    "& .MuiCardContent-root": {
                      padding: 0,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      borderRadius: "20px",
                      margin: "10px",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "12px 20px",
                      backgroundColor: "aliceblue",
                    }}
                  >
                    <Box>
                      <Typography variant="h5">Notifications</Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Button
                        onClick={(e) => {
                          handleClose(e);
                          navigate("/notification");
                        }}
                      >
                        View All
                        <Arrow />
                      </Button>
                      <Button
                        onClick={() => {
                          setOpenModal(true);
                        }}
                      >
                        Clear all
                      </Button>
                    </Box>
                  </Box>
                  {isLoading ? (
                    <List
                      component="nav"
                      sx={{
                        "& .MuiListItemButton-root": {
                          p: 1.5,
                          my: 1.5,
                          border: `1px solid ${theme.palette.divider}`,
                        },
                      }}
                    >
                      {Array.from(new Array(3)).map((_, index) => (
                        <ListItemButton key={index}>
                          <ListItemAvatar>
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Skeleton
                                variant="text"
                                width="60%"
                                height={20}
                              />
                            }
                            secondary={
                              <Skeleton
                                variant="text"
                                width="40%"
                                height={16}
                              />
                            }
                          />
                          <ListItemSecondaryAction>
                            <Skeleton variant="text" width={40} height={16} />
                            <Skeleton variant="text" width={60} height={16} />
                          </ListItemSecondaryAction>
                        </ListItemButton>
                      ))}
                    </List>
                  ) : notifications?.length === 0 ? (
                    <Box>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <img src={notificationPng} />
                      </Box>
                      <Typography variant="h1" sx={{ textAlign: "center" }}>
                        Opps! No Notification Yet
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <List
                        component="nav"
                        sx={{
                          margin: "10px",
                          maxHeight: 300,
                          overflowY: "scroll",
                          scrollbarWidth: "none",

                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                          },

                          "& .MuiListItemButton-root": {
                            p: 1.5,
                            "& .MuiListItemSecondaryAction-root": {
                              ...actionSX,
                              position: "relative",
                            },
                          },
                        }}
                      >
                        {notifications?.map((notification, index) => {
                          const type =
                            ListItemButtonTypes[notification.title] || {};
                          const { backgroundColor, hoverColor, icon } = type;
                          const borderColor =
                            avatarBorderColors[notification.title] || "#000";
                          const { time } = formatDate(notification.timestamp);
                          return (
                            <>
                              <ListItemButton
                                sx={{
                                  border: "2px solid white",
                                  padding: "10px !important",
                                  borderRadius: "20px",
                                  position: "relative",
                                  transition: "0.3s",
                                  background: getBackgroundStyle(
                                    notification?.title
                                  ),
                                  "&:hover": {
                                    background: "#E7E8EA",
                                    ".hover-buttons": {
                                      opacity: 1,
                                      visibility: "visible",
                                      display: "flex",
                                    },
                                  },

                                  ".hover-buttons": {
                                    display: "none",
                                  },
                                }}
                                key={index}
                                onClick={() => {
                                  handleNotificationPage(notification);
                                  setPermissions(notification);
                                }}
                                onMouseEnter={() =>
                                  handleMouseEnter(notification)
                                }
                                onMouseLeave={handleMouseLeave}
                              >
                                <Box
                                  sx={{
                                    position: "relative",
                                    textAlign: "top",
                                    top: "0",
                                    alignSelf: "flex-start",
                                    padding: "19px 10px",
                                  }}
                                  color="danger"
                                  variant="rounded"
                                >
                                  {getSvgIcon(notification?.title) || (
                                    <TickCircle />
                                  )}
                                </Box>
                                <ListItemText
                                  sx={{ marginTop: "0px" }}
                                  primary={
                                    <Box>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{
                                          fontWeight: "medium",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {notification.title}
                                      </Typography>
                                    </Box>
                                  }
                                  secondary={
                                    <Box>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color: "text.secondary",
                                          marginRight: "8px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}
                                        level="body-sm"
                                        noWrap
                                      >
                                        {notification.message}
                                      </Typography>
                                      {hoveredNotification === notification ? (
                                        <IconButton
                                          color="error"
                                          onClick={() => [
                                            DeleteOneNotification(
                                              notification.uniqueId
                                            ),
                                            setOpenModal(true),
                                          ]}
                                          sx={{
                                            opacity: 1,
                                            transition:
                                              "opacity 0.3s ease-in-out",
                                            position: "absolute",
                                            right: "20px",
                                            bottom: "10px",
                                          }}
                                        >
                                          <Delete />
                                        </IconButton>
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <Typography
                                    variant="caption"
                                    noWrap
                                    color="secondary"
                                    sx={{
                                      display: "block",
                                    }}
                                  >
                                    {time} &nbsp;
                                    {new Date(
                                      notification.timestamp
                                    ).toLocaleDateString("en-US", {
                                      weekday: "long",
                                    })}
                                  </Typography>
                                </ListItemSecondaryAction>
                              </ListItemButton>
                            </>
                          );
                        })}
                      </List>
                      <Box
                        sx={{
                          borderColor:
                            theme.palette.mode === "dark"
                              ? "rgba(248, 249, 250, 0.05)"
                              : "rgba(219, 224, 229, 0.65)",
                          textAlign: "center",
                        }}
                      ></Box>
                    </>
                  )}
                </MainCard>
              </ClickAwayListener>
              <CustomDialog
                open={openModal}
                onClose={() => {
                  setOpenModal(false);
                }}
                maxWidth="xs"
              >
                <DialogContent>
                  <Typography sx={{ textAlign: "center" }}>
                    Are you sure want to delete All Notification 🤔 ?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ margin: "auto", paddingBottom: "10px" }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ marginRight: 2, borderRadius: "60px" }}
                      onClick={() => setOpenModal(false)}
                    >
                      No Thanks
                    </Button>
                    <Button
                      sx={{ borderRadius: "60px" }}
                      variant="contained"
                      color="success"
                      onClick={() => handleDeleteAll()}
                    >
                      For Sure
                    </Button>
                  </Grid>
                </DialogActions>
              </CustomDialog>
            </Paper>
          </Transitions>
        )}
      </Popper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{
            width: "100%",
            bgcolor: "#8B0000",
            color: "#FFFFFF",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {tempNotification && (
        <NotificationStyle notification={tempNotification} />
      )}
    </Box>
  );
};

export default withRouter(NotificationPage);
