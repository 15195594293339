import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import * as url from "./urlHelper"
import CommonYearList from "components/reusables/CommonYearList";
import { handleCreate } from "./service";

// Define lazy-loaded components
const List = Loadable(lazy(() => import("../List")));
const Table = Loadable(lazy(() => import("../Table")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const ControlOfDocumentRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.CONTROL_OF_DOCUMENT, null, [
      createRoute(":id", <Table />),
      createRoute("list/:year", <List />),
      createRoute(commonRoutesConstants.YEAR_LIST, <CommonYearList
        uri={url.GET_BY_YEAR}
        title={routeConstants.CONTROL_OF_DOCUMENT}
        isCreate={true}
        createRoute={{
          callExtraApi: handleCreate
        }} 
        navigateToFolder={`/${routeConstants.CONTROL_OF_DOCUMENT}/list/`}
      />),
    ]),
  ]
);

export default ControlOfDocumentRoute;
