let baseUrl = "/finished-product-register";

export const GET_BY_ID = `${baseUrl}/`;

export const ADD_DATA = `${baseUrl}/`;

export const GET_BY_ID_FROM_PRODUCT = `/product/`;
export const DOWNLOAD = `${baseUrl}/download`;

export const GET_PRODUCT_DATA = `/product/product-data/`;

export const GET_SPECIFIC_BY_IDS = `${baseUrl}/get-specific-by-ids`;

export const GET_BY_FILTER = `${baseUrl}/get-by-filter`;
