import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import withRouter from "components/withRouter";

const ProcessListTable = (props) => {
  const { data } = props;
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sr. No.</TableCell>
            <TableCell>Process Name</TableCell>
            <TableCell>Manufacuting Quantity</TableCell>
            <TableCell>Accepted Quantity</TableCell>
            <TableCell>Non-Confirming Quantity</TableCell>
            <TableCell>Rejected Quantity</TableCell>
            <TableCell>Reworked Quantity</TableCell>
            <TableCell>Balance Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length > 0 ? (
            data.map((process, index) => (
              <TableRow key={process?._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{process?.processName}</TableCell>
                <TableCell>{process?.manufacuringQnty ?? 0}</TableCell>
                <TableCell>{process?.acceptedQnty ?? 0}</TableCell>
                <TableCell>{process?.nonConformingQnty ?? 0}</TableCell>
                <TableCell>{process?.rejectedQnty ?? 0}</TableCell>
                <TableCell>{process?.reworkedQnty ?? 0}</TableCell>
                <TableCell>{process?.balanceQnty ?? 0}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No data found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withRouter(ProcessListTable);
