import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as url from '../../../src/services/url_helper'
import { getItem } from 'services/utils';
import { ApiServices } from 'services/apiServices';


const initialState = {
    currentStep: null,
    subSteps: null
};

export const fetchCurrentStep = createAsyncThunk(
    'onBoarding/fetchCurrentStep',
    async (_, { rejectWithValue }) => {
        try {
            const userData = getItem('user_data')
            const uri = url.GET_USERS + userData?._id
            const result = await ApiServices.callServiceGet(uri)
            if (result?.response) {
                return { onBoardingStage: result?.result?.data?.tourSteps?.onBoardingStage, subSteps: result?.result?.data?.tourSteps?.subSteps } ?? { onBoardingStage: 0, subStep: 0 }
            } else {
                return { onBoardingStage: 0, subStep: 0 }
            }
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const onBoard = createSlice({
    name: 'onBoarding',
    initialState,
    reducers: {
        changeCurrentStep(state, action) {
            if (state.currentStep > action.payload.currentStep) { return }
            state.currentStep = action.payload.currentStep
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrentStep.fulfilled, (state, action) => {
                state.currentStep = action.payload.onBoardingStage;
                state.subSteps = action.payload.subSteps;
            })
            .addCase(fetchCurrentStep.rejected, (state, action) => {
                console.error("Failed to fetch onboarding step:", action.payload);
            });
    },
})

export default onBoard.reducer

export const { changeCurrentStep } = onBoard.actions
