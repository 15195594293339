import MainCard from 'components/MainCard'
import withRouter from 'components/withRouter'
import React, { useEffect, useState } from 'react'
import { ApiServices } from 'services/apiServices'

const GroupedReportList = (props) => {
    const { } = props, { params, navigate } = props?.router
    const { id } = params
    const [data, setData] = useState([])

    const getData = async () => {
        try {
            const uri = url.GET_GROUPED_DATA_BY_PRODUCT
            const result = await ApiServices.callServiceGet(uri)
            if (result?.meta?.success) {
                setData()
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <MainCard title="">

        </MainCard>
    )
}

export default withRouter(GroupedReportList)
