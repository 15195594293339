import React from "react";
import {
  Box,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { SearchNormal1 } from "iconsax-react";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import withRouter from "components/withRouter";

const SearchAndViewToggle = (props) => {
  const {
    placeholder = "Search...",
    isMultipleView = false,
    filter,
    setFilter,
    width,
  } = props;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  const handleViewModeChange = (_, newView) => {
    if (newView !== null) {
      setFilter((prev) => ({ ...prev, viewMode: newView }));
    }
  };

  return (
    <Box sx={{ width: "100%" }} mb={2}>
      <Grid container spacing={2}>
        {/* Search Field */}
        <Grid item xs={width ?? 12} sm={width ?? 8} md={width ?? 4}>
          <FormControl fullWidth>
            <OutlinedInput
              id="search-bar"
              startAdornment={
                <InputAdornment position="start" sx={{ mr: -0.5 }}>
                  <SearchNormal1 size={16} />
                </InputAdornment>
              }
              name="search"
              aria-describedby="search-bar-text"
              inputProps={{ "aria-label": "search" }}
              placeholder={placeholder}
              fullWidth
              sx={{ "& .MuiOutlinedInput-input": { p: 1.5 } }}
              value={filter?.search}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>

        {/* View Toggle */}
        {isMultipleView ? (
          <Grid
            item
            xs={12}
            sm={4}
            md={8}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <ToggleButtonGroup
              value={filter?.viewMode}
              exclusive
              onChange={handleViewModeChange}
              aria-label="view mode"
            >
              <ToggleButton value="grid" aria-label="grid view">
                <GridViewIcon />
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default withRouter(SearchAndViewToggle);
