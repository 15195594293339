import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, FormatConstants, routeConstants } from "services/keyConstants";
import * as url from "./urlHelper"
import CommonYearList from "components/reusables/CommonYearList";
// Define lazy-loaded components
const InternalCommunication = Loadable(
  lazy(() => import("../InternalCommunication"))
);
const InternalCommunicationById = Loadable(
  lazy(() => import("../InternalCommunicationById"))
);
const List = Loadable(lazy(() => import("../List")));
const Add = Loadable(lazy(() => import("../molecules/Add")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// Define routes using lazy-loaded components
const InternalCommunicationRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.INTERNAL_COMMUNICATION, null, [
      createRoute("view/:year", <InternalCommunication />),
      createRoute("create", <Add />),
      createRoute(":id", <InternalCommunicationById />),
      createRoute("list/:year", <List />),
      createRoute(commonRoutesConstants.YEAR_LIST, <CommonYearList
        uri={url.GET_BY_YEAR}
        title={routeConstants.INTERNAL_COMMUNICATION}
        isCreate={true}
        createFormatConstantsPermission={FormatConstants.INTERNAL_COMMUNICATION.INTERNAL_CIRCULAR}
        createRoute={{
          path: `/${routeConstants.INTERNAL_COMMUNICATION}/create`,
        }}
        navigateToFolder={`/${routeConstants.INTERNAL_COMMUNICATION}/list/`}
      />),
    ]),
  ]
);

export default InternalCommunicationRoute;
