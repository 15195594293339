import { commonRoutesConstants, routeConstants } from "services/keyConstants";

const baseUrl = `/${routeConstants.CONTROL_OF_NON_CONFIRMING}`;

export const CREATE = `${baseUrl}/`;
export const GET_BY_ID = `${baseUrl}/`;

export const UPDATE_SPECIFIC_DATA = `${baseUrl}/update-specific-data`;

export const GET_BY_YEAR = `${baseUrl}/${commonRoutesConstants.YEAR_LIST}`;

export const DOWNLOAD = `${baseUrl}/download`;

export const GET_ALL_FORMATS = `${baseUrl}/get-master-docs`;

export const GET_FILTERED_DOCS = `${baseUrl}/get-filtered-docs`;

export const GET_BY_FILTERS = `${baseUrl}/get-by-filters`;

export const DOWNLOAD_REGISTER = `${baseUrl}/download-register`;
