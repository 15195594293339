import React, { lazy } from "react";

// project-imports
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { commonRoutesConstants, routeConstants } from "services/keyConstants";
import CreateInstallationFormat from "../CreateInstallationFormat";
import CommonYearList from "components/reusables/CommonYearList";
import { getData } from "./service";
import * as url from "./UrlHelper";
import DeviceList from "../DeviceList";
import ReportList from "../ReportList";
import GroupedReportList from "../GroupedReportList";
import ProductReportList from "../ProductReportList";
import ReportDetails from "../ReportDetails";

// render - pages
const InstallationChecklist = Loadable(
  lazy(() => import("../InstallationChecklist"))
);
const List = Loadable(lazy(() => import("../List")));
const YearList = Loadable(lazy(() => import("../YearList")));
const NotifyForInstallationHead = Loadable(
  lazy(() => import("../NotifyForInstallationHead"))
);
const InstallationChildFormats = Loadable(
  lazy(() => import("../InstallationChildFormats"))
);
const InstallationFormat = Loadable(
  lazy(() => import("../InstallationFormat"))
);
const InstallationReport = Loadable(
  lazy(() => import("../InstallationReport"))
);

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });

// ==============================|| AUTH ROUTES ||============================== //

const InstallationRoutes = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(`${routeConstants.INSTALLATION}`, null, [
      createRoute("installation-checklist", <InstallationChecklist />),
      createRoute(
        "notification-for-installation-head",
        <NotifyForInstallationHead />
      ),
      createRoute("installation-format/:year", <InstallationFormat />),
      createRoute("installation-childFormats", <InstallationChildFormats />),
      createRoute("installation-report", <InstallationReport />),
      createRoute(commonRoutesConstants.YEAR_LIST, <CommonYearList
        uri={url.GET_BY_YEAR}
        title={routeConstants.INSTALLATION}
        isRenderComponent={() =>
          React.createElement(CreateInstallationFormat, {
            getData,
          })}
        navigateToFolder={`/${routeConstants.INSTALLATION}/installation-format/`}
      />),
      // createRoute("device-list", <DeviceList />),
      createRoute("report-list", <ReportList />),
      createRoute("report-list/:id", <ProductReportList />),
      createRoute("list/:id/:productId", <List />),
      createRoute("report/:id", <ReportDetails />),
    ]),
  ]
);

export default InstallationRoutes;
