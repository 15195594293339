import { routeConstants, commonRoutesConstants } from "services/keyConstants";

const baseUrl = `/${routeConstants.COD}`;

export const CREATE = `${baseUrl}/`;
export const GET_ALL_DATA_BY_TYPE = `${baseUrl}/get-dynamic`;
export const GET_BY_ID = `${baseUrl}/`;

export const GET_BY_YEAR = `${baseUrl}/${commonRoutesConstants.YEAR_LIST}`;

export const GET_DYNAMIC_DOCS = `${baseUrl}/get-dynamic-docs`;

export const UPDATE = `${baseUrl}/`;
export const UPDATE_SPECIFIC = `${baseUrl}/update-specific/`;

export const DOWNLOAD = `${baseUrl}/download`;
