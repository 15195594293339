import MainCard from "components/MainCard";
import withRouter from "components/withRouter";
import React, { useEffect, useState } from "react";
import * as url from "./utils/UrlHelper";
import DynamicTableForm from "components/reusables/DynamicTableForm";
import { Button, FormControl, Stack, TextField } from "@mui/material";
import DynamicTable from "components/reusables/DynamicTable";
import Error404 from "components/reusables/404";
import { ApiServices } from "services/apiServices";
import {
  downloadFormat,
  showToast,
  validateColumns,
  validationDynamicTable,
} from "services/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const ReportDetails = (props) => {
  const { userData, selectedRole, onChangeDownloadState } = props,
    { params } = props?.router;
  const { id } = params;
  const [data, setData] = useState({});
  const [parameterCheckedColumns, setParameterCheckedColumns] = useState([]);
  const [deviceInfoColumns, setDeviceInfoColumns] = useState([]);
  const [parametersErrors, setParametersErrors] = useState({});
  const [deviceInfoErrors, setDeviceInfoErrors] = useState({});
  const [parametersRows, setParametersRows] = useState([]);
  const [customerDetailsColumns, setCustomerDetailsColumns] = useState([]);
  const [commentsColumns, setCommentsColumns] = useState([]);
  const [customerDetailsErrors, setCustomerDetailsErrors] = useState({});
  const [commentsColumnsErrors, setCommentsColumnsErrors] = useState({});
  const [accessoriesDetailsColumns, setAccessoriesDetailsColumns] = useState(
    []
  );
  const [accessoriesDetailsRows, setAccessoriesDetailsRows] = useState([]);
  const [accessoriesDetailsErrors, setAccessoriesDetailsErrors] = useState({});
  const [trainingDescRows, setTrainingDescRows] = useState([]);
  const [trainingDescErrors, setTrainingDescErrors] = useState({});

  const getData = async () => {
    try {
      const uri = url.BASE_ROUTE + id;
      const result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        setData(result?.data);
        setDeviceInfoColumns(
          result?.data?.document?.deviceInformationColumns ?? []
        );
        setParameterCheckedColumns(
          result?.data?.document?.ParameterToBeCheckColumns ?? []
        );
        setCustomerDetailsColumns(
          result?.data?.document?.customerDetailsColumns ?? []
        );
        setCommentsColumns(result?.data?.document?.commentsColumns ?? []);
        setAccessoriesDetailsColumns(
          result?.data?.document?.accessoriesDetailsColumns ?? []
        );
        setParametersRows(result?.data?.document?.parametersRows ?? []);
        setAccessoriesDetailsRows(
          result?.data?.document?.accessoriesDetailsRows ?? []
        );
        setTrainingDescRows(
          result?.data?.document?.trainingDescriptionRows ?? []
        );
      } else {
        setData({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleValidate = (documentName) => {
    if (documentName?.includes("Checklist For Installation")) {
      const validationErrorDevice = validateColumns(deviceInfoColumns);
      if (Object.keys(validationErrorDevice)?.length > 0) {
        setDeviceInfoErrors(validationErrorDevice);
      }
      const validationErrorParameter = validationDynamicTable(
        parametersRows,
        parameterCheckedColumns
      );
      if (Object.keys(validationErrorParameter)?.length > 0) {
        setParametersErrors(validationErrorParameter);
      }

      if (validationErrorDevice && validationErrorParameter) {
        return false;
      } else {
        true;
      }
    } else {
      const validationErrorCustomerDetails = validateColumns(
        customerDetailsColumns
      );
      if (Object.keys(validationErrorCustomerDetails)?.length > 0) {
        setCustomerDetailsErrors(validationErrorCustomerDetails);
      }
      const validationErrorAccessories = validationDynamicTable(
        accessoriesDetailsRows,
        accessoriesDetailsColumns
      );
      if (Object.keys(validationErrorAccessories)?.length > 0) {
        setAccessoriesDetailsErrors(validationErrorAccessories);
      }
      const validationErrorComment = validationDynamicTable(commentsColumns);
      if (Object.keys(validationErrorComment)?.length > 0) {
        setCommentsColumnsErrors(validationErrorComment);
      }

      if (validationErrorCustomerDetails && validationErrorAccessories) {
        return false;
      } else {
        true;
      }
    }
  };

  const handleSave = async () => {
    try {
      if (handleValidate(data?.documentName)) {
        return;
      }
      let reqObj = {};
      if (data?.documentName?.includes("Checklist For Installation")) {
        reqObj = {
          document: {
            ...data?.document,
            deviceInformationColumns: deviceInfoColumns,
            parametersRows,
            status: "Submitted",
            statusHistory: {
              ...data?.document?.statusHistory,
              initialSubmit: {
                lastUpdatedAt: new Date(),
                createdAt: new Date(),
                userData: {
                  name: userData?.name,
                  userId: userData?._id,
                  designationId: selectedRole?.roleId,
                  designation: selectedRole?.roleName,
                },
              },
            },
            submmitedBy: {
              userData: {
                name: userData?.name,
                userId: userData?._id,
                designationId: selectedRole?.roleId,
                designation: selectedRole?.roleName,
              },
              time: new Date(),
            },
          },
        };
      } else {
        reqObj = {
          document: {
            ...data?.document,
            customerDetailsColumns: customerDetailsColumns,
            commentsColumns: commentsColumns,
            trainingDescriptionRows: trainingDescRows ?? [],
            accessoriesDetailsRows,
            status: "Submitted",
            statusHistory: {
              ...data?.document?.statusHistory,
              initialSubmit: {
                lastUpdatedAt: new Date(),
                createdAt: new Date(),
                userData: {
                  name: userData?.name,
                  userId: userData?._id,
                  designationId: selectedRole?.roleId,
                  designation: selectedRole?.roleName,
                },
              },
            },
            submmitedBy: {
              userData: {
                name: userData?.name,
                userId: userData?._id,
                designationId: selectedRole?.roleId,
                designation: selectedRole?.roleName,
              },
              time: new Date(),
            },
          },
        };
      }

      const uri = url.BASE_ROUTE + id;
      const result = await ApiServices.callServicePutWithBodyData(uri, reqObj);
      showToast(result);
      if (result?.meta?.success) {
        getData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isValidUser = (data) => {
    return (
      data?.some(
        (user) =>
          user?.userId === userData?._id &&
          user?.designationId === selectedRole?.roleId
      ) ?? false
    );
  };

  const handleDownload = async () => {
    try {
      let reqObj = {
        formatId: id,
      };
      let uri = url.INSTALLATION_DOWNLOAD;
      await downloadFormat(uri, reqObj, onChangeDownloadState);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <MainCard
      title={`${data?.documentName}` ?? "-"}
      secondary={
        <>
          <Button variant="contained" color="primary" onClick={handleDownload}>
            Download
          </Button>
        </>
      }
    >
      {Object.keys(data)?.length > 0 ? (
        data?.documentName?.includes("Checklist For Installation") ? (
          <Stack gap={2}>
            <DynamicTableForm
              columns={deviceInfoColumns}
              setColumns={setDeviceInfoColumns}
              errors={deviceInfoErrors}
              setErrors={setDeviceInfoErrors}
              isStatic={true}
              isViewMode={data?.document?.type === "Internal" ? false : true}
            />
            <DynamicTable
              columns={parameterCheckedColumns}
              setColumns={setParameterCheckedColumns}
              rows={parametersRows}
              setRows={setParametersRows}
              errors={parametersErrors}
              setErrors={setParametersErrors}
              isStatic={true}
              isNumberDynamic={true}
              isViewMode={data?.document?.type === "Internal" ? false : true}
              isStrictData={true}
            />
          </Stack>
        ) : data?.documentName?.includes("Installation Report") ? (
          <Stack gap={2}>
            <DynamicTableForm
              columns={customerDetailsColumns}
              setColumns={setCustomerDetailsColumns}
              errors={customerDetailsErrors}
              setErrors={setCustomerDetailsErrors}
              isStatic={true}
              isViewMode={data?.document?.type === "Internal" ? false : true}
            />
            {customerDetailsColumns?.find(
              (col) =>
                col?.defaultName === "areYouProvidingAccessoriesWithTheDevice"
            )?.inputValue === "Yes" && (
              <DynamicTable
                columns={accessoriesDetailsColumns}
                setColumns={setAccessoriesDetailsColumns}
                rows={accessoriesDetailsRows}
                setRows={setAccessoriesDetailsRows}
                errors={accessoriesDetailsErrors}
                setErrors={setAccessoriesDetailsErrors}
                isStatic={true}
                isNumberDynamic={true}
                isViewMode={data?.document?.type === "Internal" ? false : true}
              />
            )}
            {data?.document?.trainingDescriptionColumns?.length > 0 && (
              <DynamicTable
                columns={data?.document?.trainingDescriptionColumns}
                setRows={setTrainingDescRows}
                rows={trainingDescRows}
                errors={trainingDescErrors}
                setErrors={setTrainingDescErrors}
                isStatic={true}
                isNumberDynamic={true}
              />
            )}
            <DynamicTableForm
              columns={commentsColumns}
              setColumns={setCommentsColumns}
              errors={commentsColumnsErrors}
              setErrors={setCommentsColumnsErrors}
              isStatic={true}
              isViewMode={data?.document?.type === "Internal" ? false : true}
            />
          </Stack>
        ) : null
      ) : (
        <Error404
          headingMain={`Oops, looks like there are no Process Validation created it!`}
          subHeading={`Please fill Validation details`}
        />
      )}
      {null}
      {Object.keys(data)?.length && data?.document?.type === "Internal" ? (
        <Stack sx={{ display: "flex", justifyContent: "flex-end" }}>
          {data?.document?.submmitedBy ? (
            <Stack gap={2} margin={2}>
              <FormControl fullWidth>
                <TextField
                  label="Name"
                  value={
                    data?.document?.submmitedBy?.userData
                      ? `${
                          data.document.submmitedBy.userData.name || "N/A"
                        } - ${
                          data.document.submmitedBy.userData.designation ||
                          "N/A"
                        }`
                      : ""
                  }
                  fullWidth
                  inputProps={{ readOnly: true }}
                />
              </FormControl>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date"
                    value={
                      data?.document?.submmitedBy?.time
                        ? new Date(data.document.submmitedBy.time)
                        : null
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    disabled={true}
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>
          ) : (
            <Button
              disabled={!isValidUser(data?.document?.assignedUser) ?? false}
              sx={{ width: 150, margin: 2 }}
              color="success"
              variant="contained"
              onClick={() => handleSave()}
            >
              Save
            </Button>
          )}
        </Stack>
      ) : null}
    </MainCard>
  );
};

export default withRouter(ReportDetails);
