import { ApiServices } from "services/apiServices";
import { Constants } from "services/keyConstants";
import { getItem, showToast } from "services/utils";
import * as url from "./urlHelper";

const userData = getItem(Constants.KEY_USER_DATA);

export const handleCreate = async () => {
  try {
    const reqObj = {
      year: new Date().getFullYear(),
    };
    const uri = url.CREATE;
    const result = await ApiServices.callServicePostWithBodyData(uri, reqObj);
    showToast(result);
    return result
  } catch (error) {
    console.error(error);
  }
}

export const getDefaultDataForDynamicTable = (
  columnData,
  options = {},
  functions = {}
) => {
  return columnData?.map((item) => {
    // Create a shallow copy of the item to avoid mutating the original object
    const newItem = { ...item };

    // Replace extraOnChangeFunction with extraOnChange if it exists and resolve the function from functions
    if (newItem.extraOnChangeFunction) {
      const functionName = newItem.extraOnChangeFunction;
      if (
        functions[functionName] &&
        typeof functions[functionName] === "function"
      ) {
        newItem.extraOnChange = functions[functionName]; // Assign the resolved function
      } else {
        console.error(
          `Function ${functionName} not found or is not a valid function.`
        );
      }
      delete newItem.extraOnChangeFunction; // Remove the extraOnChangeFunction key
    }

    // Handle defaultValue with dynamicField and functionName
    const dynamicField = newItem?.defaultValue?.dynamicField;
    const functionName = newItem?.defaultValue?.functionName;
    if (dynamicField && functionName) {
      const dynamicData = options[dynamicField];
      const dynamicFunction = functions[functionName];

      if (typeof dynamicFunction === "function") {
        try {
          // Call the function with the dynamic data
          const result = dynamicFunction(dynamicData);

          // Check if the function is asynchronous
          if (result instanceof Promise) {
            result
              .then((resolvedValue) => {
                newItem.defaultValue = resolvedValue;
              })
              .catch((error) =>
                console.error(
                  `Error resolving function ${functionName}:`,
                  error
                )
              );
          } else {
            // Synchronous function result
            newItem.defaultValue = result;
          }
        } catch (error) {
          console.error(`Error calling function ${functionName}:`, error);
        }
      } else {
        console.error(
          `Function ${functionName} not found in the provided functions object.`
        );
      }
    }

    return newItem;
  });
};
