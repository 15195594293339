import React, { useState } from "react";
import { Dialog, Grid, Typography, Paper, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import withRouter from "components/withRouter";

const ISOCard = () => {
  const [open, setOpen] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
    boxShadow: "none",
    height: "175px",
  }));

  const CancelIcon = (props) => (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.9987 3.33594C10.8154 3.33594 3.33203 10.8193 3.33203 20.0026C3.33203 29.1859 10.8154 36.6693 19.9987 36.6693C29.182 36.6693 36.6654 29.1859 36.6654 20.0026C36.6654 10.8193 29.182 3.33594 19.9987 3.33594ZM25.5987 23.8359C26.082 24.3193 26.082 25.1193 25.5987 25.6026C25.3487 25.8526 25.032 25.9693 24.7154 25.9693C24.3987 25.9693 24.082 25.8526 23.832 25.6026L19.9987 21.7693L16.1654 25.6026C15.9154 25.8526 15.5987 25.9693 15.282 25.9693C14.9654 25.9693 14.6487 25.8526 14.3987 25.6026C13.9154 25.1193 13.9154 24.3193 14.3987 23.8359L18.232 20.0026L14.3987 16.1693C13.9154 15.6859 13.9154 14.8859 14.3987 14.4026C14.882 13.9193 15.682 13.9193 16.1654 14.4026L19.9987 18.2359L23.832 14.4026C24.3154 13.9193 25.1154 13.9193 25.5987 14.4026C26.082 14.8859 26.082 15.6859 25.5987 16.1693L21.7654 20.0026L25.5987 23.8359Z"
        fill="#E76767"
      />
    </svg>
  );

  const ISOIcon = () => (
    <svg
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.9987 32.0853C64.7233 32.0853 74.0496 35.9484 80.9259 42.8247C87.8023 49.7011 91.6654 59.0274 91.6654 68.752C91.6654 78.4766 87.8023 87.8029 80.9259 94.6792C74.0496 101.556 64.7233 105.419 54.9987 105.419C45.2741 105.419 35.9478 101.556 29.0715 94.6792C22.1951 87.8029 18.332 78.4766 18.332 68.752C18.332 59.0274 22.1951 49.7011 29.0715 42.8247C35.9478 35.9484 45.2741 32.0853 54.9987 32.0853ZM54.9987 41.252C47.7052 41.252 40.7105 44.1493 35.5533 49.3065C30.396 54.4638 27.4987 61.4585 27.4987 68.752C27.4987 76.0454 30.396 83.0402 35.5533 88.1974C40.7105 93.3547 47.7052 96.252 54.9987 96.252C62.2922 96.252 69.2869 93.3547 74.4441 88.1974C79.6014 83.0402 82.4987 76.0454 82.4987 68.752C82.4987 61.4585 79.6014 54.4638 74.4441 49.3065C69.2869 44.1493 62.2922 41.252 54.9987 41.252ZM54.9987 48.127L61.0579 60.4103L74.6154 62.3811L64.807 71.9374L67.1216 85.4399L54.9987 79.0645L42.8758 85.4353L45.1904 71.9374L35.382 62.3766L48.9395 60.4057L54.9987 48.127ZM82.4987 9.16865V22.9186L76.2516 28.1345C71.0691 25.4109 65.4085 23.716 59.582 23.1432V9.16865H82.4987ZM50.4154 9.16406V23.1432C44.5908 23.7151 38.9318 25.4085 33.7504 28.1299L27.4987 22.9186V9.16865L50.4154 9.16406Z"
        fill="#4680FF"
        fill-opacity="0.1"
      />
    </svg>
  );

  const MedalStar = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16.75C11.6 16.75 11.2 16.72 10.82 16.65C8.7 16.34 6.77 15.12 5.55 13.31C4.7 12.03 4.25 10.54 4.25 9C4.25 4.73 7.73 1.25 12 1.25C16.27 1.25 19.75 4.73 19.75 9C19.75 10.54 19.3 12.03 18.45 13.31C17.22 15.13 15.29 16.34 13.15 16.66C12.8 16.72 12.4 16.75 12 16.75ZM12 2.75C8.55 2.75 5.75 5.55 5.75 9C5.75 10.25 6.11 11.45 6.79 12.47C7.78 13.93 9.33 14.91 11.05 15.16C11.69 15.27 12.32 15.27 12.91 15.16C14.66 14.91 16.21 13.92 17.2 12.46C17.88 11.44 18.24 10.24 18.24 8.98999C18.25 5.54999 15.45 2.75 12 2.75Z"
        fill="black"
      />
      <path
        d="M6.47128 22.588C6.33128 22.588 6.20128 22.568 6.06128 22.538C5.41128 22.388 4.91128 21.888 4.76128 21.238L4.41128 19.768C4.39128 19.678 4.32128 19.608 4.22128 19.578L2.57128 19.188C1.95128 19.038 1.46128 18.578 1.29128 17.968C1.12128 17.358 1.29128 16.698 1.74128 16.248L5.64128 12.348C5.80128 12.188 6.02128 12.108 6.24128 12.128C6.46128 12.148 6.66128 12.268 6.79128 12.458C7.78128 13.918 9.33128 14.908 11.0613 15.158C11.7013 15.268 12.3313 15.268 12.9213 15.158C14.6713 14.908 16.2213 13.918 17.2113 12.458C17.3313 12.268 17.5413 12.148 17.7613 12.128C17.9813 12.108 18.2013 12.188 18.3613 12.348L22.2613 16.248C22.7113 16.698 22.8813 17.358 22.7113 17.968C22.5413 18.578 22.0413 19.048 21.4313 19.188L19.7813 19.578C19.6913 19.598 19.6213 19.668 19.5913 19.768L19.2413 21.238C19.0913 21.888 18.5913 22.388 17.9413 22.538C17.2913 22.698 16.6213 22.468 16.2013 21.958L12.0013 17.128L7.80128 21.968C7.46128 22.368 6.98128 22.588 6.47128 22.588ZM6.09128 14.028L2.80128 17.318C2.71128 17.408 2.72128 17.508 2.74128 17.568C2.75128 17.618 2.80128 17.718 2.92128 17.738L4.57128 18.128C5.22128 18.278 5.72128 18.778 5.87128 19.428L6.22128 20.898C6.25128 21.028 6.35128 21.068 6.41128 21.088C6.47128 21.098 6.57128 21.108 6.66128 21.008L10.4913 16.598C8.79128 16.268 7.23128 15.358 6.09128 14.028ZM13.5113 16.588L17.3413 20.988C17.4313 21.098 17.5413 21.098 17.6013 21.078C17.6613 21.068 17.7513 21.018 17.7913 20.888L18.1413 19.418C18.2913 18.768 18.7913 18.268 19.4413 18.118L21.0913 17.728C21.2113 17.698 21.2613 17.608 21.2713 17.558C21.2913 17.508 21.3013 17.398 21.2113 17.308L17.9213 14.018C16.7713 15.348 15.2213 16.258 13.5113 16.588Z"
        fill="black"
      />
      <path
        d="M13.8911 12.8922C13.6311 12.8922 13.3211 12.8222 12.9511 12.6022L12.0011 12.0322L11.0511 12.5922C10.1811 13.1122 9.61112 12.8122 9.40112 12.6622C9.19112 12.5122 8.74112 12.0622 8.97112 11.0722L9.21112 10.0422L8.41112 9.30219C7.97112 8.86219 7.81112 8.3322 7.96112 7.8522C8.11112 7.3722 8.55112 7.03219 9.17112 6.93219L10.2411 6.7522L10.7511 5.6322C11.0411 5.0622 11.4911 4.74219 12.0011 4.74219C12.5111 4.74219 12.9711 5.07221 13.2511 5.64221L13.8411 6.8222L14.8311 6.9422C15.4411 7.0422 15.8811 7.38218 16.0411 7.86218C16.1911 8.34218 16.0311 8.87219 15.5911 9.31219L14.7611 10.1422L15.0211 11.0722C15.2511 12.0622 14.8011 12.5122 14.5911 12.6622C14.4811 12.7522 14.2411 12.8922 13.8911 12.8922ZM9.61112 8.39221L10.3011 9.08218C10.6211 9.40218 10.7811 9.9422 10.6811 10.3822L10.4911 11.1822L11.2911 10.7122C11.7211 10.4622 12.3011 10.4622 12.7211 10.7122L13.5211 11.1822L13.3411 10.3822C13.2411 9.9322 13.3911 9.40218 13.7111 9.08218L14.4011 8.39221L13.5311 8.24219C13.1111 8.17219 12.6911 7.86221 12.5011 7.48221L12.0011 6.5022L11.5011 7.5022C11.3211 7.8722 10.9011 8.19221 10.4811 8.26221L9.61112 8.39221Z"
        fill="#292D32"
      />
    </svg>
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div onClick={() => setOpen((prev) => !prev)}>
        <Box
          sx={{
            bgcolor: "#FFF",
            borderRadius: "50px",
            height: "34px",
            padding: "5px 15px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            fontWeight: 600,
          }}
        >
          <MedalStar />
          ISO13485
        </Box>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        sx={{
          width: "60vw",
          height: "510px",
          margin: "auto",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ margin: "10px" }}>
          <Box
            sx={{
              height: "56px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: "20px",
              borderBottom: "1px solid #9FA2AA",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 500,
              }}
            >
              Select Standards
            </Typography>

            <Box onClick={handleClose}>
              <CancelIcon />
            </Box>
          </Box>
        </Box>

        <Box sx={{ margin: "10px" }}>
          <Grid container spacing={2}>
            {[1].map(() => (
              <Grid item lg={3} sx={{}}>
                <Item sx={{ border: "1px solid #CFD1D4" }}>
                  <Box sx={{ height: "110px", display: "flex" }}>
                    <Box
                      sx={{ width: "100%", color: "#000", paddingLeft: "5px" }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: {
                            sm: "22px",
                            md: "28px",
                            lg: "20px",
                            xl: "50px",
                          },
                          fontWeight: 600,
                          textAlign: "start",
                          lineHeight: "55px",
                        }}
                        variant="subtitle1"
                      >
                        ISO
                      </Typography>
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: {
                            sm: "15px",
                            md: "15px",
                            lg: "17px",
                            xl: "25px",
                          },
                          fontWeight: 600,
                          textAlign: "start",
                          lineHeight: "10px",
                        }}
                      >
                        13485
                      </Typography>
                    </Box>
                    <Box sx={{ width: "110px" }}>
                      {" "}
                      <ISOIcon />{" "}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0px 0px 10px 10px",
                    }}
                  >
                    {"Quality Management  System".split(" ").map((text) => (
                      <Typography sx={{ textAlign: "start", fontSize: "13px" }}>
                        {text}
                      </Typography>
                    ))}
                  </Box>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default withRouter(ISOCard);
