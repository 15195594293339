import {
  Box,
  CardContent,
  ClickAwayListener,
  Divider,
  IconButton,
  Grid,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  List,
  ListItemButton,
  ListItemText,
  Modal,
} from "@mui/material";
import { Windows } from "iconsax-react";
import { useRef, useState } from "react";
import { camelCaseToString } from "services/utils";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import DynamicNotification from "components/DynamicNotification";
import withRouter from "components/withRouter";
import general from "assets/images/megaMenu/general.png";
import management from "assets/images/megaMenu/management.png";
import feedback from "assets/images/megaMenu/feedback.png";
import customer from "assets/images/megaMenu/customer.png";
import share from "assets/images/megaMenu/SHARE.png";

import ellipse from "assets/images/megaMenu/ellipse.png";
import CircleIcon from "@mui/icons-material/Circle";

const MegaMenu = (props) => {
  const isSmallScreen = useMediaQuery("(min-width:1000px)");
  const { theme, routeConstants } = props,
    { navigate } = props?.router,
    { ThemeMode, DRAWER_WIDTH } = props?.config;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      setOpen(false);
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen =
    theme.palette.mode === ThemeMode.DARK ? "secondary.200" : "secondary.200";
  const iconBackColor =
    theme.palette.mode === ThemeMode.DARK
      ? "background.default"
      : "secondary.100";

  const handleService = () => {
    navigate(`${routeConstants.CUSTOMER_SERVICE}/customer-service-form`);
  };

  const buttonData = {
    general: [
      {
        text: "Customer Service Form",
        onClick: handleService,
      },
      {
        text: "Internal communication",
        onClick: () =>
          navigate(`${routeConstants.INTERNAL_COMMUNICATION}/create`),
      },
      // {
      //   text: "Customer Property",
      //   onClick: () =>
      //     navigate(
      //       `${routeConstants.CONTROL_OF_CUSTOMER_PROPERTY}/list-of-customer-property`
      //     ),
      // },
      // {
      //   text: "Work Environment",
      //   onClick: () => navigate("work-environment/environment"),
      // },
      {
        text: "Add-Complaint",
        onClick: () =>
          navigate(`${routeConstants.CUSTOMER_COMPLAINTS}/add-complaint`),
      },
      // {
      //   text: "Generate Inprocess Record",
      //   onClick: () => navigate(`${routeConstants.MAM_OF_PRODUCT}/incoming`),
      // },
      {
        text: "Communication With RegulatoryBody",
        onClick: () =>
          navigate(
            `/${routeConstants.COMMUNICATION_WITH_REGULATORY_BODIES}/communication-with-regulatory-body`
          ),
      },
    ],
    ChangeControlManagement: [
      {
        text: "Change Note",
        onClick: () => navigate(`${routeConstants.CHANGE_NOTE}/manage`),
      },
    ],

    customerRelatedProcessAndDistribution: [
      {
        text: "Generate New Distributor",
        onClick: () =>
          navigate(
            `${routeConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION}/create-distributor`
          ),
      },
      {
        text: "Generate Order Form",
        onClick: () =>
          navigate(
            `${routeConstants.CUSTOMER_RELATED_PROCESS_AND_DISTRIBUTION}/create-order`
          ),
      },
    ],
    Feedback: [
      {
        text: "Create Internal Feedback",
        onClick: () => navigate(`${routeConstants.FEEDBACK}/create-internal`),
      },
      {
        text: "Create External Feedback",
        onClick: () => navigate(`${routeConstants.FEEDBACK}/create-external`),
      },
    ],
  };

  // Filter out falsy values for each key in buttonData
  Object.keys(buttonData)?.forEach((key) => {
    buttonData[key] = buttonData[key]?.filter(Boolean);

    // If no valid buttons, delete the key
    if (buttonData[key]?.length === 0) {
      delete buttonData[key];
    }
  });

  const createButton = (text, onClick) => ({
    text,
    onClick,
  });

  const createButtonsForCategory = (category) => {
    return (
      buttonData[category]
        ?.filter(Boolean)
        ?.map(({ text, onClick }) => createButton(text, onClick)) || []
    );
  };
  const categoryImages = {
    ChangeControlManagement: management,
    customerRelatedProcessAndDistribution: customer,
    Feedback: feedback,
  };

  return (
    <>
      {isOpenNotification ? (
        <DynamicNotification
          open={isOpenNotification}
          setOpen={setIsOpenNotification}
          isMultipleDepartment={false}
          isMultipleUser={false}
          handleSubmit={handleSubmitMasterList}
        />
      ) : null}
      <ClickAwayListener onClickAway={handleClose}>
        <Box sx={{ flexShrink: 0, ml: 0.5 }}>
          <IconButton
            color="secondary"
            variant="light"
            aria-label="open Btns"
            ref={anchorRef}
            aria-controls={open ? "Btns-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            size="medium"
            sx={{
              color: "secondary.main",
              bgcolor: open ? iconBackColorOpen : iconBackColor,
              p: 1,
              borderRadius: 100,
              bgcolor: "#fff",
            }}
          >
            <Windows variant="Bulk" />
          </IconButton>
          <Popper
            placement="bottom-end"
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            popperOptions={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: isSmallScreen ? [170, 9] : [0, 9] },
                },
              ],
            }}
            sx={{
              maxHeight: "550px",
              overflow: "hidden",
              top: "10px !important",
              right: "1.5rem !important",
              borderRadius: "30px",
            }}
          >
            {({ TransitionProps }) => (
              <Transitions
                type="grow"
                position="top-right"
                in={open}
                {...TransitionProps}
              >
                {isSmallScreen ? (
                  <Paper
                    type="grow"
                    position="top-right"
                    in={open}
                    {...TransitionProps}
                    sx={{
                      boxShadow: theme.customShadows.z1,
                      width: {
                        md: `calc(100vw - 200px)`,
                        lg: `calc(100vw - ${DRAWER_WIDTH + 100}px)`,
                        xl: `calc(100vw - ${DRAWER_WIDTH + 140}px)`,
                      },
                      maxWidth: 800,
                      maxHeight: "550px",
                      borderRadius: "30px",
                      overflow: "hidden",
                      backgroundColor: "rgb(255,255,255)",
                    }}
                  >
                    <Grid
                      container
                      lg={12}
                      sx={{
                        padding: "10px 10px",
                        justifyContent: "center",
                        backgroundImage: `url(${ellipse})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right 0px bottom 0px",
                      }}
                    >
                      <Grid
                        container
                        lg={5.7}
                        sm={5.5}
                        sx={{
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "10px 0px",
                          }}
                        >
                          <img
                            src={general}
                            alt="General"
                            style={{
                              borderBottom: "2px solid #9FA2AA",
                              borderRadius: "4px",
                              width: "2.5rem",
                            }}
                          />
                          <Typography variant="h5" sx={{ padding: "0px 20px" }}>
                            General
                          </Typography>
                        </Box>
                        <Box>
                          {buttonData.general?.map((button, i) => (
                            <Typography
                              onMouseEnter={() => setHoveredIndex(i)}
                              onMouseLeave={() => setHoveredIndex(null)}
                              sx={{
                                padding: "8px",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                "&:hover": {
                                  backgroundImage:
                                    "linear-gradient(to right, #DFEEFF, #FFFFFF)",
                                  borderLeft: "5px solid #7EA6FF",
                                  borderRadius: "5px",
                                },
                              }}
                              key={i}
                              onClick={(e) => {
                                if (button?.onClick) button.onClick(e);
                                handleClose(e);
                              }}
                            >
                              <Box sx={{ width: "fit-content" }}>
                                <CircleIcon
                                  sx={{ fontSize: "6px", marginRight: "5px" }}
                                />
                                {button.text}
                              </Box>
                              {hoveredIndex === i && (
                                <img
                                  src={share}
                                  alt="Arrow"
                                  style={{ width: "15px", height: "15px" }}
                                />
                              )}
                            </Typography>
                          ))}
                        </Box>
                      </Grid>
                      <Grid container lg={5.9} sm={5.5}>
                        {Object.keys(buttonData)
                          ?.slice(1)
                          ?.map((category, index) => (
                            <div key={category} style={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {categoryImages[category] && (
                                  <img
                                    src={categoryImages[category]}
                                    alt={category}
                                    style={{
                                      marginBottom: "10px",
                                      marginRight: "20px",
                                      width: "2.5rem",
                                    }}
                                  />
                                )}
                                <Typography variant="h5">
                                  {camelCaseToString(category)}
                                </Typography>
                              </Box>
                              {createButtonsForCategory(category)?.length > 0
                                ? createButtonsForCategory(category)?.map(
                                    (button, i) => (
                                      <Box
                                        key={i}
                                        onMouseEnter={() =>
                                          setHoveredIndex(`${index}-${i}`)
                                        }
                                        onMouseLeave={() =>
                                          setHoveredIndex(null)
                                        }
                                      >
                                        <Typography
                                          sx={{
                                            padding: "8px",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            "&:hover": {
                                              backgroundImage:
                                                "linear-gradient(to right, #DFEEFF, #FFFFFF)",
                                              borderLeft: "5px solid #7EA6FF",
                                              borderRadius: "5px",
                                            },
                                          }}
                                          onClick={(e) => {
                                            if (button?.onClick)
                                              button.onClick(e);
                                            handleClose(e);
                                          }}
                                        >
                                          <Box sx={{ width: "fit-content" }}>
                                            <CircleIcon
                                              sx={{
                                                fontSize: "6px",
                                                marginRight: "5px",
                                              }}
                                            />
                                            {camelCaseToString(button.text)}
                                          </Box>
                                          {hoveredIndex === `${index}-${i}` && (
                                            <img
                                              src={share}
                                              alt="Arrow"
                                              style={{
                                                width: "15px",
                                                height: "15px",
                                              }}
                                            />
                                          )}
                                        </Typography>
                                      </Box>
                                    )
                                  )
                                : null}
                              {index !== Object.keys(buttonData).length - 2 && (
                                <hr
                                  style={{
                                    border: "1px solid #ddd",
                                    margin: "10px 0",
                                  }}
                                />
                              )}
                            </div>
                          ))}
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <Paper
                    sx={{
                      marginLeft: 2,
                      marginRight: 2,
                      boxShadow: theme.customShadows.z1,
                      maxWidth: 350,
                      overflowY: "scroll",
                      maxHeight: "500px",
                      borderRadius: 1.5,
                      "&::-webkit-scrollbar": {
                        width: "5px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "10px",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <MainCard border={false} content={false}>
                        <CardContent sx={{ p: 2, pb: 0 }}>
                          {Object.keys(buttonData).map((category) => (
                            <Box key={category} sx={{ mb: 1 }}>
                              <Typography
                                variant="subtitle2"
                                color="text.primary"
                                sx={{
                                  textTransform: "capitalize",
                                  fontSize: "11px",
                                }}
                              >
                                {camelCaseToString(category)}
                              </Typography>
                              <Divider />
                              <List
                                component="nav"
                                sx={{
                                  p: 0,
                                  "& .MuiListItemIcon-root": { minWidth: 32 },
                                  "& .MuiDivider-root": { my: 1 },
                                }}
                              >
                                {createButtonsForCategory(category)?.length > 0
                                  ? createButtonsForCategory(category)?.map(
                                      (button, i) => (
                                        <ListItemButton
                                          key={i}
                                          onClick={(e) => {
                                            if (button?.onClick)
                                              button.onClick(e);
                                            handleClose(e);
                                          }}
                                        >
                                          <ListItemText primary={button.text} />
                                        </ListItemButton>
                                      )
                                    )
                                  : null}
                              </List>
                            </Box>
                          ))}
                        </CardContent>
                        <Divider />
                      </MainCard>
                    </ClickAwayListener>
                  </Paper>
                )}
              </Transitions>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
    </>
  );
};

export default withRouter(MegaMenu);
