import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Paper,
  Button,
  Stack,
} from "@mui/material";
import * as url from "./utils/urlHelper";
import {
  validateColumns,
  getPermssionDataByName,
  checkPermission,
  showToast,
} from "services/utils";
import ESign from "components/ESign";
import withRouter from "components/withRouter";
import DynamicTableForm from "components/reusables/DynamicTableForm";
import { getDefaultData } from "pages/CRPD/utils/service";
import MainCard from "components/MainCard";

const ServiceRecord = (props) => {
  const { params, navigate } = props?.router,
    {
      userData,
      ApiServices,
      FormatConstants,
      selectedRole,
    } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();
  const [customerDetailsColums, setCustomerDetailsColums] = useState();
  const [deviceDetailsColums, setDeviceDetailsColums] = useState();
  const [permissionData, setPermissionData] = useState([]);
  const [errors, setErrors] = useState();
  const { id, parentId } = params;

  const getCustomerService = async () => {
    try {
      const uri = url.GET_BY_ID_CUSTOMER + id;
      const result = await ApiServices.callServiceGet(uri);
      if (result?.meta?.success) {
        getFormatData(result?.data?.document);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const getFormatData = async (data) => {
    let columnData = data;
    setData(columnData);
    setCustomerDetailsColums(columnData?.customerDetailsColumns);

    let tempData = getDefaultData(columnData?.deviceDetailsColumns, {
      userData: userData,
      selectedRole: selectedRole,
    });

    setDeviceDetailsColums(tempData);
    // }
  };
  const getPermissionData = async () => {
    let result = await getPermssionDataByName(
      FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_FORM
    );
    setPermissionData(result ?? []);
  };
  useEffect(() => {
    getCustomerService();
    getPermissionData();
  }, []);

  const handleESignUpdate = async (success) => {
    try {
      const validationErrors = validateColumns(deviceDetailsColums)
      if (Object.keys(validationErrors)?.length > 0) {
        setErrors(validationErrors)
        return
      }
      let reqObj = {
        document: {
          ...data,
          customerDetailsColumns: customerDetailsColums,
          deviceDetailsColumns: deviceDetailsColums,
          submmited: true
        },
      };

      let uri = url.UPDATE_REPORT + id + '?' + `mainDocId=${parentId}`;
      const response = await ApiServices.callServicePutWithBodyData(
        uri,
        reqObj
      );
      if (response?.meta?.success) {
        showToast(response)
        navigate(-1)
      }
    } catch (error) {
      console.error("Error updating e-signature:", error);
    }
  };

  return (
    <MainCard title="Customer Service Record">

      {isOpen ? (
        <ESign
          open={isOpen}
          setOpen={setIsOpen}
          handleSubmit={handleESignUpdate}
        />
      ) : null}
      {data ? (
        <Stack sx={{ margin: 2, width: "100%" }} gap={2}>
          <DynamicTableForm
            // data={customerDetails}
            columns={customerDetailsColums}
            setColumns={setCustomerDetailsColums}
            isStrictData={true}
            isStatic={true}
            isViewMode={true}
          />
          <DynamicTableForm
            // data={deviceDetails}
            columns={deviceDetailsColums}
            setColumns={setDeviceDetailsColums}
            setErrors={setErrors}
            errors={errors}
            isStrictData={true}
            isStatic={true}
          />
        </Stack>
      ) : null}
      <Stack
        direction="row" // Ensure horizontal layout
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center", // Align items in the center vertically (optional)
          m: 1,
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={
            !checkPermission(
              permissionData,
              FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_FORM
            )
          }
          onClick={handleESignUpdate}
          sx={{ width: 150 }}
        >
          Submit
        </Button>
      </Stack>



    </MainCard>
  );
};

export default withRouter(ServiceRecord);
