import PropTypes from "prop-types";
import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from "@mui/material";
import { Logout, Musicnote, Profile, RecoveryConvert } from "iconsax-react";
import { getItem, setItem } from "services/utils";
import withRouter from "components/withRouter";
import { CheckCircleOutline } from "@mui/icons-material";

const ProfileTab = (props) => {
  const {
      handleToggle,
      handleLogout,
      theme,
      userData,
      userRole,
      Constants,
      setRole,
      role,
    } = props,
    { navigate } = props?.router;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const storedSoundOn = getItem(Constants.KEY_NOTIFICATION_SOUND);

  const [soundOn, setSoundOn] = useState(
    storedSoundOn !== undefined ? storedSoundOn : true
  );

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    if (route && route !== "") {
      navigate(route);
    }
  };

  const handleRoleSelection = (role, elm) => {
    const updatedRole = {
      departmentId: elm?.departmentId,
      departmentName: elm?.departmentName,
      departmentDefaultName: elm?.departmentDefaultName,
      roleId: role?.id,
      defaultRoleName: role?.defaultDesignation,
      roleName: role?.designation,
    };
    setItem(Constants.KEY_SELECTED_ROLE, updatedRole);
    setRole(updatedRole);
    window.dispatchEvent(
      new CustomEvent("roleUpdated", { detail: updatedRole })
    );
    window.location.reload();
  };

  const handleSoundToggle = () => {
    const newSoundOn = !soundOn;
    setSoundOn(newSoundOn);

    setItem(Constants.KEY_NOTIFICATION_SOUND, newSoundOn);
    window.dispatchEvent(new Event("notificationSoundUpdated"));
  };

  return (
    <>
      <List
        component="nav"
        sx={{ p: 1, "& .MuiListItemIcon-root": { minWidth: 32 } }}
      >
        {userData?.departmentData?.length > 1 ? (
          <Accordion
            sx={{
              border: "none",
              borderRadius: "8px",
              overflow: "hidden",
              background: "none",
              margin: "2px 0",
              "&.Mui-expanded": {
                border: "1px solid #dedede",
              },
              "&:not(.Mui-expanded)": {
                border: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<RecoveryConvert size={20} />}
              sx={{ background: "none" }}
            >
              Switch Designation
            </AccordionSummary>

            <AccordionDetails
              sx={{
                margin: "0 10px",
                padding: 0,
                overflowY: "scroll",
                maxHeight: "300px",
                overflow: "scroll",
                "&::-webkit-scrollbar": {
                  width: "2px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "10px",
                },
              }}
            >
              {userData?.departmentData?.map((elm, index) => (
                <List sx={{ padding: 0 }} key={index}>
                  <ListItemButton>
                    <ListItemText primary={elm?.departmentName} />
                  </ListItemButton>
                  <List sx={{ paddingLeft: 4 }}>
                    {elm?.members?.map((member, memberIndex) => (
                      <ListItemButton
                        key={memberIndex}
                        onClick={() => handleRoleSelection(member, elm)}
                      >
                        <ListItemText
                          primary={`${memberIndex + 1}. ${member?.designation}`}
                        />
                        {member?.id === role?.roleId && (
                          <CheckCircleOutline style={{ color: "green" }} />
                        )}
                      </ListItemButton>
                    ))}
                  </List>
                </List>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : null}
        <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => {
            handleToggle();
            handleListItemClick(event, 2, "/profile");
          }}
        >
          <ListItemIcon>
            <Profile variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="View Profile" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => {
            handleSoundToggle();
            handleListItemClick(event, 3);
          }}
        >
          <ListItemIcon>
            <Musicnote variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="Notification Sound" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              checked={soundOn}
              inputProps={{ "aria-labelledby": "switch-list-label-sound" }}
            />
          </ListItemSecondaryAction>
        </ListItemButton>
        <ListItemButton
          sx={{
            "&:hover": {
              backgroundColor: "#f591915c",
            },
          }}
          selected={selectedIndex === 4}
          onClick={handleLogout}
        >
          <ListItemIcon>
            <Logout color="red" variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
};

export default withRouter(ProfileTab);
