import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  openItem: ["dashboard"],
  openComponent: "buttons",
  selectedID: null,
  drawerOpen: false,
  pinUnpin: false,
  componentDrawerOpen: true,
  menu: {},
  error: null,
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeID(state, action) {
      state.selectedID = action.payload;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },
    pinUnpinMenu(state, action) {
      state.pinUnpin = action.payload;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    hasError(state, action) {
      state.error = action.payload;
    },
  },
});

export default menu.reducer;

export const {
  activeItem,
  activeComponent,
  openDrawer,
  openComponentDrawer,
  activeID,
  pinUnpinMenu,
} = menu.actions;
