import { lazy } from "react";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import { routeConstants, commonRoutesConstants, FormatConstants, permissionConstants } from "services/keyConstants";
import CommonYearList from "components/reusables/CommonYearList";
import * as url from "./urlHelper"

// Define lazy-loaded components
const Create = Loadable(lazy(() => import("../Create")));
const View = Loadable(lazy(() => import("../View")));
const FeedbackById = Loadable(lazy(() => import("../FeedbackById")));
const List = Loadable(lazy(() => import("../List")));

// Helper function to create routes
const createRoute = (path, element, children) => ({ path, element, children });
// Define routes using lazy-loaded components
const FeedbackRoute = createRoute(
  "/",
  <AuthGuard>
    <MainLayout />
  </AuthGuard>,
  [
    createRoute(routeConstants.FEEDBACK, null, [
      createRoute("create-internal", <Create type={FormatConstants.FEEDBACK.INTERNAL_FEEDBACK} />),
      createRoute("create-external", <Create type={FormatConstants.FEEDBACK.EXTERNAL_FEEDBACK} />),
      createRoute("view/:year/:typeId", <View />),
      createRoute(":id/:subId?", <FeedbackById />),
      createRoute("list/:year", <List />),
      createRoute(commonRoutesConstants.YEAR_LIST,
        <CommonYearList
          uri={url.GET_BY_YEAR}
          title={routeConstants.FEEDBACK}
          isExtraButtons={true}
          permissionFormatConstants={[FormatConstants.FEEDBACK.INTERNAL_FEEDBACK, FormatConstants.FEEDBACK.EXTERNAL_FEEDBACK]}
          extraButtonChildren={[
            {
              buttonTitle: "Create Internal Feedback",
              path: `/${routeConstants.FEEDBACK}/create-internal`,
              disableOnPermission: true,
              formatConstants: FormatConstants.FEEDBACK.INTERNAL_FEEDBACK,
              permissionConstants: permissionConstants.CREATE
            },
            {
              buttonTitle: "Create External Feedback",
              path: `/${routeConstants.FEEDBACK}/create-external`,
              disableOnPermission: true,
              formatConstants: FormatConstants.FEEDBACK.EXTERNAL_FEEDBACK,
              permissionConstants: permissionConstants.CREATE
            },
          ]}
          navigateToFolder={`/${routeConstants.FEEDBACK}/list/`}
        />
      ),
    ]),
  ]
);

export default FeedbackRoute;
