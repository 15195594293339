// import { useState, useEffect } from "react";
// import {
//   TextField,
//   Typography,
//   Box,
//   Button,
//   Grid,
//   DialogTitle,
//   DialogContent,
//   MenuItem,
//   DialogActions,
//   Chip,
//   Tabs,
//   Tab,
//   Stack,
//   Card,
// } from "@mui/material";
// import CustomAccordionSkeleton from "components/CustomAccordionSkeleton";
// import CustomAccordion from "components/CustomAccordian";
// import Error404 from "components/reusables/404";
// import * as url from "./utils/urlHelper";
// import MainCard from "components/MainCard";
// import FolderIcon from "@mui/icons-material/Folder";
// import {
//   downloadFormat,
//   extractUserDetails,
//   filterUserById,
//   getDocumentLifeCycleForMasterDoc,
//   getYearFromDate,
//   sendNotification,
//   showToast,
//   TabPanel,
// } from "services/utils";
// import dayjs from "dayjs";
// import { getUserDataByDesignation } from "services/organogramServices";
// import withRouter from "components/withRouter";
// import CustomNotification from "components/CustomNotification";
// import DynamicTableForm from "components/reusables/DynamicTableForm";
// import {
//   DepartmentConstants,
//   DesignationConstants,
//   FormatConstants,
//   MasterDocumentTypeConstants,
//   routeConstants,
// } from "services/keyConstants";
// import DynamicTableCustomerFeedback from "./DynamicTableCustomerFeedback";
// import CustomDialog from "components/reusables/CustomDialog";
// import ChooseUserNotification from "components/reusables/ChooseUserNotification";
// import EmailSelector from "components/EmailSelector";

// const ServiceRecordHistory = (props) => {
//   const { params, navigate } = props?.router,
//     {
//       userData,
//       ApiServices,
//       onChangeDownloadState,
//       TypeConstants,
//       DepartmentConstants,
//       routeConstants,
//       selectedRole,
//     } = props;

//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [expanded, setExpanded] = useState(false);
//   const [tabValue, setTabValue] = useState(0);
//   const [notificationModalOpen, setNotificationModalOpen] = useState(false);
//   const [selectedId, setSelectedId] = useState(null);
//   const year = params?.year;
//   const [approvalStatus, setApprovalStatus] = useState("");
//   const [processType, setProcessType] = useState("");
//   const [openDesicionModal, setOpenDesicionModal] = useState(false);
//   const [responsibleUser, setResponsibleUser] = useState([]);
//   const [externalEmail, setExternalEmail] = useState([]);
//   const [emailError, setEmailError] = useState(false);

//   const getData = async () => {
//     try {
//       setIsLoading(true);
//       const reqObj = {
//         year: year,
//         demandedItem:
//           tabValue === 0
//             ? "Customer Service Record"
//             : tabValue === 2
//               ? "Servicing Feedback Form"
//               : "Customer Servicing Details",
//       };
//       const result = await ApiServices.callServicePostWithBodyData(
//         url.GET_ALL_SERVICE_FEEDBACK,
//         reqObj
//       );
//       if (result?.meta?.success) {
//         setData(result?.data?.list || []);
//       } else {
//         setData([]);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleDownloadClick = async (e, id) => {
//     let reqObj = {
//       formatId: id,
//     };
//     let uri = url.DOWNLOAD;
//     await downloadFormat(uri, reqObj, onChangeDownloadState);
//   };

//   useEffect(() => {
//     getData();
//   }, [tabValue]);

//   const handleAccordionChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   const tabLabels = [
//     "Customer Record",
//     "Customer Details",
//     "Customer Servicing Feedback",
//   ];
//   const handleApprovalChange = (event) => {
//     const value = event.target.value;
//     setApprovalStatus(value);

//   };

//   const handleProcessTypeChange = (event) => {
//     const value = event.target.value;
//     setProcessType(value);

//   };
//   const handleEmailsChange = (emails) => {
//     setExternalEmail(emails);
//     setEmailError(emails?.length === 0);
//   };
//   const submitDesicionModal = async () => {
//     let updateData;
//     const masterDocData = await getDocumentLifeCycleForMasterDoc(
//       FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_FORM,
//       MasterDocumentTypeConstants.FORMAT
//     );
//     if (processType === "Internal") {
//       updateData = {
//         "document.makeDecision": true,
//         "document.serviceType": processType,
//         "document.selectedUserForRecord": [responsibleUser],
//       };
//     } else {
//       updateData = {
//         "document.makeDecision": true,
//         "document.serviceType": processType,
//         "document.externalEmail": externalEmail,
//       };
//     }

//     let reqObj = {
//       id: selectedId,
//       orgId: userData?.orgId,
//       documentName: "Customer Servicing Details",
//       updateData: updateData,
//     };

//     let uri = url.UPDATE_SPECIFIC_DATA;
//     const response = await ApiServices.callServicePutWithBodyData(uri, reqObj);
//     setOpenDesicionModal(!openDesicionModal);

//     if (response) {
//       let reqObj;
//       if (processType === "External") {
//         let masterDocDataForServicing = await getDocumentLifeCycleForMasterDoc(
//           FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_FEEDBACK,
//           MasterDocumentTypeConstants.FORMAT
//         );

//         let userDatasForServicing = await getUserDataByDesignation(
//           [
//             {
//               type: TypeConstants.TYPE_FIND_HEAD,
//               department: [DepartmentConstants.QUALITY_CONTROL_DEPARTMENT],
//             },
//           ],
//           false
//         );

//         let servicingReqObj = {
//           masterDocId: masterDocDataForServicing?._id,
//           orgId: userData?.orgId,
//           clientUserId: userData?._id,
//           documentName:
//             FormatConstants.CUSTOMERSERVICE.CUSTOMER_SERVICING_FEEDBACK,
//           type: "customer-servicing-record",
//           subType: "Generate-Link-Invite",
//           document: {
//             domainName:
//               window?.location?.origin +
//               `/${routeConstants.CUSTOMER_SERVICE}/service-form/`,
//             link: ``,
//             userEmails: [],
//             notificationData: {
//               recipients: userDatasForServicing?.map((user) => ({
//                 userId: user?.userId,
//               })),
//               status: "Serviceing Feedback Form Filled By User",
//               msg: "Serviceing Feedback form submitted by user,please Review it",
//               location: `${routeConstants.CUSTOMER_SERVICE}/service-history/`,
//             },
//           },
//         };

//         let notificationArr = [];
//         // fetch user
//         let userDatas = await getUserDataByDesignation(
//           [
//             {
//               type: TypeConstants.TYPE_FIND_ALL_USERS_BY_DESIGNATION,
//               designations: [
//                 DesignationConstants.MANAGING_DIRECTOR,
//                 DesignationConstants.DIRECTOR,
//               ],
//             },
//             {
//               type: TypeConstants.TYPE_FIND_HEAD,
//               department: [DepartmentConstants.SALES_AND_MARKETING_DEPARTMENT],
//             },
//           ],
//           false
//         );
//         notificationArr.push(...userDatas);
//         reqObj = {
//           detailId: selectedId,
//           documentName: "Customer Service Record",
//           type: processType,
//           reportMasterDocId: masterDocData?._id,
//           reportData: {
//             notificationData: {
//               recipients: notificationArr?.map((user) => ({
//                 userId: user?.userId,
//               })),
//               status: "Servicing Form Filled By User",
//               msg: "Servicing form submitted by user,please Review it",
//               location: `service-record-history/${getYearFromDate()}`,
//             },
//             document: [],
//             userEmail: externalEmail,
//             link: `${window.location.origin}/service-report/${userData?.orgId}/`,
//             servicingDocument: servicingReqObj,
//           },
//         };
//       } else {
//         reqObj = {
//           detailId: selectedId,
//           documentName: "Customer Service Record",
//           reportMasterDocId: masterDocData?._id,
//           type: processType,
//         };
//       }
//       let uri = url.CREATE_SERVICE_REPORT;
//       const response = await ApiServices.callServicePostWithBodyData(
//         uri,
//         reqObj
//       );
//     }
//     if (response && processType === "Internal") {
//       if (responsibleUser) {
//         let notificationObj = {
//           dataArray: [
//             {
//               recipients: filterUserById([responsibleUser]),
//               msg: "Servicing is coming. Please prepare for the service record.",
//               status: "Please Fill the service record",
//               location: `${routeConstants.CUSTOMER_SERVICE}/service-record-history/${year}`,
//             },
//           ],
//         };

//         await sendNotification(notificationObj);
//         getData();
//       }
//     }
//   };
//   const tabContents = [
//     () => (
//       <CustomAccordionList
//         isLoading={isLoading}
//         data={data}
//         expanded={expanded}
//         handleAccordionChange={handleAccordionChange}
//         type="Customer Service Record"
//         isMakeDecisionButton={false}
//         handleDownloadClick={handleDownloadClick}
//         navigate={navigate}
//         userData={userData}
//         selectedRole={selectedRole}
//       />
//     ),
//     () => (
//       <CustomAccordionList
//         isLoading={isLoading}
//         data={data}
//         expanded={expanded}
//         handleAccordionChange={handleAccordionChange}
//         type="Customer Servicing Details"
//         setNotificationModalOpen={setNotificationModalOpen}
//         notificationModalOpen={notificationModalOpen}
//         handleSubmitNotification={handleSubmitNotification}
//         setSelectedId={setSelectedId}
//         setApprovalStatus={setApprovalStatus}
//         setProcessType={setProcessType}
//         setOpenDesicionModal={setOpenDesicionModal}
//         openDesicionModal={openDesicionModal}
//         setResponsibleUser={setResponsibleUser}
//         responsibleUser={responsibleUser}
//         handleEmailsChange={handleEmailsChange}
//         emailError={emailError}
//         setExternalEmail={setExternalEmail}
//         externalEmail={externalEmail}
//         submitDesicionModal={submitDesicionModal}
//         processType={processType}
//         approvalStatus={approvalStatus}
//         handleProcessTypeChange={handleProcessTypeChange}
//         handleApprovalChange={handleApprovalChange}
//         isMakeDecisionButton={true}
//         handleDownloadClick={handleDownloadClick}
//         navigate={navigate}
//         userData={userData}
//         selectedRole={selectedRole}
//       />
//     ),
//     () => (
//       <CustomAccordionList
//         isLoading={isLoading}
//         data={data}
//         expanded={expanded}
//         handleAccordionChange={handleAccordionChange}
//         type="Customer Servicing Feedback"
//         setNotificationModalOpen={setNotificationModalOpen}
//         notificationModalOpen={notificationModalOpen}
//         handleSubmitNotification={handleSubmitNotification}
//         setSelectedId={setSelectedId}
//         isMakeDecisionButton={true}
//         handleDownloadClick={handleDownloadClick}
//         navigate={navigate}
//         userData={userData}
//         selectedRole={selectedRole}
//       />
//     ),
//   ];

//   const handleSubmitNotification = async (selectedUser) => {
//     try {
//       let reqObj = {
//         id: selectedId,
//         orgId: userData?.orgId,
//         documentName: "Customer Servicing Details",
//         updateData: {
//           "document.selectedUserForRecord": extractUserDetails(selectedUser),
//           "document.makeDecision": true,
//         },
//       };
//       let uri = url.UPDATE_SPECIFIC_DATA;
//       const response = await ApiServices.callServicePutWithBodyData(
//         uri,
//         reqObj
//       );
//       if (selectedUser) {
//         let notificationObj = {
//           dataArray: [
//             {
//               recipients: filterUserById(selectedUser),
//               msg: "Servicing is coming. Please prepare for the service record.",
//               status: "Please Fill the service record",
//               location: `${routeConstants.CUSTOMER_SERVICE}/service-record-history/${year}`,
//             },
//           ],
//         };

//         await sendNotification(notificationObj);
//         getData();
//       }

//       showToast(response);
//     } catch (error) {
//       console.error("Error in handleSubmitNotification:", error);
//     }
//   };

//   const handleChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   const handleOpenFolder = (name) => {
//     if (name === "Customer Record") {
//       return navigate(`../history/customer-record/${year}`)
//     } else if (name === "Customer Details") {
//       return navigate(`../history/customer-details/${year}`)
//     } else if (name === "Customer Servicing Feedback") {
//       return navigate(`../history/customer-servicing-feedback/${year}`)
//     }
//   }

//   return (
//     <>
//       <MainCard title="Service Record History">
//         {isLoading ? (
//           <>Hello</>
//         ) : (
//           <>
//             <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
//               <Tabs
//                 value={tabValue}
//                 onChange={handleChange}
//                 aria-label="Archive Tabs"
//                 variant="fullWidth"
//               >
//                 <Tab label="Customer Record" />
//                 <Tab label="Customer Details" />
//                 <Tab label="Customer Servicing Feedback" />
//               </Tabs>
//             </Box>
//             <Box>
//               <TabPanel value={tabValue} index={0}>
//                 <CustomAccordionList
//                   isLoading={isLoading}
//                   data={data}
//                   expanded={expanded}
//                   handleAccordionChange={handleAccordionChange}
//                   type="Customer Service Record"
//                   isMakeDecisionButton={false}
//                   handleDownloadClick={handleDownloadClick}
//                   navigate={navigate}
//                   userData={userData}
//                   selectedRole={selectedRole}
//                 />
//               </TabPanel>
//               <TabPanel value={tabValue} index={1}>
//                 <CustomAccordionList
//                   isLoading={isLoading}
//                   data={data}
//                   expanded={expanded}
//                   handleAccordionChange={handleAccordionChange}
//                   type="Customer Servicing Details"
//                   setNotificationModalOpen={setNotificationModalOpen}
//                   notificationModalOpen={notificationModalOpen}
//                   handleSubmitNotification={handleSubmitNotification}
//                   setSelectedId={setSelectedId}
//                   setApprovalStatus={setApprovalStatus}
//                   setProcessType={setProcessType}
//                   setOpenDesicionModal={setOpenDesicionModal}
//                   openDesicionModal={openDesicionModal}
//                   setResponsibleUser={setResponsibleUser}
//                   responsibleUser={responsibleUser}
//                   handleEmailsChange={handleEmailsChange}
//                   emailError={emailError}
//                   setExternalEmail={setExternalEmail}
//                   externalEmail={externalEmail}
//                   submitDesicionModal={submitDesicionModal}
//                   processType={processType}
//                   approvalStatus={approvalStatus}
//                   handleProcessTypeChange={handleProcessTypeChange}
//                   handleApprovalChange={handleApprovalChange}
//                   isMakeDecisionButton={true}
//                   handleDownloadClick={handleDownloadClick}
//                   navigate={navigate}
//                   userData={userData}
//                   selectedRole={selectedRole}
//                 />
//               </TabPanel>
//               <TabPanel value={tabValue} index={2}>
//                 <CustomAccordionList
//                   isLoading={isLoading}
//                   data={data}
//                   expanded={expanded}
//                   handleAccordionChange={handleAccordionChange}
//                   type="Customer Servicing Feedback"
//                   setNotificationModalOpen={setNotificationModalOpen}
//                   notificationModalOpen={notificationModalOpen}
//                   handleSubmitNotification={handleSubmitNotification}
//                   setSelectedId={setSelectedId}
//                   isMakeDecisionButton={true}
//                   handleDownloadClick={handleDownloadClick}
//                   navigate={navigate}
//                   userData={userData}
//                   selectedRole={selectedRole}
//                 />
//               </TabPanel>
//             </Box>
//           </>
//         )}

//         <MainCard title="Service Record History">
//           <Grid container spacing={2}>
//             {["Customer Record", "Customer Details", "Customer Servicing Feedback"]?.map((item, index) => (
//               <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
//                 <Card
//                   style={{
//                     width: "100%",
//                     height: "100%",
//                     cursor: "pointer",
//                   }}
//                 >
//                   <Box
//                     onClick={() => handleOpenFolder(item)}
//                     sx={{
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "center",
//                       textAlign: "center",
//                       borderRadius: 2,
//                       padding: 2,
//                       height: "100%",
//                       justifyContent: "center",
//                       cursor: "pointer",
//                       "&:hover": {
//                         transform: "scale3d(1.02, 1.02, 1)",
//                         transition: "all .10s ease-in-out",
//                       },
//                     }}
//                   >
//                     <FolderIcon
//                       sx={{
//                         fontSize: 50,
//                         color: "#5c8ee0",
//                         cursor: "pointer",
//                       }}
//                     />
//                     <Typography>{item ?? "-"}</Typography>
//                   </Box>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         </MainCard>
//       </MainCard>
//     </>
//   );
// };

// const CustomAccordionList = ({
//   isLoading,
//   data,
//   expanded,
//   handleAccordionChange,
//   type,
//   setNotificationModalOpen,
//   notificationModalOpen,
//   handleSubmitNotification,
//   setSelectedId,
//   handleDownloadClick,
//   navigate,
//   userData,
//   selectedRole,
//   handleProcessTypeChange,
//   setOpenDesicionModal,
//   processType,
//   openDesicionModal,
//   setResponsibleUser,
//   responsibleUser,
//   handleEmailsChange,
//   externalEmail,
//   emailError,
//   submitDesicionModal,
// }) => {
//   let filteredData = [];
//   if (type === "Customer Servicing Feedback") {
//     filteredData = data;
//   } else {
//     filteredData = data?.filter((item) => item.documentName === type);
//   }
//   const handleDesicionModal = (item) => {
//     setOpenDesicionModal(!openDesicionModal);
//     setSelectedId(item);
//     // setNotificationModalOpen(!notificationModalOpen);
//   };

//   return (
//     <>
//       {notificationModalOpen ? (
//         <CustomNotification
//           open={notificationModalOpen}
//           setOpen={setNotificationModalOpen}
//           isMultipleUser={true}
//           handleSubmit={handleSubmitNotification}
//           isStaticDepartment={[
//             DepartmentConstants.SALES_AND_MARKETING_DEPARTMENT,
//           ]}
//         />
//       ) : null}
//       {isLoading ? (
//         <CustomAccordionSkeleton />
//       ) : filteredData?.length > 0 ? (
//         filteredData?.map((item, index) => (
//           <CustomAccordion
//             key={item._id}
//             expanded={expanded === index}
//             onChange={handleAccordionChange(index)}
//             summary={
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   width: "100%",
//                 }}
//               >
//                 <Typography>
//                   {type === "Customer Servicing Details"
//                     ? item?.document?.columns?.find(
//                       (column) => column?.value === "customerName"
//                     )?.inputValue
//                     : type === "Customer Servicing Feedback"
//                       ? `Customer Feedback - ${index + 1}`
//                       : `Customer Servicing Report - ${index + 1}`}
//                 </Typography>
//                 <div
//                   style={{ display: "flex", gap: "10px", marginLeft: "auto" }}
//                 >
//                   {type === "Customer Servicing Details" &&
//                     item.document?.makeDecision === false &&
//                     item?.document?.selectedUser?.some((user) => {
//                       return (
//                         user?.userId === userData?._id &&
//                         user?.designationId === selectedRole?.roleId
//                       );
//                     }) && (
//                       <Button
//                         variant="contained"
//                         color="success"
//                         onClick={() => handleDesicionModal(item?._id)}
//                       >
//                         Make Decision
//                       </Button>
//                     )}
//                   {type === "Customer Servicing Details" &&
//                     item?.document?.isReportFiled === false &&
//                     item?.document?.selectedUserForRecord?.some((user) => {
//                       return (
//                         user?.userId === userData?._id &&
//                         user?.designationId === selectedRole?.roleId
//                       );
//                     }) && item?.status !== "Submitted" && (
//                       <Button
//                         variant="contained"
//                         color="success"
//                         onClick={() =>
//                           navigate(
//                             `../../${routeConstants.CUSTOMER_SERVICE}/service-record/${item?._id}/${item?.serviceRecordId}`
//                           )
//                         }
//                       >
//                         Fill the Record
//                       </Button>
//                     )}
//                   {type === "Customer Servicing Details" && item?.document?.serviceType?.length > 0 && (
//                     <Chip
//                       label={item?.document?.serviceType}
//                       color={"default"}
//                     />
//                   )}
//                   {type === "Customer Service Record" &&
//                     item?.document?.serviceType && (
//                       <Button
//                         variant="contained"
//                         color="success"
//                         onClick={() =>
//                           navigate(
//                             `../../${routeConstants.CUSTOMER_SERVICE}/service-link`
//                           )
//                         }
//                       >
//                         Generate Feedback form
//                       </Button>
//                     )}
//                   <Button
//                     variant="contained"
//                     color="success"
//                     onClick={(e) => handleDownloadClick(e, item?._id)}
//                   >
//                     Download
//                   </Button>
//                 </div>
//               </div>
//             }
//             details={
//               type === "Customer Servicing Details" ? (
//                 <>
//                   <Typography component="div">
//                     <DynamicTableForm
//                       columns={item?.document?.columns}
//                       isViewMode={true}
//                     />
//                   </Typography>
//                 </>
//               ) : type === "Customer Servicing Feedback" ? (
//                 item?.document?.responces?.map((res) => (
//                   <>
//                     <DynamicTableForm
//                       columns={res?.customerDetails?.columns}
//                       isViewMode={true}
//                     />

//                     <DynamicTableCustomerFeedback
//                       columns={res?.customerServicingData?.columns}
//                       options={res?.customerServicingData?.options}
//                       rows={res?.customerServicingData?.rows}
//                       isStrictDataForRow={true}
//                       isViewMode={true}
//                     />
//                     <DynamicTableForm
//                       columns={res?.customerServicingDetails?.columns}
//                       isViewMode={true}
//                     />
//                   </>
//                 ))
//               ) : (
//                 <>
//                   <DynamicTableForm
//                     columns={
//                       item?.document.find(
//                         (itm) => itm.tableName === "Customer details"
//                       )?.columns
//                     }
//                     tableName={"Customer details"}
//                     isViewMode={true}
//                   />
//                   <DynamicTableForm
//                     columns={
//                       item?.document.find(
//                         (itm) => itm.tableName === "Device Details"
//                       )?.columns
//                     }
//                     tableName={"Device Details"}
//                     isViewMode={true}
//                   />
//                   <DynamicTableForm
//                     columns={
//                       item?.document.find(
//                         (itm) => itm.tableName === "Details of Return"
//                       )?.columns
//                     }
//                     tableName={"Details of Return"}
//                     isViewMode={true}
//                   />
//                   <DynamicTableForm
//                     columns={
//                       item?.document.find(
//                         (itm) => itm.tableName === "Service Engineer Findings"
//                       )?.columns
//                     }
//                     tableName={"Service Engineer Findings"}
//                     isViewMode={true}
//                   />
//                   <Grid item xs={6}>
//                     <TextField
//                       label="Name"
//                       variant="outlined"
//                       size="small"
//                       value={item?.eSign?.userData?.name}
//                       fullWidth
//                       margin="normal"
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={6}>
//                     <TextField
//                       label="Time"
//                       variant="outlined"
//                       size="small"
//                       value={
//                         item?.eSign?.time
//                           ? dayjs(item?.eSign?.time).format("DD/MM/YYYY")
//                           : ""
//                       }
//                       fullWidth
//                       margin="normal"
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                     />
//                   </Grid>
//                 </>
//               )
//             }
//           />
//         ))
//       ) : (
//         <Error404
//           headingMain="Oops, looks like there are no records!"
//           subHeading="Please check back later or create a new record"
//         />
//       )}
//       <CustomDialog
//         open={openDesicionModal}
//         onClose={() => setOpenDesicionModal(false)}
//       >
//         <DialogTitle>Please select sevicing type</DialogTitle>
//         <DialogContent>
//           <Stack sx={{ margin: 2 }} gap={2}>
//             <TextField
//               select
//               label="Servicing Type"
//               fullWidth
//               value={processType}
//               onChange={handleProcessTypeChange}
//               margin="normal"
//             >
//               <MenuItem value="Internal">Internal</MenuItem>
//               <MenuItem value="External">External</MenuItem>
//             </TextField>

//             {processType === "Internal" && (
//               <ChooseUserNotification
//                 label="Select Preparer"
//                 isMultipleUser={false}
//                 value={
//                   Object.keys(responsibleUser)?.length > 0
//                     ? [responsibleUser]
//                     : ""
//                 }
//                 onChange={(value) => setResponsibleUser(value)}
//               />
//             )}
//             {processType === "External" && (
//               <>
//                 <EmailSelector
//                   emails={externalEmail}
//                   setEmails={handleEmailsChange}
//                   isHiddenLabel={true}
//                 />
//                 {emailError && (
//                   <span
//                     style={{
//                       color: "red",
//                       marginTop: "0.5rem",
//                       display: "block",
//                     }}
//                   >
//                     Email is required.
//                   </span>
//                 )}
//               </>
//             )}
//           </Stack>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDesicionModal(false)} color="error" variant="contained">Cancel</Button>
//           <Button onClick={() => submitDesicionModal()} color="success" variant="contained">
//             Save
//           </Button>
//         </DialogActions>
//       </CustomDialog>
//     </>
//   );
// };

// export default withRouter(ServiceRecordHistory);

import { Box, Card, Grid, Typography } from '@mui/material'
import MainCard from 'components/MainCard'
import withRouter from 'components/withRouter'
import React from 'react'
import FolderIcon from "@mui/icons-material/Folder";

const ServiceRecordHistory = (props) => {
  const { navigate, params } = props?.router
  const { year } = params
  const handleOpenFolder = (name) => {
    if (name === "Customer Record") {
      return navigate(`../history/customer-record/${year}`)
    } else if (name === "Customer Details") {
      return navigate(`../history/customer-details/${year}`)
    } else if (name === "Customer Servicing Feedback") {
      return navigate(`../history/customer-servicing-feedback/${year}`)
    }
  }
  return (
    <MainCard title="Service Record History">
      <Grid container spacing={2}>
        {["Customer Record", "Customer Details", "Customer Servicing Feedback"]?.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
            <Card
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            >
              <Box
                onClick={() => handleOpenFolder(item)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 2,
                  padding: 2,
                  height: "100%",
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale3d(1.02, 1.02, 1)",
                    transition: "all .10s ease-in-out",
                  },
                }}
              >
                <FolderIcon
                  sx={{
                    fontSize: 50,
                    color: "#5c8ee0",
                    cursor: "pointer",
                  }}
                />
                <Typography>{item ?? "-"}</Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </MainCard>
  )
}

export default withRouter(ServiceRecordHistory)
