const persmissionData = {
  Procedures: {
    "Procedure for Competence, Awareness and Training": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Control of Document": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Customer Complaints": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Installation": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Control of Records": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Customer Property": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Feedback": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Customer Related Process And Distribution": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },

    "Procedure for Advisory Notice and Recall": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Product Realization": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Design and Development": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Sterilization validation": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Identification and Traceability": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Preservation of Product": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Monitoring and Measurement of Process": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Process Validation": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Data Analysis": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },

    "Procedure for PMS": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },

    "Procedure for Internal Quality Audit": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Internal Communication": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },

    "Procedure for Corrective Action Preventive Action": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },

    "Procedure for Management Review Meeting": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Monitoring and Measurement of Equipment": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Monitoring and Measuring of Product": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Preventive Maintenance": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Production": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Purchase": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Servicing": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Work Environment": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },

    "Procedure for Work Environment": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Procedure for Control of non confirming": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },

  Production: {
    "Requisition Slip-Production": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Production Planning": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Batch Manufacturing Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Stock Register": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Control Of Document": {
    "External List of Documnets - COD": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Master List of Documnets - COD": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Communication with Requlatory Bodies": {
    "Communication with Requlatory Bodies - Mega Menu Section": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },

    "Customer Complaint": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Amendments in Technical File": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Change Management": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "FSN/Advisory Notice": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Design Modification": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Change Note": {
    "Change Management": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Change Note History": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Finished Product Register": {
    "Product Code Register": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Control of Records": {
    "Master List of Records - COR": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  Product: {
    "Product List": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Product Details": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Internal And External Communication": {
    "Internal-External Communication": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  IQA: {
    "Internal Quality Audit Scheduled": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Internal Audit Plan": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "List of Internal Auditors": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Corrective Action Report": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Internal Quality Audit Report": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Control of Non Conforming": {
    "Rework Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Rejection Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Scrap Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Concession Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Return of Goods Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Product history": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Process Validation": {
    "Master Validation Plan": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Validation Protocol Report": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Customer Property Department": {
    "List of Customer Property": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Customer Property": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Approval Of Customer Property": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Customer Complaint Department": {
    "Customer Complaint": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Advisory Recall": {
    "Product Recall Register": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Recall List": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Returned Goods Records": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Advisory Notice": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  CAPA: {
    "Corrective Action": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Preventive Action": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Management Review Meeting": {
    "MRM-Plan": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "MRM Circular": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Minutes Of Meeting": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Purchase Department": {
    "Purchase Department Format": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Supplier/Service Provider Selection": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Purchase Order": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Subcontractor Agreement": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Test Request Note": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Incoming Inspection Report": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Supplier Evaluation form": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Supplier Agreement": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Requisition Slip": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Issue Slip": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Approved Supplier List": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Raw material Stock register": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Supplier Re-evaluation Form": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Agreement with laboratry": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Customer servicing": {
    "Customer Service Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Customer Service Form": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Preventive Maintenance": {
    "Machine Break Down Report": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Preventive Maintenance Plan": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Maintanance Report": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "MAM of equipment": {
    "Master List Of Equipment": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Internal List Of Equipment": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Internal Clibration Report": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "MAM of Product": {
    "In process Inspection Report": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Test Request Note Form": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Incoming Inspection Report": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Final Inspection Report": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Generate Inprocess Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  Installation: {
    "Installation Formats": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Checklist for Installation": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Installation Report": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Sales And Marketing Department": {
    "Order Review Form": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Sales Information": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Distribution Records": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    // "List of distributors": {
    //   create: false,
    //   read: false,
    //   delete: false,
    //   update: false,
    //   download: false,
    // },
    "Distributor Agreement": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Customer Feedback Form": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    // "Customer Complaint Register": {
    //   create: false,
    //   read: false,
    //   delete: false,
    //   update: false,
    //   download: false,
    // },
    // "Checklist for installation": {
    //   create: false,
    //   read: false,
    //   delete: false,
    //   update: false,
    //   download: false,
    // },
    // "Installation report": {
    //   create: false,
    //   read: false,
    //   delete: false,
    //   update: false,
    //   download: false,
    // },
    // "Customer Servicing Details": {
    //   create: false,
    //   read: false,
    //   delete: false,
    //   update: false,
    //   download: false,
    // },
    "Product History": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    // "Service records": {
    //   create: false,
    //   read: false,
    //   delete: false,
    //   update: false,
    //   download: false,
    // },
    // "Servicing Feedback form": {
    //   create: false,
    //   read: false,
    //   delete: false,
    //   update: false,
    //   download: false,
    // },
  },
  "Work Environment": {
    "Sterile & Non-Sterile": {
      "Cleaning register for Sterile and Non-Sterile": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
      "Temp and humidity monitoring record for Sterile and Non-Sterile": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
      "Fumigation Record for Sterile and Non-Sterile": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
      "Personal hygiene record for Sterile and Non-Sterile": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
      "Apron and slipper cleaning for Sterile and Non-Sterile": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
    },
  },

  "Store Department": {
    "Cleaning register": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Temp and humidity monitoring record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Fumigation Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Personal hygiene record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Apron and slipper cleaning": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },

  "Quality Control Department": {
    "Cleaning register": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Temp and humidity monitoring record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Fumigation Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Personal hygiene record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Apron and slipper cleaning": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },

  "Competence, Awareness and Training": {
    Hiring: {
      "Requistion For Manpower": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
      "Manpower List with Specimen Signature": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
      "Induction Training Record": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
    },
    Training: {
      "Training Need Assesment Form": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
      "Training Attendance Sheet": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
      "Training Evaluation Record": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
      "Training Calendar": {
        create: false,
        read: false,
        delete: false,
        update: false,
        download: false,
      },
    },
    "Competency Matrix": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Maintenance Department": {
    "Cleaning register": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Temp and humidity monitoring record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Fumigation Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Personal hygiene record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Apron and slipper cleaning": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Quality Assurance Department": {
    "Cleaning register": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Temp and humidity monitoring record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Fumigation Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Personal hygiene record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Apron and slipper cleaning": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Regulatory Affairs Department": {
    "Cleaning register": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Temp and humidity monitoring record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Fumigation Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Personal hygiene record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Apron and slipper cleaning": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  "Dispatch Department": {
    "Cleaning register": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Temp and humidity monitoring record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Fumigation Record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Personal hygiene record": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Apron and slipper cleaning": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
  },
  Sidebar: {
    "Users": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Standards": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Master Products": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    "Finished Product Register": {
      create: false,
      read: true,
      delete: true,
      update: false,
      download: false,
    },
 
    "Master Table": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },

    "Competency Matrix": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },

    "Calendar": {
      create: false,
      read: false,
      delete: false,
      update: false,
      download: false,
    },
    History: {
      //   create: false,
      //   read: false,
      //   delete: false,
      //   update: false,
      children: {
        General: {
          //   create: false,
          //   read: false,
          //   delete: false,
          //   update: false,
          children: {
            "Change Note Report": {
              create: true,
              read: false,
              delete: false,
              update: false,
              download: false,
            },
            "Approval Of Customer Property": {
              create: false,
              read: false,
              delete: false,
              update: false,
              download: false,
            },
            "Induction Training Records": {
              create: false,
              read: false,
              delete: false,
              update: false,
              download: false,
            },
            "Requisition for Man-Power": {
              create: false,
              read: false,
              delete: false,
              update: false,
              download: false,
            },
          },
        },
        Production: {
          //   create: false,
          //   read: false,
          //   delete: false,
          //   update: false,
          children: {
            "Production-Planning": {
              create: false,
              read: false,
              delete: false,
              update: false,
              download: false,
            },
            "Batch-Manufacturing-Record": {
              create: false,
              read: false,
              delete: false,
              update: false,
              download: false,
            },
            "Batch-Release-Checklist": {
              create: false,
              read: false,
              delete: false,
              update: false,
              download: false,
            },
            "Requisition-Slip": {
              create: false,
              read: false,
              delete: false,
              update: false,
              download: false,
            },
            "Issue-Slip": {
              create: false,
              read: false,
              delete: false,
              update: false,
              download: false,
            },
            "Stock-Register": {
              create: false,
              read: false,
              delete: false,
              update: false,
              download: false,
            },
          },
        },
      },
    },
  },
  "Monitoring and Measurement of product": {
    create: false,
    read: false,
    delete: false,
    update: false,
    download: false,
  },
  "Control of Non Conforming Product": {
    create: false,
    read: false,
    delete: false,
    update: false,
    download: false,
  },
};
export default persmissionData;
